import React, { Component }           from 'react';
import connect                        from "react-redux/es/connect/connect";
import PropTypes                      from "prop-types";
import history                        from "../../history";
import "./UserList.scss";
import { deleteUser, getListOfUsers } from "../../redux/userSlice";
import _                              from "lodash";
import { Link }                       from "react-router-dom";
import ConfirmationModal              from "../modal/ConfirmationModal";
import Loading                        from "../loading/Loading";

const $ = require('jquery');
$.DataTable = require('datatables.net-bs5');

class UserList extends Component {
  static propTypes = {
    usersList: PropTypes.array,
    listLoaded: PropTypes.bool,
    
    getListOfUsers: PropTypes.func.isRequired,
    deleteUser: PropTypes.func.isRequired,
  };
  
  constructor(props) {
    super(props);
    
    this.tableAPI = undefined;
    
    this.state = {
      deleteModalIsOpen: false,
      userId: undefined,
      userText: undefined
    };
    
    this.toggleModal = this.toggleModal.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.deleteUserButton = this.deleteUserButton.bind(this);
  }
  
  componentDidMount() {
    const { usersList, listLoaded, getListOfUsers } = this.props;
    window.reactHistory = history;
    window.deleteUserButton = this.deleteUserButton;
    
    if (listLoaded === false || _.isEmpty(usersList)) {
      getListOfUsers();
    }
    
    this.initTable();
  }
  
  componentWillUnmount() {
    this.tableAPI.destroy();
  }
  
  componentDidUpdate(prevProps, prevState) {
    // update users list table only if we receive a new list
    if (!_.isEqual(prevProps.usersList, this.props.usersList)) {
      this.updateTable();
    }
  }
  
  toggleModal() {
    this.setState({
      deleteModalIsOpen: !this.state.deleteModalIsOpen
    });
  }
  
  deleteUser(userId) {
    const { deleteUser } = this.props;
    deleteUser(userId);
    this.toggleModal();
  }
  
  deleteUserButton(userId, username) {
    this.setState({
      deleteModalIsOpen: true,
      userId: userId,
      userText: username
    });
  }
  
  updateTable() {
    const { usersList, listLoaded } = this.props;
    
    if (listLoaded === true) {
      this.tableAPI.clear();
      this.tableAPI.rows.add(usersList);
      this.tableAPI.draw();
    }
  }
  
  initTable() {
    const { usersList } = this.props;
    
    this.$el = $(this.el);
    this.tableAPI = this.$el.DataTable({
      data: usersList,
      pageLength: 10,
      oLanguage: {
        // sSearch: "Cauta",
        // sLengthMenu: "Afiseaza _MENU_ intrari",
        // sInfo: "Se afiseaza intrarile de _START_ la _END_ dintrun total de _TOTAL_",
        // oPaginate: {
        //   sNext: "Urmatoarea",
        //   sPrevious: "Anterioara"
        // },
      },
      
      columns: [
        { title: "Username", data: "username" },
        { title: "First Name", data: "firstName" },
        { title: "Last Name", data: "lastName" },
        { title: "Roles", data: "roles" },
        { title: "Enabled", data: "enabled" },
        {
          data: null,
          title: 'Actions',
          wrap: true,
          width: "210px",
          render: function (item) {
            return '<div class="btn-group"> ' +
              '<a onclick="window.reactHistory.push(\'/admin/users/edit/' + item.id + '\')" class="users-actions">' +
              '<button type="button" class="btn btn-outline-info"><i class="fas fa-edit"></i> Edit</button>' +
              '</a>' +
              '<a onclick="window.deleteUserButton(' + item.id + ', \'' + item.username + '\')" class="users-actions">' +
              '<button type="button" class="btn btn-outline-danger"><i class="fas fa-trash"></i> Delete</button>' +
              '</a>' +
              '</div>';
          },
          orderable: false
        }
      ]
    });
  }
  
  render() {
    const { listLoaded } = this.props;
    return (
      <div className="list-of-users section">
        
        <div className="section-title">
          <h2>List of Users</h2>
        </div>
        
        <Loading display={!listLoaded}/>
        
        <ConfirmationModal
          isOpen={this.state.deleteModalIsOpen}
          title={"User: " + this.state.userText}
          content="<p>Are you sure you want to delete this User?</p>"
          actionText="Delete"
          toggle={this.toggleModal}
          doAction={() => this.deleteUser(this.state.userId)}/>
        
        <div className="row">
          <div className="col-12">
            <table className="table table-hover" ref={el => this.el = el}>
            </table>
          </div>
        </div>
        
        <div className="row mt-5">
          <div className="col-12">
            <Link to="/admin/users/add/user">
              <button type="button" className="btn btn-lg btn-outline-success">
                <i className="fas fa-plus-circle"></i> Add New User
              </button>
            </Link>
          </div>
        </div>
      </div>
    );
  };
}

export default connect(
  (state) => {
    return {
      usersList: state.user.list,
      listLoaded: state.user.listLoaded
    };
  },
  {
    getListOfUsers: () => getListOfUsers(),
    deleteUser: (userId) => deleteUser(userId),
  }
)(UserList);


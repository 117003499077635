import _ from "lodash";

export const toOption = (option) => {
  if (_.isEmpty(option)) {
    return [];
  }
  
  return {
    value: option,
    label: option
  };
};

export const toSelector = (options) => {
  if (_.isEmpty(options)) {
    return [];
  }
  
  const response = [];
  for (let option of options) {
    response.push({
      value: option,
      label: option
    });
  }
  return response;
};

export const toParam = (selector) => {
  if (_.isEmpty(selector)) {
    return undefined;
  }
  
  return selector?.value;
};

export const toParams = (selectors) => {
  if (_.isEmpty(selectors)) {
    return undefined;
  }
  
  const response = [];
  for (let selector of selectors) {
    response.push(selector?.value);
  }
  return response;
};


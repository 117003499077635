import React, { Component }           from 'react';

import "./RecordingSearch.scss";
import connect                        from "react-redux/es/connect/connect";
import { API_SEARCH_RECORDINGS }      from "../../api/Api";
import tokenService                   from "../../security/TokenService";
import {
  isRecordingW
}                                     from "../../security/Authorization";
import { saveRecordingFile }          from "../../util/File";
import ConfirmationModal              from "../modal/ConfirmationModal";
import PropTypes                      from "prop-types";
import {
  deleteAllRecordingSearch,
  deleteRecordingSearch,
  downloadAllRecordingSearch
}                                     from "../../redux/recordingSlice";
import DatePicker                     from "react-datepicker";
import _                              from "lodash";
import { RECORDING_MAX_BULK_ACTIONS } from "../../security/Constants";

const $ = require('jquery');
$.DataTable = require('datatables.net-bs5');

class RecordingSearch extends Component {
  static propTypes = {
    deleteRecordingProcess: PropTypes.bool,
    deleteAllRecordingProcess: PropTypes.bool,
    downloadAllRecordingProcess: PropTypes.bool,
    
    deleteRecordingSearch: PropTypes.func.isRequired,
    deleteAllRecordingSearch: PropTypes.func.isRequired,
    downloadAllRecordingSearch: PropTypes.func.isRequired,
  };
  
  constructor(props) {
    super(props);
    
    this.tableAPI = undefined;
    
    this.state = {
      // state for delete action
      deleteModalIsOpen: false,
      recordingId: undefined,
      recordingText: undefined,
      
      // state for search filters
      searchRecordingId: "",
      searchANumber: "",
      searchBNumber: "",
      searchStartDate: undefined,
      searchEndDate: undefined,
      
      // state for results actions
      deleteAllModalIsOpen: false,
      recordsFiltered: 0
    };
    
    this.handleChange = this.handleChange.bind(this);
    this.setStartDate = this.setStartDate.bind(this);
    this.setEndDate = this.setEndDate.bind(this);
    this.setDateRange = this.setDateRange.bind(this);
    
    this.downloadFile = this.downloadFile.bind(this);
    this.downloadAll = this.downloadAll.bind(this);
    this.deleteAll = this.deleteAll.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleAllModal = this.toggleAllModal.bind(this);
    this.deleteRecording = this.deleteRecording.bind(this);
    this.deleteRecordingButton = this.deleteRecordingButton.bind(this);
  }
  
  componentDidMount() {
    window.downloadFile = this.downloadFile;
    window.deleteRecordingButton = this.deleteRecordingButton;
    
    this.initTable();
  }
  
  componentWillUnmount() {
    this.tableAPI.destroy();
  }
  
  componentDidUpdate(prevProps, prevState) {
    // update Recordings list table only if user updated the list (usually delete)
    if ((prevProps.deleteRecordingProcess === true && this.props.deleteRecordingProcess === false)
      || (prevProps.deleteAllRecordingProcess === true && this.props.deleteAllRecordingProcess === false)) {
      console.log(">>>>>!!!!!!!!!!!!!!!!!!!!!!!>>>>>>>>");
      this.tableAPI.ajax.reload();
    }
    
    if (!_.isEqual(prevState.searchRecordingId, this.state.searchRecordingId)
      || !_.isEqual(prevState.searchANumber, this.state.searchANumber)
      || !_.isEqual(prevState.searchBNumber, this.state.searchBNumber)
      || !_.isEqual(prevState.searchStartDate, this.state.searchStartDate)
      || !_.isEqual(prevState.searchEndDate, this.state.searchEndDate)) {
      this.tableAPI.ajax.reload();
    }
  }
  
  setDateRange(update) {
    const [start, end] = update;
    
    this.setStartDate(start);
    this.setEndDate(end);
  }
  
  setStartDate(newDate) {
    this.setState({
      searchStartDate: newDate
    });
  }
  
  setEndDate(newDate) {
    this.setState({
      searchEndDate: newDate
    });
  }
  
  handleChange = (event) => {
    const { target } = event;
    let { id } = target;
    let value = target.value;
    
    if (target.type === "checkbox") {
      value = target.checked;
    } else {
      if (target.type === "radio") {
        id = target.name;
        value = target.value;
      } else {
        value = target.value;
      }
    }
    
    this.setState({
      [id]: value,
    });
  };
  
  downloadFile(recordingId, recordingName) {
    saveRecordingFile(recordingId, recordingName);
  }
  
  downloadAll() {
    const { downloadAllRecordingSearch } = this.props;
    const { searchRecordingId, searchANumber, searchBNumber, searchStartDate, searchEndDate } = this.state;
    
    var gmtSearchStartDate, gmtSearchEndDate;
    if (_.isDate(searchStartDate)) {
      const userTimezoneOffset = searchStartDate.getTimezoneOffset() * 60000;
      gmtSearchStartDate = new Date(searchStartDate.getTime() - userTimezoneOffset);
      gmtSearchStartDate = gmtSearchStartDate.getTime();
    }
    
    if (_.isDate(searchEndDate)) {
      const userTimezoneOffset = searchEndDate.getTimezoneOffset() * 60000;
      gmtSearchEndDate = new Date(searchEndDate.getTime() - userTimezoneOffset + 86400000); // 86400000 = 1 day in ms
      gmtSearchEndDate = gmtSearchEndDate.getTime();
    }
    
    downloadAllRecordingSearch({
      draw: 0,
      start: 0,
      length: 0,
      search: '',
      searchRecordingId: searchRecordingId,
      searchANumber: searchANumber,
      searchBNumber: searchBNumber,
      searchStartDate: gmtSearchStartDate,
      searchEndDate: gmtSearchEndDate,
    });
  }
  
  toggleModal() {
    this.setState({
      deleteModalIsOpen: !this.state.deleteModalIsOpen
    });
  }
  
  deleteRecording(recordingId) {
    const { deleteRecordingSearch } = this.props;
    deleteRecordingSearch(recordingId);
    this.toggleModal();
  }
  
  deleteRecordingButton(recordingId, recordingName) {
    this.setState({
      deleteModalIsOpen: true,
      recordingId: recordingId,
      recordingText: recordingName
    });
  }
  
  toggleAllModal() {
    this.setState({
      deleteAllModalIsOpen: !this.state.deleteAllModalIsOpen
    });
  }
  
  deleteAll() {
    const { deleteAllRecordingSearch } = this.props;
    const { searchRecordingId, searchANumber, searchBNumber, searchStartDate, searchEndDate } = this.state;
    
    var gmtSearchStartDate, gmtSearchEndDate;
    if (_.isDate(searchStartDate)) {
      const userTimezoneOffset = searchStartDate.getTimezoneOffset() * 60000;
      gmtSearchStartDate = new Date(searchStartDate.getTime() - userTimezoneOffset);
      gmtSearchStartDate = gmtSearchStartDate.getTime();
    }
    
    if (_.isDate(searchEndDate)) {
      const userTimezoneOffset = searchEndDate.getTimezoneOffset() * 60000;
      gmtSearchEndDate = new Date(searchEndDate.getTime() - userTimezoneOffset + 86400000); // 86400000 = 1 day in ms
      gmtSearchEndDate = gmtSearchEndDate.getTime();
    }
    
    deleteAllRecordingSearch({
      draw: 0,
      start: 0,
      length: 0,
      search: '',
      searchRecordingId: searchRecordingId,
      searchANumber: searchANumber,
      searchBNumber: searchBNumber,
      searchStartDate: gmtSearchStartDate,
      searchEndDate: gmtSearchEndDate,
    });
    
    this.toggleAllModal();
  }
  
  deleteAllRecordingButton() {
    this.setState({
      deleteAllModalIsOpen: true,
    });
  }
  
  initTable() {
    const self = this;
    
    this.$el = $(this.el);
    this.tableAPI = this.$el.DataTable({
      pageLength: 10,
      searching: false,
      order: [],
      processing: true,
      serverSide: true,
      ajax: {
        url: API_SEARCH_RECORDINGS,
        headers: {
          Authorization: `Bearer ${tokenService.getUser()?.token}`,
          sessionId: tokenService.getUser()?.sessionId
        },
        data: function (formData) {
          const { searchRecordingId, searchANumber, searchBNumber, searchStartDate, searchEndDate } = self.state;
          
          var gmtSearchStartDate, gmtSearchEndDate;
          if (_.isDate(searchStartDate)) {
            const userTimezoneOffset = searchStartDate.getTimezoneOffset() * 60000;
            gmtSearchStartDate = new Date(searchStartDate.getTime() - userTimezoneOffset);
            gmtSearchStartDate = gmtSearchStartDate.getTime();
          }
          
          if (_.isDate(searchEndDate)) {
            const userTimezoneOffset = searchEndDate.getTimezoneOffset() * 60000;
            gmtSearchEndDate = new Date(searchEndDate.getTime() - userTimezoneOffset + 86400000); // 86400000 = 1 day in ms
            gmtSearchEndDate = gmtSearchEndDate.getTime();
          }
          
          return {
            draw: formData.draw,
            start: formData.start,
            length: formData.length,
            search: formData.search.value,
            searchRecordingId: searchRecordingId,
            searchANumber: searchANumber,
            searchBNumber: searchBNumber,
            searchStartDate: gmtSearchStartDate,
            searchEndDate: gmtSearchEndDate,
          };
        },
        dataSrc: function (json) {
          // all elements after filtering
          self.setState({
            recordsFiltered: json.recordsFiltered
          });
          
          return json.data;
        },
        error: function (xhr, error, code) {
          // do nothing;
        }
      },
      columns: [
        { title: "Recording GW", data: "recordingId", orderable: false },
        { title: "A Number", data: "aNumber", orderable: false },
        { title: "B Number", data: "bNumber", orderable: false },
        {
          data: "recordingDate",
          title: 'Recording Date',
          wrap: true,
          orderable: false,
          render: function (item) {
            const date = new Date(item);
            return date.toLocaleTimeString([], {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
            });
          },
        },
        { title: "Duration", data: "recordingDuration", orderable: false },
        { title: "File Name", data: "path", orderable: false },
        {
          data: null,
          title: 'Actions',
          wrap: true,
          width: "210px",
          render: function (item) {
            if (isRecordingW()) {
              return '<div class="btn-group"> ' +
                '<a aria-label="Download Recording" data-cooltipz-dir="left" onclick="window.downloadFile(' + item.id + ', \'' + item.path + '\')" class="recording-actions">' +
                '<button type="button" class="btn btn-outline-info"><i class="fas fa-download"></i></button>' +
                '</a>' +
                '<a aria-label="Delete Recording" data-cooltipz-dir="left" onclick="window.deleteRecordingButton(' + item.id + ', \'' + item.path + '\')" class="recording-actions">' +
                '<button type="button" class="btn btn-outline-danger"><i class="fas fa-trash"></i></button>' +
                '</a>';
            }
            return '<div class="btn-group"> ' +
              '<a aria-label="Download Recording" data-cooltipz-dir="left" onclick="window.downloadFile(' + item.id + ', \'' + item.path + '\')" class="recording-actions">' +
              '<button type="button" class="btn btn-outline-info"><i class="fas fa-download"></i></button>' +
              '</a>' +
              '</div>';
          },
          orderable: false
        }
      ]
    });
  }
  
  render() {
    const {
      searchRecordingId,
      searchANumber,
      searchBNumber,
      searchStartDate,
      searchEndDate,
      recordsFiltered
    } = this.state;
    
    const { deleteAllRecordingProcess, downloadAllRecordingProcess } = this.props;
    
    return (
      <div className="recording-search section">
        
        <div className="section-title">
          <h2>Recordings</h2>
        </div>
        
        <ConfirmationModal
          isOpen={this.state.deleteModalIsOpen}
          title={"Recording File: " + this.state.recordingText}
          content="<p>Are you sure you want to delete this Recording File?</p>"
          actionText="Delete"
          size={"lg"}
          toggle={this.toggleModal}
          doAction={() => this.deleteRecording(this.state.recordingId)}/>
        
        <ConfirmationModal
          isOpen={this.state.deleteAllModalIsOpen}
          title={"Delete " + recordsFiltered + " Recording Files from table"}
          content="<p>Are you sure you want to delete All Recordings from the search result?</p>"
          actionText="Delete"
          toggle={this.toggleAllModal}
          doAction={() => this.deleteAll()}/>
        
        <div className="row">
          <div className="col-12">
            <h5 className="search-filters"><i className="fas fa-search"></i> Search Filters</h5>
          </div>
        </div>
        
        <div className="row mt-2 mb-4 filters">
          <div className="col-3">
            <label htmlFor="searchRecordingId" className="form-label">Recording GW</label>
            
            <input type="number" className="form-control" name="searchRecordingId"
                   id="searchRecordingId"
                   placeholder="recording gw"
                   value={searchRecordingId}
                   onChange={(event) => this.handleChange(event)}/>
            
            {/*<div className="input-group">*/}
            {/*  <input type="number" className="form-control border-end-0 border" name="searchRecordingId"*/}
            {/*         id="searchRecordingId"*/}
            {/*         placeholder="recording gw"*/}
            {/*         value={searchRecordingId}*/}
            {/*         onChange={(event) => this.handleChange(event)}/>*/}
            {/*  <span className="input-group-append">*/}
            {/*      <button className="btn btn-white border-start-0 border" type="button">*/}
            {/*          <i className="fas fa-delete-left"></i>*/}
            {/*      </button>*/}
            {/*  </span>*/}
            {/*</div>*/}
          </div>
          
          <div className="col-3">
            <label htmlFor="searchANumber" className="form-label">A Number</label>
            <input type="number" className="form-control" name="searchANumber"
                   id="searchANumber"
                   placeholder="a number"
                   value={searchANumber}
                   onChange={(event) => this.handleChange(event)}/>
          </div>
          
          <div className="col-3">
            <label htmlFor="searchBNumber" className="form-label">B Number</label>
            <input type="number" className="form-control" name="searchBNumber"
                   id="searchBNumber"
                   placeholder="b number"
                   value={searchBNumber}
                   onChange={(event) => this.handleChange(event)}/>
          </div>
          
          <div className="col-3">
            <label htmlFor="searchStartDate" className="form-label">Recording Dates</label>
            
            <DatePicker
              className="form-control"
              name="searchStartDate" id="searchStartDate"
              isClearable
              // showTimeSelect
              selectsRange
              startDate={searchStartDate}
              endDate={searchEndDate}
              dateFormat="dd/MM/yyyy"
              // dateFormat="dd/MM/yyyy HH:mm"
              onChange={(update) => this.setDateRange(update)}
            />
            
            {/*<DatePicker*/}
            {/*  className="form-control"*/}
            {/*  name="searchStartDate" id="searchStartDate"*/}
            {/*  isClearable*/}
            {/*  showTimeSelect*/}
            {/*  selectsStart*/}
            {/*  startDate={searchStartDate}*/}
            {/*  endDate={searchEndDate}*/}
            {/*  dateFormat="dd/MM/yyyy HH:mm"*/}
            {/*  selected={searchStartDate}*/}
            {/*  onChange={(date) => this.setStartDate(date)}/>*/}
            
            {/*<DatePicker*/}
            {/*  className="form-control"*/}
            {/*  name="searchStartDate" id="searchEndDate"*/}
            {/*  isClearable*/}
            {/*  showTimeSelect*/}
            {/*  selectsEnd*/}
            {/*  startDate={searchStartDate}*/}
            {/*  endDate={searchEndDate}*/}
            {/*  minDate={searchStartDate}*/}
            {/*  dateFormat="dd/MM/yyyy HH:mm"*/}
            {/*  selected={searchEndDate}*/}
            {/*  onChange={(date) => this.setEndDate(date)}/>*/}
          </div>
        </div>
        
        <div className="row">
          <div className="col-12">
            <table className="table table-hover" ref={el => this.el = el}>
            </table>
          </div>
        </div>
        
        
        {
          (recordsFiltered > 0)
            ? <div>
              {
                (recordsFiltered > RECORDING_MAX_BULK_ACTIONS)
                  ? <div className="row mt-5 mb-5">
                    <div className="col-12">
                      <div className="alert alert-warning" role="alert">
                        <i className="fas fa-exclamation-circle"></i> Bulk actions are allowed only for a maximum
                        of {RECORDING_MAX_BULK_ACTIONS} total results. Please adjust your filters.
                      </div>
                      
                      <div className="all-actions">
                        <button type="button" className="btn btn-outline-info download-all disabled">
                          <i className="fas fa-download"></i> Download All
                        </button>
                        
                        {
                          (isRecordingW())
                            ? <button type="button" className="btn btn-outline-danger disabled">
                              <i className="fas fa-trash"></i> Delete All
                            </button>
                            : null
                        }
                      </div>
                    </div>
                  </div>
                  : <div className="row mt-5 mb-5">
                    <div className="col-6">
                      <div className="alert alert-success" role="alert">
                        <i className="fas fa-check-circle"></i> Bulk actions for the above results
                      </div>
                      
                      <div className="all-actions">
                        {
                          (downloadAllRecordingProcess === false)
                            ? <a aria-label="Download All Files" data-cooltipz-dir="left" href="" onClick={(event) => {
                              event.preventDefault();
                              this.downloadAll();
                            }}>
                              <button type="button" className="btn btn-outline-info download-all">
                                <i className="fas fa-download"></i> Download All
                              </button>
                            </a>
                            : <button type="button" className="btn btn-outline-info download-all disabled">
                              <span className="spinner-border spinner-border-sm" role="status"
                                    aria-hidden="true"></span> Download All
                            </button>
                        }
                        
                        
                        {
                          (isRecordingW())
                            ? <span>
                              {
                                (deleteAllRecordingProcess === false)
                                  ? <a aria-label="Delete All Files" data-cooltipz-dir="right" href=""
                                       onClick={(event) => {
                                         event.preventDefault();
                                         this.deleteAllRecordingButton();
                                       }}>
                                    <button type="button" className="btn btn-outline-danger">
                                      <i className="fas fa-trash"></i> Delete All
                                    </button>
                                  </a>
                                  : <button type="button" className="btn btn-outline-danger disabled">
                                    <span className="spinner-border spinner-border-sm" role="status"
                                          aria-hidden="true"></span> Delete All
                                  </button>
                              }
                            </span>
                            : null
                        }
                      </div>
                    </div>
                  </div>
              }
            </div>
            : null
        }
      
      </div>
    );
  };
}

export default connect(
  (state) => {
    return {
      deleteRecordingProcess: state.recording.deleteRecordingProcess,
      deleteAllRecordingProcess: state.recording.deleteAllRecordingProcess,
      downloadAllRecordingProcess: state.recording.downloadAllRecordingProcess
    };
  },
  {
    deleteRecordingSearch: (recordingId) => deleteRecordingSearch(recordingId),
    deleteAllRecordingSearch: (searchParams) => deleteAllRecordingSearch(searchParams),
    downloadAllRecordingSearch: (searchParams) => downloadAllRecordingSearch(searchParams),
  }
)(RecordingSearch);

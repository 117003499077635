import React, { Component }      from 'react';
import connect                   from "react-redux/es/connect/connect";
import { Route, Router, Switch } from 'react-router-dom';
import history                   from '../history';
import tokenService              from "../security/TokenService";
import DefaultLayout             from "../components/layouts/DefaultLayout";
import Login                     from "../components/login/Login";
import Logout                    from "../components/login/Logout";
import { loadUserLocalStorage }  from "../redux/userSlice";
import UserList                  from "../components/user/UserList";
import PropTypes                 from "prop-types";
import UserProfile               from "../components/user/UserProfile";
import AddUser                   from "../components/user/AddUser";
import Dashboards                from "../components/dashboards/Dashboards";
import CliList                   from "../components/cli/CliList";
import DialerList                from "../components/dialer/DialerList";
import AddCli                    from "../components/cli/AddCli";
import CliProfile                from "../components/cli/CliProfile";
import AddCliChange              from "../components/cli/AddCliChange";
import CliChangeProfile          from "../components/cli/CliChangeProfile";
import MyProfile                 from "../components/user/MyProfile";
import DistributionList          from "../components/distribution/DistributionList";
import AddDialer                 from "../components/dialer/AddDialer";
import DialerProfile             from "../components/dialer/DialerProfile";
import CloneDialer               from "../components/dialer/CloneDialer";
import AddDistribution           from "../components/distribution/AddDistribution";
import DistributionProfile       from "../components/distribution/DistributionProfile";
import AddDialerDestination      from "../components/dialer/AddDialerDestination";
import DialerDestinationProfile  from "../components/dialer/DialerDestinationProfile";
import NotFound404               from "../errors/NotFound404";
import {
  AdminPageAuth,
  CliRPageAuth,
  CliWPageAuth,
  DashboardRPageAuth,
  DialerRPageAuth,
  DialerWPageAuth,
  FasRPageAuth,
  FasWPageAuth,
  RecordingRPageAuth,
  RecordingWPageAuth
}                                from "../security/Authorization";
import Forbidden403              from "../errors/Forbidden403";
import CloneCli                  from "../components/cli/CloneCli";
import AuditList                 from "../components/audit/AuditList";
import FasList                   from "../components/fas/FasList";
import AddFas                    from "../components/fas/AddFas";
import FasProfile                from "../components/fas/FasProfile";
import RecordingProfile          from "../components/recording/RecordingProfile";
import RecordingList             from "../components/recording/RecordingList";
import AddRecording              from "../components/recording/AddRecording";
import RecordingSearch           from "../components/recording/RecordingSearch";

const UserListAuth = AdminPageAuth(UserList);
const AddUserAuth = AdminPageAuth(AddUser);

const CliListAuth = CliRPageAuth(CliList);
const AddCliAuth = CliWPageAuth(AddCli);
const CloneCliAuth = CliWPageAuth(CloneCli);
const AddCliChangeAuth = CliWPageAuth(AddCliChange);

const DialerListAuth = DialerRPageAuth(DialerList);
const AddDialerAuth = DialerWPageAuth(AddDialer);
const CloneDialerAuth = DialerWPageAuth(CloneDialer);
const AddDialerDestinationAuth = DialerWPageAuth(AddDialerDestination);

const DistributionListAuth = DialerRPageAuth(DistributionList);
const AddDistributionAuth = DialerWPageAuth(AddDistribution);

const FasListAuth = FasRPageAuth(FasList);
const AddFasAuth = FasWPageAuth(AddFas);

const RecordingListAuth = RecordingRPageAuth(RecordingList);
const AddRecordingAuth = RecordingWPageAuth(AddRecording);
const RecordingSearchAuth = RecordingRPageAuth(RecordingSearch);

const DashboardsAuth = DashboardRPageAuth(Dashboards);

const AuditListAuth = AdminPageAuth(AuditList);

class AppRoutes extends Component {
  static propTypes = {
    loadUserLocalStorage: PropTypes.func.isRequired,
  };
  
  constructor(props) {
    super(props);
    
    const { loadUserLocalStorage } = this.props;
    
    // try to load the user from the local storage and save the info into the app state
    const user = tokenService.getUser();
    
    if (user !== undefined) {
      loadUserLocalStorage(user);
    }
  }
  
  componentDidMount() {
  }
  
  render() {
    return (<Router history={history}>
      <Route path="/login" component={Login}/>
      <Route path="/logout" component={Logout}/>
      
      <Switch>
        <Route path="/profile" exact>
          <DefaultLayout>
            <MyProfile/>
          </DefaultLayout>
        </Route>
      </Switch>
      
      <Switch>
        <Route path="/" exact>
          <DefaultLayout>
            <UserListAuth/>
          </DefaultLayout>
        </Route>
      </Switch>
      
      {/*************** /admin path (main path) ***************/}
      <Route path="/admin/:path?">
        <DefaultLayout>
          <Switch>
            {/*************** default /admin  - should be updated depending on user's roles ***************/}
            <Route path="/admin" exact>
              <UserListAuth/>
            </Route>
            
            {/*************** errors paths ***************/}
            <Route path="/admin/404" exact>
              <NotFound404/>
            </Route>
            
            <Route path="/admin/403" exact>
              <Forbidden403/>
            </Route>
            
            {/*************** users paths ***************/}
            <Route path="/admin/users" exact>
              <UserListAuth/>
            </Route>
            <Route path="/admin/users/edit/:id" exact component={UserProfile}/>
            <Route path="/admin/users/add/user" exact>
              <AddUserAuth/>
            </Route>
            
            {/*************** CLI paths ***************/}
            <Route path="/admin/cli" exact>
              <CliListAuth/>
            </Route>
            <Route path="/admin/cli/edit/:id" exact component={CliProfile}/>
            <Route path="/admin/cli/clone/:id" exact component={CloneCliAuth}/>
            <Route path="/admin/cli/add/cli" exact>
              <AddCliAuth/>
            </Route>
            
            <Route path="/admin/cli/:id/cli-change/add/cli-change" exact component={AddCliChangeAuth}/>
            <Route path="/admin/cli/cli-change/edit/:id" exact component={CliChangeProfile}/>
            
            {/*************** dialer paths ***************/}
            <Route path="/admin/dialer" exact>
              <DialerListAuth/>
            </Route>
            <Route path="/admin/dialer/edit/:id" exact component={DialerProfile}/>
            <Route path="/admin/dialer/clone/:id" exact component={CloneDialerAuth}/>
            <Route path="/admin/dialer/add/dialer" exact>
              <AddDialerAuth/>
            </Route>
            
            <Route path="/admin/dialer/:id/dialer-destination/add/dialer-destination" exact
                   component={AddDialerDestinationAuth}/>
            <Route path="/admin/dialer/dialer-destination/edit/:id" exact component={DialerDestinationProfile}/>
            
            {/*************** distribution paths ***************/}
            <Route path="/admin/distribution" exact>
              <DistributionListAuth/>
            </Route>
            <Route path="/admin/distribution/edit/:id" exact component={DistributionProfile}/>
            <Route path="/admin/distribution/add/distribution" exact>
              <AddDistributionAuth/>
            </Route>
            
            {/*************** fas paths ***************/}
            <Route path="/admin/fas" exact>
              <FasListAuth/>
            </Route>
            <Route path="/admin/fas/edit/:id" exact component={FasProfile}/>
            <Route path="/admin/fas/add/fas" exact>
              <AddFasAuth/>
            </Route>
            
            {/*************** recording paths ***************/}
            <Route path="/admin/recgw" exact>
              <RecordingListAuth/>
            </Route>
            <Route path="/admin/recgw/edit/:id" exact component={RecordingProfile}/>
            <Route path="/admin/recgw/add/recgw" exact>
              <AddRecordingAuth/>
            </Route>
            
            <Route path="/admin/recording" exact>
              <RecordingSearchAuth/>
            </Route>
            
            {/*************** audit paths ***************/}
            <Route path="/admin/audit" exact>
              <AuditListAuth/>
            </Route>
            
            {/*************** extra paths ***************/}
            <Route path="/admin/dashboard" exact>
              <DashboardsAuth/>
            </Route>
          
          </Switch>
        </DefaultLayout>
      </Route>
    
    </Router>);
  }
}

export default connect((state) => {
  return {};
}, {
  loadUserLocalStorage: (user) => loadUserLocalStorage(user)
})(AppRoutes);

import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import connect              from "react-redux/es/connect/connect";

import { performLogin } from "../../redux/userSlice";

import './Login.scss';

class Login extends Component {
  // check the received props
  static propTypes = {
    user: PropTypes.object,
    
    performLogin: PropTypes.func.isRequired,
  };
  
  constructor(props) {
    super(props);
    
    this.state = {
      username: '',
      password: '',
    };
    
    this.handleChange = this.handleChange.bind(this);
  }
  
  handleChange = (event) => {
    const { target } = event;
    const value = target.value;
    const { name } = target;
    this.setState({
      [name]: value,
    });
  };
  
  submitForm(event) {
    const { performLogin } = this.props;
    
    event.preventDefault();
    
    performLogin(this.state.username, this.state.password);
  }
  
  render() {
    const { user } = this.props;
    const { username, password } = this.state;
    
    return (
      <div className="login">
        {/*<DefaultHeader/>*/}
        
        <div className="row login-form">
          <div className="col-4 offset-4 jumbotron">
            <h2>Sign In</h2>
            <form onSubmit={(event) => this.submitForm(event)}>
              <div className="mb-3 mt-3">
                <label htmlFor="username" className="form-label">Username</label>
                <input type="text" className="form-control" name="username" id="username" placeholder="username"
                       value={username}
                       onChange={(event) => this.handleChange(event)} required/>
              </div>
              <div className="mb-4 mt-3">
                <label htmlFor="password" className="form-label">Password</label>
                <input type="password" className="form-control" name="password" id="password" placeholder="********"
                       value={password}
                       onChange={(event) => this.handleChange(event)} required/>
              </div>
              
              <button type="submit" className="btn btn-primary">Submit</button>
            </form>
            
            {(user.fail !== undefined)
              ? <div className="alert alert-danger login-error" role="alert">
                {user.fail}
              </div>
              : null
            }
          </div>
        </div>
        
        {/*<DefaultFooter/>*/}
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      user: state.user
    };
  },
  {
    performLogin: (username, password) => performLogin(username, password),
  }
)(Login);

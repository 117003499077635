import React, { Component }                                  from 'react';

import "./AddDistribution.scss";
import connect                                               from "react-redux/es/connect/connect";
import { clearStateCreatedDistribution, createDistribution } from "../../redux/distributionSlice";
import PropTypes                                             from "prop-types";
import _                                                     from "lodash";
import { getTrackBackground, Range }                         from "react-range";

export const STEP = 1;
export const MIN = 0;    // Min, Max should be 1 day (0, 24)
export const MAX = 24;
export const MIN_RANGE_SIZE = 2;
export const COLOR = "#548BF4";

class AddDistribution extends Component {
  static propTypes = {
    createdDistribution: PropTypes.object,
    createDistributionProcess: PropTypes.bool,
    
    createDistribution: PropTypes.func.isRequired,
    clearStateCreatedDistribution: PropTypes.func.isRequired,
  };
  
  constructor(props) {
    super(props);
    
    this.state = {
      distributionName: "",
      distributionItems: [],
      
      values: [12],             // half day
      colors: [COLOR, COLOR],   // this needs to be true: values.length + 1 === colors.length;
    };
    
    this.handleChange = this.handleChange.bind(this);
    this.handleItemChange = this.handleItemChange.bind(this);
    this.initIntervals = this.initIntervals.bind(this);
    this.updateIntervals = this.updateIntervals.bind(this);
    this.addInterval = this.addInterval.bind(this);
    this.computeIntervals = this.computeIntervals.bind(this);
  }
  
  componentDidMount() {
    this.initIntervals();
  }
  
  componentWillUnmount() {
    const { clearStateCreatedDistribution } = this.props;
    clearStateCreatedDistribution();
  }
  
  handleChange = (event) => {
    const { target } = event;
    let { id } = target;
    let value = target.value;
    
    if (target.type === "checkbox") {
      value = target.checked;
    } else {
      if (target.type === "radio") {
        id = target.name;
        value = target.value;
      } else {
        value = target.value;
      }
    }
    
    this.setState({
      [id]: value,
    });
  };
  
  submitForm(event) {
    const { createDistribution } = this.props;
    
    event.preventDefault();
    
    createDistribution(this.state);
  }
  
  /**
   * Update item values.
   */
  handleItemChange = (event) => {
    const { target } = event;
    const { id, value } = target;
    
    // we have a path hierarchy
    const path = id.split(".");
    if (path.length === 2) {
      const index = path[0];
      const field = path[1];
      
      const distributionItems = [...this.state.distributionItems];
      const item = { ...distributionItems[index] };
      item[field] = value;
      distributionItems[index] = item;
      this.setState({ distributionItems });
    } else {
      console.error("something is wrong with the interval item id: " + id);
    }
  };
  
  /**
   * Init the intervals.
   */
  initIntervals() {
    const values = [MIN, ...this.state.values, MAX];
    const distributionItems = [];
    
    for (let i = 0; i < values.length - 1; i++) {
      const distributionItem = {
        intervalStart: values[i],
        intervalEnd: values[i + 1],
        minutesPercentage: undefined,
        acdVariation: undefined,
        asrVariation: undefined
      };
      
      distributionItems.push(distributionItem);
    }
    
    this.setState({ distributionItems });
  }
  
  /**
   * Update intervals values.
   */
  updateIntervals(values) {
    // only allow unique values
    if (_.uniq([MIN, ...values, MAX]).length === [MIN, ...values, MAX].length) {
      this.setState({ values });
      
      // just to be sure that the this.state.values were updated
      setTimeout(() => {
        this.computeIntervals();
      }, 50);
    }
  }
  
  /**
   * Add a new interval for the distribution.
   */
  addInterval() {
    // find new "free" interval value
    const values = [MIN, ...this.state.values, MAX];
    
    let interval;
    for (let i = MAX; i >= MIN; i--) {
      if (!values.includes(i)) {
        interval = i;
        break;
      }
    }
    
    const newValues = _.sortBy([...this.state.values, interval]);
    
    this.setState({
      values: newValues,
      colors: [...this.state.colors, COLOR],  // just add another color
    });
    
    // just to be sure that the this.state.values were updated
    setTimeout(() => {
      this.computeIntervals();
    }, 50);
  }
  
  /**
   * Compute the intervals.
   */
  computeIntervals() {
    const values = [MIN, ...this.state.values, MAX];
    const distributionItems = [];
    
    for (let i = 0; i < values.length - 1; i++) {
      let distributionItem;
      
      // check if we have a new distribution item, in that case we just create a new item with undefined values
      if (!_.isNil(this.state.distributionItems[i])) {
        distributionItem = _.cloneDeep(this.state.distributionItems[i]);
        distributionItem.intervalStart = values[i];
        distributionItem.intervalEnd = values[i + 1];
      } else {
        distributionItem = {
          intervalStart: values[i],
          intervalEnd: values[i + 1],
          minutesPercentage: undefined,
          acdVariation: undefined,
          asrVariation: undefined
        };
      }
      
      distributionItems.push(distributionItem);
    }
    
    this.setState({ distributionItems });
  }
  
  render() {
    const { distributionName, distributionItems } = this.state;
    const { createdDistribution, createDistributionProcess } = this.props;
    
    return (
      <div className="add-distribution section">
        
        <div className="section-title">
          <h2>Add new Distribution</h2>
        </div>
        
        <div className="row">
          <div className="col-12">
            <form onSubmit={(event) => this.submitForm(event)}>
              
              <div className="row mt-3">
                <div className="col-6">
                  <label htmlFor="distributionName" className="form-label">Distribution Name</label>
                  <input type="text" className="form-control" name="distributionName" id="distributionName"
                         placeholder="distribution name"
                         value={distributionName}
                         onChange={(event) => this.handleChange(event)} required/>
                  <div className="form-text">Please provide a unique distribution name</div>
                </div>
                
                <div className="col-6 d-flex align-self-center justify-content-center">
                  <button type="button" className="btn btn-primary" onClick={() => this.addInterval()}>Add New
                    Interval
                  </button>
                </div>
              
              </div>
              
              <div className="row mt-4">
                <div className="col-12 mb-4">
                  <label className="form-label">Distribution Ranges</label>
                </div>
                <div className="col-12">
                  <Range
                    key={this.state.values.length}
                    values={this.state.values}
                    step={STEP}
                    min={MIN}
                    max={MAX}
                    onChange={(values) => this.updateIntervals(values)}
                    renderTrack={({ props, children }) => (
                      <div
                        onMouseDown={props.onMouseDown}
                        onTouchStart={props.onTouchStart}
                        style={{
                          ...props.style,
                          height: "36px",
                          display: "flex",
                          width: "100%"
                        }}
                      >
                        <div
                          ref={props.ref}
                          style={{
                            height: "8px",
                            width: "100%",
                            borderRadius: "4px",
                            background: getTrackBackground({
                              values: this.state.values,
                              colors: this.state.colors,
                              min: MIN,
                              max: MAX,
                            }),
                            alignSelf: "center"
                          }}
                        >
                          {children}
                        </div>
                      </div>
                    )}
                    renderThumb={({ index, props, isDragged }) => (
                      <div
                        {...props}
                        style={{
                          ...props.style,
                          height: "36px",
                          width: "36px",
                          borderRadius: "4px",
                          backgroundColor: "#FFF",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          boxShadow: "0px 2px 6px #AAA"
                        }}
                      >
                        <div
                          style={{
                            position: 'absolute',
                            top: '-32px',
                            color: '#fff',
                            fontWeight: 'bold',
                            fontSize: '14px',
                            padding: '4px',
                            borderRadius: '4px',
                            backgroundColor: '#548BF4'
                          }}
                        >
                          {this.state.values[index].toFixed(0)}
                        </div>
                        <div
                          style={{
                            height: "16px",
                            width: "5px",
                            backgroundColor: isDragged ? "#548BF4" : "#CCC"
                          }}
                        />
                      </div>
                    )}
                  />
                </div>
              </div>
              
              <div className="row mt-3">
                {
                  distributionItems.map((item, index) => <div className="col-4 mb-3"
                                                              key={index}>
                    <div className="card">
                      <div className="card-body">
                        <h5
                          className="card-title d-flex justify-content-center">{item.intervalStart} to {item.intervalEnd}</h5>
                        
                        <div className="mb-3 mt-3">
                          <label htmlFor="asrVariation" className="form-label">ASR Variation</label>
                          <input type="number" className="form-control" name="asrVariation" id={index + ".asrVariation"}
                                 placeholder=""
                                 value={item.asrVariation}
                                 onChange={(event) => this.handleItemChange(event)} required/>
                        </div>
                        
                        <div className="mb-3 mt-3">
                          <label htmlFor="acdVariation" className="form-label">ACD Variation</label>
                          <input type="number" className="form-control" name="acdVariation" id={index + ".acdVariation"}
                                 placeholder=""
                                 value={item.acdVariation}
                                 onChange={(event) => this.handleItemChange(event)} required/>
                        </div>
                        
                        <div className="mb-3 mt-3">
                          <label htmlFor="minutesPercentage" className="form-label">Minutes Percentage</label>
                          <input type="number" className="form-control" name="minutesPercentage"
                                 id={index + ".minutesPercentage"}
                                 placeholder=""
                                 value={item.minutesPercentage}
                                 onChange={(event) => this.handleItemChange(event)} required/>
                        </div>
                      </div>
                    </div>
                  </div>)
                }
              </div>
              
              <div className="row mt-4">
                <div className="col-12">
                  {
                    (createDistributionProcess === false)
                      ? <button type="submit" className="btn btn-primary"><i className="fas fa-save"></i> Create
                        Distribution
                      </button>
                      : <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Create
                        Distribution
                      </button>
                  }
                </div>
              </div>
            
            </form>
            
            {(!_.isEmpty(createdDistribution.errors))
              ? <div className="alert alert-danger login-error" role="alert">
                {
                  createdDistribution.errors.map(error => <div key={error.fieldName + error.message}>
                    {
                      (error.fieldName !== undefined)
                        ? error.fieldName + " - "
                        : null
                    }
                    {error.message}
                  </div>)
                }
              </div>
              : null
            }
          </div>
        </div>
      
      </div>
    );
  };
}

export default connect(
  (state) => {
    return {
      createdDistribution: state.distribution.createdDistribution,
      createDistributionProcess: state.distribution.createDistributionProcess
    };
  },
  {
    createDistribution: (distributionData) => createDistribution(distributionData),
    clearStateCreatedDistribution: () => clearStateCreatedDistribution()
  }
)(AddDistribution);

import React, { Component }                                                          from 'react';

import "./CliChangeProfile.scss";
import connect
                                                                                     from "react-redux/es/connect/connect";
import { clearStateCliChangeById, deleteCliNumber, editCliChange, getCliChangeById } from "../../redux/cliSlice";
import PropTypes                                                                     from "prop-types";
import history                                                                       from "../../history";
import _                                                                             from "lodash";
import Loading                                                                       from "../loading/Loading";
import ConfirmationModal                                                             from "../modal/ConfirmationModal";
import Select                                                                        from "react-select";
import { toOption, toParam, toSelector }                                             from "../../util/SelectorUtils";
import { API_FETCH_CLI_NUMBERS }                                                     from "../../api/Api";
import tokenService                                                                  from "../../security/TokenService";
import { saveFile }                                                                  from "../../util/File";
import {
  CliWCompAuth,
  isCliW
}                                                                                    from "../../security/Authorization";

const $ = require('jquery');
$.DataTable = require('datatables.net-bs5');

const UpdateButton = ({ editCliChangeProcess }) => {
  return <div className="col-4 d-flex align-self-center justify-content-center">
    {
      (editCliChangeProcess === false)
        ? <button type="submit" className="btn btn-primary"><i className="fas fa-redo"></i> Update
        </button>
        : <button className="btn btn-primary" type="button" disabled>
                          <span className="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span> Update
        </button>
    }
  </div>;
};
const UpdateButtonAuth = CliWCompAuth(UpdateButton);

class CliChangeProfile extends Component {
  static propTypes = {
    cliChangePolicies: PropTypes.array,
    cliChangeTypes: PropTypes.array,
    
    cliChangeProfile: PropTypes.object,
    cliChangeProfileLoaded: PropTypes.bool,
    
    editCliChangeProcess: PropTypes.bool,
    deleteCliNumberProcess: PropTypes.bool,
    
    clearStateCliChangeById: PropTypes.func.isRequired,
    getCliChangeById: PropTypes.func.isRequired,
    editCliChange: PropTypes.func.isRequired,
    deleteCliNumber: PropTypes.func.isRequired,
  };
  
  constructor(props) {
    super(props);
    
    this.tableAPI = undefined;
    
    this.state = {
      deleteModalIsOpen: false,
      cliNumberId: undefined,
      cliNumberText: undefined,
      
      cliChangeId: undefined,
      cliChangePolicy: undefined,
      cliChangeType: undefined,
      enabled: undefined,
      file: undefined
    };
    
    this.toggleModal = this.toggleModal.bind(this);
    this.deleteCliNumber = this.deleteCliNumber.bind(this);
    this.deleteCliNumberButton = this.deleteCliNumberButton.bind(this);
    
    this.downloadFile = this.downloadFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangePolicyChange = this.handleChangePolicyChange.bind(this);
    this.handleChangeTypeChange = this.handleChangeTypeChange.bind(this);
  }
  
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps?.cliChangeProfile?.id !== prevState?.cliChangeId) {
      console.log("[[>>> getDerivedStateFromProps]]");
      return {
        ...prevState,
        cliChangeId: nextProps.cliChangeProfile.id,
        cliChangePolicy: toOption(nextProps.cliChangeProfile.cliChangePolicy),
        cliChangeType: toOption(nextProps.cliChangeProfile.cliChangeType),
        enabled: nextProps.cliChangeProfile.enabled,
      };
    } else {
      return null;
    }
  }
  
  componentDidMount() {
    const { getCliChangeById } = this.props;
    
    window.reactHistory = history;
    window.deleteCliNumberButton = this.deleteCliNumberButton;
    
    if (this.props?.match?.params?.id !== undefined) {
      const cliChangeId = _.parseInt(this.props.match.params.id);
      
      // no matter what fetch Cli Change and replace any data previously saved
      getCliChangeById(cliChangeId);
    }
    
    this.initTable();
  }
  
  componentWillUnmount() {
    const { clearStateCliChangeById } = this.props;
    
    if (this.props?.match?.params?.id !== undefined) {
      const cliChangeId = _.parseInt(this.props.match.params.id);
      clearStateCliChangeById(cliChangeId);
    }
    
    this.tableAPI.destroy();
  }
  
  componentDidUpdate(prevProps, prevState) {
    // update CLI Change Numbers list table only if user updated the list (new upload / delete)
    if ((prevProps.editCliChangeProcess === true && this.props.editCliChangeProcess === false)
      || (prevProps.deleteCliNumberProcess === true && this.props.deleteCliNumberProcess === false)) {
      console.log(">>>>>!!!!!!!!!!!!!!!!!!!!!!!>>>>>>>>");
      this.tableAPI.ajax.reload();
    }
  }
  
  toggleModal() {
    this.setState({
      deleteModalIsOpen: !this.state.deleteModalIsOpen
    });
  }
  
  deleteCliNumber(cliNumberId) {
    const { deleteCliNumber } = this.props;
    
    deleteCliNumber(cliNumberId);
    
    this.toggleModal();
  }
  
  deleteCliNumberButton(cliNumberId, destinationCli) {
    this.setState({
      deleteModalIsOpen: true,
      cliNumberId: cliNumberId,
      cliNumberText: destinationCli
    });
  }
  
  handleChange = (event) => {
    const { target } = event;
    let { id } = target;
    let value = target.value;
    
    if (target.type === "file") {
      value = target.files[0];
    } else {
      if (target.type === "checkbox") {
        value = target.checked;
      } else {
        if (target.type === "radio") {
          id = target.name;
          value = target.value;
        } else {
          value = target.value;
        }
      }
    }
    
    this.setState({
      [id]: value,
    });
  };
  
  handleChangePolicyChange = (cliChangePolicy) => {
    this.setState({
      cliChangePolicy
    });
  };
  
  handleChangeTypeChange = (cliChangeType) => {
    this.setState({
      cliChangeType
    });
  };
  
  downloadFile(file) {
    saveFile(file);
  }
  
  submitUpdateForm(event) {
    const { editCliChange } = this.props;
    event.preventDefault();
    
    // extract CLI Change id
    let cliChangeId;
    if (this.props?.match?.params?.id !== undefined) {
      cliChangeId = _.parseInt(this.props.match.params.id);
    }
    
    if (cliChangeId === undefined) {
      console.error("Something is wrong! we don't have the CLI Change id at this point!");
      return;
    }
    
    // create an object of formData
    const formData = new FormData();
    
    // update the formData object
    formData.append("cliChangePolicy", toParam(this.state.cliChangePolicy));
    formData.append("cliChangeType", toParam(this.state.cliChangeType));
    formData.append("enabled", this.state.enabled);
    formData.append("file", this.state.file);
    
    editCliChange(cliChangeId, formData);
  }
  
  initTable() {
    // extract CLI Change id
    let cliChangeId;
    if (this.props?.match?.params?.id !== undefined) {
      cliChangeId = _.parseInt(this.props.match.params.id);
    }
    
    if (cliChangeId === undefined) {
      console.error("Something is wrong! we don't have the CLI Change id at this point!");
      return;
    }
    
    this.$el = $(this.el);
    this.tableAPI = this.$el.DataTable({
      pageLength: 10,
      order: [],
      serverSide: true,
      ajax: {
        url: API_FETCH_CLI_NUMBERS.interpolate({
          cliChangeId
        }),
        headers: {
          Authorization: `Bearer ${tokenService.getUser()?.token}`,
          sessionId: tokenService.getUser()?.sessionId
        },
        data: function (formData) {
          return {
            draw: formData.draw,
            start: formData.start,
            length: formData.length,
            search: formData.search.value
          };
        },
        error: function (xhr, error, code) {
          // do nothing;
        }
      },
      columns: [
        { title: "Destination CLI", data: "destinationCli", orderable: false },
        {
          data: "createdDate",
          title: 'Created Date',
          wrap: true,
          orderable: false,
          render: function (item) {
            const date = new Date(item);
            return date.toLocaleDateString();
          },
        },
        { title: "Created By", data: "createdBy", orderable: false },
        {
          data: null,
          title: 'Actions',
          wrap: true,
          width: "105px",
          render: function (item) {
            if (isCliW()) {
              return '<div class="btn-group"> ' +
                '<a onclick="window.deleteCliNumberButton(' + item.id + ', \'' + item.destinationCli + '\')" class="cli-number-actions">' +
                '<button type="button" class="btn btn-outline-danger"><i class="fas fa-trash"></i> Delete</button>' +
                '</a>' +
                '</div>';
            }
            return '<div class="btn-group"> ' +
              '</div>';
          },
          orderable: false
        }
      ]
    });
  }
  
  render() {
    const { cliChangePolicy, cliChangeType, enabled } = this.state;
    const {
      cliChangePolicies,
      cliChangeTypes,
      cliChangeProfile,
      cliChangeProfileLoaded,
      editCliChangeProcess
    } = this.props;
    
    return (
      <div className="cli-change-profile section">
        
        <div className="section-title">
          <h2>Edit CLI Change Campaign</h2>
        </div>
        
        <Loading display={!cliChangeProfileLoaded}/>
        
        <ConfirmationModal
          isOpen={this.state.deleteModalIsOpen}
          title={"CLI Change Campaign Number: " + this.state.cliNumberText}
          content="<p>Are you sure you want to delete this CLI Change Campaign Number?</p>"
          actionText="Delete"
          toggle={this.toggleModal}
          doAction={() => this.deleteCliNumber(this.state.cliNumberId)}/>
        
        {
          (cliChangeProfile.destinationCode !== undefined)
            ? <div>
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">CLI Change Code: {cliChangeProfile.destinationCode} --
                    CLI Change Name: {cliChangeProfile.destinationName}</h5>
                  <p className="card-text">Created
                    By: {cliChangeProfile.createdBy} on {new Date(cliChangeProfile.createdDate).toLocaleDateString()} date</p>
                </div>
              </div>
              
              <hr className="bg-info m-4 border-info"/>
              
              <div className="row">
                <div className="col-12">
                  <form onSubmit={(event) => this.submitUpdateForm(event)}>
                    
                    <div className="row">
                      <div className="col-6">
                        <label htmlFor="cliChangePolicy" className="form-label">Cli Change Policy</label>
                        <Select
                          id="cliChangePolicy"
                          name="cliChangePolicy"
                          options={toSelector(cliChangePolicies)}
                          value={cliChangePolicy}
                          classNamePrefix="select"
                          onChange={(event) => this.handleChangePolicyChange(event)}/>
                      </div>
                      
                      <div className="col-6">
                        <label htmlFor="cliChangeType" className="form-label">Cli Change Type</label>
                        <Select
                          id="cliChangeType"
                          name="cliChangeType"
                          options={toSelector(cliChangeTypes)}
                          value={cliChangeType}
                          classNamePrefix="select"
                          onChange={(event) => this.handleChangeTypeChange(event)}/>
                      </div>
                    </div>
                    
                    <div className="row mt-3">
                      <div className="col-6">
                        <label htmlFor="file" className="form-label">Upload CLI Numbers</label>
                        <input className="form-control" type="file" name="file" id="file"
                               onChange={(event) => this.handleChange(event)}/>
                        <div className="form-text">(Optional) Upload an Excel document - this will override any
                          previous uploaded list
                        </div>
                      </div>
                      
                      <div className="col-2 d-flex justify-content-center">
                        <div className="mb-3">
                          <label htmlFor="enabled" className="form-label">Enabled</label>
                          <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" id="enabled"
                                   checked={enabled ? enabled : false}
                                   onChange={(event) => this.handleChange(event)}/>
                          </div>
                        </div>
                      </div>
                      
                      <UpdateButtonAuth editCliChangeProcess={editCliChangeProcess}/>
                    </div>
                    
                    {(!_.isEmpty(cliChangeProfile.errors))
                      ? <div className="alert alert-danger login-error" role="alert">
                        {
                          cliChangeProfile.errors.map(error => <div key={error.fieldName + error.message}>
                            {
                              (error.fieldName !== undefined)
                                ? error.fieldName + " - "
                                : null
                            }
                            {error.message}
                          </div>)
                        }
                      </div>
                      : null
                    }
                  </form>
                </div>
              </div>
              
              <hr className="bg-info m-4 border-info"/>
            </div>
            : null
        }
        
        {
          (!_.isEmpty(cliChangeProfile.files))
            ? <div className="row">
              <div className="col-12">
                <h5 className="download"><i className="fas fa-download"></i> Download previously uploaded files</h5>
              </div>
              
              <div className="col-12">
                <div className="table-responsive">
                  <table className="table align-middle">
                    <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Uploaded By</th>
                      <th scope="col">Uploaded Date</th>
                      <th scope="col">Download</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      cliChangeProfile.files.map(file => <tr key={file.id}>
                        <td><a aria-label="Download file" data-cooltipz-dir="right" href="" onClick={(event) => {
                          event.preventDefault();
                          this.downloadFile(file);
                        }}>{file.name}</a></td>
                        <td>{file.createdBy}</td>
                        <td>{new Date(file.createdDate).toLocaleDateString()}</td>
                        <td>
                          <button type="button" className="btn btn-primary" onClick={() => this.downloadFile(file)}>
                            <i className="fas fa-file-excel"></i> Download
                          </button>
                        </td>
                      </tr>)
                    }
                    </tbody>
                  </table>
                </div>
                
                <hr className="bg-info m-4 border-info"/>
              
              </div>
            
            </div>
            : null
        }
        
        <div className="row mt-4">
          <div className="col-12">
            <table className="table table-hover" ref={el => this.el = el}>
            </table>
          </div>
        </div>
      
      </div>
    );
  };
}

export default connect(
  (state, props) => {
    return {
      cliChangePolicies: state.metadata.list["cliChangePolicy"],
      cliChangeTypes: state.metadata.list["cliChangeType"],
      
      cliChangeProfile: state.cli.cliChangeProfile,
      cliChangeProfileLoaded: state.cli.cliChangeProfileLoaded,
      
      editCliChangeProcess: state.cli.editCliChangeProcess,
      deleteCliNumberProcess: state.cli.deleteCliNumberProcess
    };
  },
  {
    clearStateCliChangeById: (cliChangeId) => clearStateCliChangeById(cliChangeId),
    getCliChangeById: (cliChangeId) => getCliChangeById(cliChangeId),
    editCliChange: (cliChangeId, cliChangeData) => editCliChange(cliChangeId, cliChangeData),
    deleteCliNumber: (cliNumberId) => deleteCliNumber(cliNumberId),
  }
)(CliChangeProfile);

import _ from "lodash";

/**
 * Users/JWT Token Utils
 */
class TokenService {
  getLocalToken() {
    const user = this.getUser();
    return user?.token;
  }
  
  getLocalRefreshToken() {
    const user = this.getUser();
    return user?.refreshToken;
  }
  
  getUsername() {
    const user = this.getUser();
    return user?.username;
  }
  
  updateLocalTokens(token, refreshToken, sessionId) {
    const user = this.getUser();
    user.token = token;
    user.refreshToken = refreshToken;
    user.sessionId = sessionId;
    
    this.setUser(user);
  }
  
  getUser() {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      
      if (!_.isNil(user)) {
        return user;
      }
    } catch (e) {
      console.error(e);
      return undefined;
    }
    
    return undefined;
  }
  
  setUser(user) {
    localStorage.setItem("user", JSON.stringify(user));
  }
  
  hasRoles(allowedRoles) {
    const user = this.getUser();
    const roles = user?.roles;
    
    if (roles !== undefined) {
      for (let role of roles) {
        if (allowedRoles.includes(role)) {
          return true;
        }
      }
    }
    
    return false;
  }
  
  removeUser() {
    localStorage.removeItem("user");
  }
}

const tokenService = new TokenService();
export default tokenService;

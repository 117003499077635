import React, { Component }                     from 'react';

import "./RecordingList.scss";
import connect                                  from "react-redux/es/connect/connect";
import { Link }                                 from "react-router-dom";
import { RecordingWCompAuth, isRecordingW }     from "../../security/Authorization";
import PropTypes                                from "prop-types";
import history                                  from "../../history";
import _                                        from "lodash";
import Loading                                  from "../loading/Loading";
import ConfirmationModal                        from "../modal/ConfirmationModal";
import { deleteRecording, getListOfRecordings } from "../../redux/recordingSlice";

const $ = require('jquery');
$.DataTable = require('datatables.net-bs5');

const AddButton = () => {
  return <div className="row mt-5">
    <div className="col-12">
      <Link to="/admin/recgw/add/recgw">
        <button type="button" className="btn btn-lg btn-outline-success">
          <i className="fas fa-plus-circle"></i> Add Recording GW
        </button>
      </Link>
    </div>
  </div>;
};
const AddButtonAuth = RecordingWCompAuth(AddButton);

class RecordingList extends Component {
  static propTypes = {
    recordingList: PropTypes.array,
    listLoaded: PropTypes.bool,
    
    getListOfRecordings: PropTypes.func.isRequired,
    deleteRecording: PropTypes.func.isRequired,
  };
  
  constructor(props) {
    super(props);
    
    this.tableAPI = undefined;
    
    this.state = {
      deleteModalIsOpen: false,
      recordingId: undefined,
      recordingText: undefined
    };
    
    this.toggleModal = this.toggleModal.bind(this);
    this.deleteRecording = this.deleteRecording.bind(this);
    this.deleteRecordingButton = this.deleteRecordingButton.bind(this);
  }
  
  componentDidMount() {
    const { recordingList, listLoaded, getListOfRecordings } = this.props;
    window.reactHistory = history;
    window.deleteRecordingButton = this.deleteRecordingButton;
    
    if (listLoaded === false || _.isEmpty(recordingList)) {
      getListOfRecordings();
    }
    
    this.initTable();
  }
  
  componentWillUnmount() {
    this.tableAPI.destroy();
  }
  
  componentDidUpdate(prevProps, prevState) {
    // update Recording GW list table only if we receive a new list
    if (!_.isEqual(prevProps.recordingList, this.props.recordingList)) {
      this.updateTable();
    }
  }
  
  toggleModal() {
    this.setState({
      deleteModalIsOpen: !this.state.deleteModalIsOpen
    });
  }
  
  deleteRecording(recordingId) {
    const { deleteRecording } = this.props;
    deleteRecording(recordingId);
    this.toggleModal();
  }
  
  deleteRecordingButton(recordingId, recordingName) {
    this.setState({
      deleteModalIsOpen: true,
      recordingId: recordingId,
      recordingText: recordingName
    });
  }
  
  updateTable() {
    const { recordingList, listLoaded } = this.props;
    
    if (listLoaded === true) {
      this.tableAPI.clear();
      this.tableAPI.rows.add(recordingList);
      this.tableAPI.draw();
    }
  }
  
  initTable() {
    const { recordingList } = this.props;
    
    this.$el = $(this.el);
    this.tableAPI = this.$el.DataTable({
      data: recordingList,
      pageLength: 10,
      order: [],
      columns: [
        { title: "Recording GW ID", data: "recordingId" },
        { title: "Recording GW Name", data: "recordingName" },
        { title: "Number of Days", data: "numberOfDays" },
        {
          data: "createdDate",
          title: 'Created Date',
          wrap: true,
          orderable: false,
          render: function (item) {
            const date = new Date(item);
            return date.toLocaleDateString();
          },
        },
        { title: "Created By", data: "createdBy", orderable: false },
        {
          data: null,
          title: 'Actions',
          wrap: true,
          width: "210px",
          render: function (item) {
            if (isRecordingW()) {
              return '<div class="btn-group"> ' +
                '<a onclick="window.reactHistory.push(\'/admin/recgw/edit/' + item.id + '\')" class="recording-actions">' +
                '<button type="button" class="btn btn-outline-info"><i class="fas fa-edit"></i> Edit</button>' +
                '</a>' +
                '<a onclick="window.deleteRecordingButton(' + item.id + ', \'' + item.recordingName + '\')" class="recording-actions">' +
                '<button type="button" class="btn btn-outline-danger"><i class="fas fa-trash"></i> Delete</button>' +
                '</a>';
            }
            return '<div class="btn-group"> ' +
              '<a onclick="window.reactHistory.push(\'/admin/recgw/edit/' + item.id + '\')" class="recording-actions">' +
              '<button type="button" class="btn btn-outline-info"><i class="fas fa-eye"></i> View</button>' +
              '</a>' +
              '</div>';
          },
          orderable: false
        }
      ]
    });
  }
  
  render() {
    const { listLoaded } = this.props;
    
    return (
      <div className="list-of-recording section">
        
        <div className="section-title">
          <h2>List of Recording Gateways</h2>
        </div>
        
        <Loading display={!listLoaded}/>
        
        <ConfirmationModal
          isOpen={this.state.deleteModalIsOpen}
          title={"Recording GW: " + this.state.recordingText}
          content="<p>Are you sure you want to delete this Recording GW?</p>"
          actionText="Delete"
          toggle={this.toggleModal}
          doAction={() => this.deleteRecording(this.state.recordingId)}/>
        
        <div className="row">
          <div className="col-12">
            <table className="table table-hover" ref={el => this.el = el}>
            </table>
          </div>
        </div>
        
        <AddButtonAuth/>
      
      </div>
    );
  };
}

export default connect(
  (state) => {
    return {
      recordingList: state.recording.list,
      listLoaded: state.recording.listLoaded
    };
  },
  {
    getListOfRecordings: () => getListOfRecordings(),
    deleteRecording: (recordingId) => deleteRecording(recordingId),
  }
)(RecordingList);

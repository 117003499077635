import axios                            from "axios";
import history                          from "../history";
import tokenService                     from "../security/TokenService";
import { API_LOGIN, API_REFRESH_TOKEN } from "./Api";

const headers = {
  'Content-Type': 'application/json'
};

/**
 * Add JWT to the call headers
 */
const loadJWTToken = (url) => {
  // first check if there are APIs that don't need any Authorization, like LOGIN actions
  if (url !== API_LOGIN
    && url !== API_REFRESH_TOKEN) {
    // try to load the user from the local storage and extract JWT
    const user = tokenService.getUser();
    
    if (user !== undefined) {
      if (user.token !== undefined) {
        headers['Authorization'] = 'Bearer ' + user.token;
        headers['sessionId'] = user.sessionId;
      } else {
        headers['Authorization'] = 'Bearer Invalid';
        headers['sessionId'] = 'Session Invalid';
      }
    } else {
      headers['Authorization'] = 'Bearer Invalid';
      headers['sessionId'] = 'Session Invalid';
    }
  } else if (url === API_REFRESH_TOKEN) { // for refresh token we still need to send the sessionId header
    // try to load the user from the local storage and extract JWT
    const user = tokenService.getUser();
    
    if (user !== undefined) {
      if (user.sessionId !== undefined) {
        headers['Authorization'] = '';
        headers['sessionId'] = user.sessionId;
      } else {
        headers['Authorization'] = '';
        headers['sessionId'] = 'Session Invalid';
      }
    } else {
      headers['Authorization'] = '';
      headers['sessionId'] = 'Session Invalid';
    }
  } else {
    headers['Authorization'] = '';
    headers['sessionId'] = '';
  }
};

/**
 * Add interceptor to check if the JWT expired and add a refresh token API call.
 */
axios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (error) => {
    const originalRequest = error.config;
    
    if (originalRequest.url !== API_LOGIN
      && originalRequest.url !== API_REFRESH_TOKEN
      && error.response) {
      // forbidden - 403 page
      if (error.response.status === 403) {
        history.push('/admin/403');
      }
      
      // not found - 404 page
      if (error.response.status === 404) {
        history.push('/admin/404');
      }
      
      // JWT is expired
      if (error.response.status === 401 && !originalRequest.retry) {
        originalRequest.retry = true;
        try {
          const response = await httpPost(API_REFRESH_TOKEN, {
            username: tokenService.getUsername(),
            refreshToken: tokenService.getLocalRefreshToken()
          });
          
          const { token, refreshToken, sessionId } = response.data;
          tokenService.updateLocalTokens(token, refreshToken, sessionId);
          
          originalRequest.headers['Authorization'] = 'Bearer ' + response.data.token;
          originalRequest.headers['sessionId'] = response.data.sessionId;
          return axios(originalRequest);
        } catch (reject) {
          tokenService.removeUser();
          history.push('/login');
          
          throw reject;
        }
      }
    }
    return Promise.reject(error);
  }
);

export const httpGet = (url, params) => {
  loadJWTToken(url);
  
  return axios.get(url, {
    responseType: "json",
    params: params,
    headers
  }).catch(reject => {
    throw reject;
  });
};

export const httpGetBlob = (url, params) => {
  loadJWTToken(url);
  
  return axios.get(url, {
    responseType: "blob", // arraybuffer
    params: params,
    headers
  }).catch(reject => {
    throw reject;
  });
};

export const httpDelete = (url, params) => {
  loadJWTToken(url);
  
  return axios.delete(url, {
    responseType: "json",
    params: params,
    headers
  }).catch(reject => {
    throw reject;
  });
};

export const httpPost = (url, data) => {
  loadJWTToken(url);
  
  return axios.post(url, data, {
    headers: headers
  }).catch(reject => {
    throw reject;
  });
};

export const httpPatch = (url, data) => {
  loadJWTToken(url);
  
  return axios.patch(url, data, {
    headers: headers
  }).catch(reject => {
    throw reject;
  });
};


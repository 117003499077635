import React, { Component }                           from 'react';

import "./AddCliChange.scss";
import connect                                        from "react-redux/es/connect/connect";
import { clearStateCreateCliChange, createCliChange } from "../../redux/cliSlice";
import PropTypes                                      from "prop-types";
import Select                                         from "react-select";
import { toParam, toSelector }                        from "../../util/SelectorUtils";
import _                                              from "lodash";

class AddCliChange extends Component {
  static propTypes = {
    cliChangePolicies: PropTypes.array,
    cliChangeTypes: PropTypes.array,
    
    createdCliChange: PropTypes.object,
    createCliChangeProcess: PropTypes.bool,
    
    createCliChange: PropTypes.func.isRequired,
    clearStateCreateCliChange: PropTypes.func.isRequired,
  };
  
  constructor(props) {
    super(props);
    
    this.state = {
      destinationCode: "",
      destinationName: "",
      cliChangePolicy: undefined,
      cliChangeType: undefined,
      enabled: true,
      file: undefined
    };
    
    this.handleChange = this.handleChange.bind(this);
    this.handleChangePolicyChange = this.handleChangePolicyChange.bind(this);
    this.handleChangeTypeChange = this.handleChangeTypeChange.bind(this);
  }
  
  componentWillUnmount() {
    const { clearStateCreateCliChange } = this.props;
    clearStateCreateCliChange();
  }
  
  handleChange = (event) => {
    const { target } = event;
    let { id } = target;
    let value = target.value;
    
    if (target.type === "file") {
      value = target.files[0];
    } else {
      if (target.type === "checkbox") {
        value = target.checked;
      } else {
        if (target.type === "radio") {
          id = target.name;
          value = target.value;
        } else {
          value = target.value;
        }
      }
    }
    
    this.setState({
      [id]: value,
    });
  };
  
  handleChangePolicyChange = (cliChangePolicy) => {
    this.setState({
      cliChangePolicy
    });
  };
  
  handleChangeTypeChange = (cliChangeType) => {
    this.setState({
      cliChangeType
    });
  };
  
  submitForm(event) {
    const { createCliChange } = this.props;
    event.preventDefault();
    
    // extract CLI id
    let cliId;
    if (this.props?.match?.params?.id !== undefined) {
      cliId = _.parseInt(this.props.match.params.id);
    }
    
    if (cliId === undefined) {
      console.error("Something is wrong! we don't have the CLI id at this point!");
      return;
    }
    
    // create an object of formData
    const formData = new FormData();
    
    const cliChangeCreate = new Blob([JSON.stringify({
      destinationCode: this.state.destinationCode,
      destinationName: this.state.destinationName,
      cliChangePolicy: toParam(this.state.cliChangePolicy),
      cliChangeType: toParam(this.state.cliChangeType),
      enabled: this.state.enabled
    })], {
      type: 'application/json'
    });
    
    // update the formData object
    formData.append("cliChangeCreate", cliChangeCreate);
    formData.append("file", this.state.file);
    
    createCliChange(cliId, formData);
  }
  
  render() {
    const { destinationCode, destinationName, cliChangePolicy, cliChangeType, enabled } = this.state;
    const { cliChangePolicies, cliChangeTypes, createdCliChange, createCliChangeProcess } = this.props;
    
    return (
      <div className="add-cli-change section">
        
        <div className="section-title">
          <h2>Add new CLI Change</h2>
        </div>
        
        <div className="row">
          <div className="col-12">
            <form onSubmit={(event) => this.submitForm(event)}>
              
              <div className="row mt-3">
                <div className="col-6">
                  <label htmlFor="destinationCode" className="form-label">Destination Code</label>
                  <input type="number" className="form-control" name="destinationCode" id="destinationCode"
                         placeholder="destination code"
                         value={destinationCode}
                         onChange={(event) => this.handleChange(event)} required/>
                  <div className="form-text">Please provide a unique number within this CLI Campaign</div>
                </div>
                
                <div className="col-6">
                  <label htmlFor="destinationName" className="form-label">Destination Name</label>
                  <input type="text" className="form-control" name="destinationName" id="destinationName"
                         placeholder="destination name"
                         value={destinationName}
                         onChange={(event) => this.handleChange(event)} required/>
                  <div className="form-text">Please provide a unique name within this CLI Campaign</div>
                </div>
              </div>
              
              <div className="row mt-3">
                <div className="col-6">
                  <label htmlFor="cliChangePolicy" className="form-label">Cli Change Policy</label>
                  <Select
                    id="cliChangePolicy"
                    name="cliChangePolicy"
                    options={toSelector(cliChangePolicies)}
                    value={cliChangePolicy}
                    classNamePrefix="select"
                    onChange={(event) => this.handleChangePolicyChange(event)}/>
                </div>
                
                <div className="col-6">
                  <label htmlFor="cliChangeType" className="form-label">Cli Change Type</label>
                  <Select
                    id="cliChangeType"
                    name="cliChangeType"
                    options={toSelector(cliChangeTypes)}
                    value={cliChangeType}
                    classNamePrefix="select"
                    onChange={(event) => this.handleChangeTypeChange(event)}/>
                </div>
              </div>
              
              <div className="row mt-3">
                <div className="col-6">
                  <label htmlFor="file" className="form-label">Upload CLI Numbers</label>
                  <input className="form-control" type="file" name="file" id="file"
                         onChange={(event) => this.handleChange(event)}/>
                  <div className="form-text">(Optional) Upload an Excel document - this can be edited later</div>
                </div>
                
                <div className="col-6 d-flex justify-content-center">
                  <div className="mb-3">
                    <label htmlFor="enabled" className="form-label">Enabled</label>
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" id="enabled"
                             checked={enabled ? enabled : false}
                             onChange={(event) => this.handleChange(event)}/>
                    </div>
                  </div>
                </div>
              </div>
              
              
              <div className="row mt-5">
                <div className="col-12">
                  {
                    (createCliChangeProcess === false)
                      ? <button type="submit" className="btn btn-primary"><i className="fas fa-save"></i> Create CLI
                        Change
                      </button>
                      : <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Create
                        CLI Change
                      </button>
                  }
                </div>
              </div>
            
            </form>
            
            {(!_.isEmpty(createdCliChange.errors))
              ? <div className="alert alert-danger login-error" role="alert">
                {
                  createdCliChange.errors.map(error => <div key={error.fieldName + error.message}>
                    {
                      (error.fieldName !== undefined)
                        ? error.fieldName + " - "
                        : null
                    }
                    {error.message}
                  </div>)
                }
              </div>
              : null
            }
          </div>
        </div>
      
      </div>
    );
  }
}

export default connect(
  (state, props) => {
    return {
      cliChangePolicies: state.metadata.list["cliChangePolicy"],
      cliChangeTypes: state.metadata.list["cliChangeType"],
      
      createdCliChange: state.cli.createdCliChange,
      createCliChangeProcess: state.cli.createCliChangeProcess
    };
  },
  {
    createCliChange: (cliId, cliChangeData) => createCliChange(cliId, cliChangeData),
    clearStateCreateCliChange: () => clearStateCreateCliChange()
  }
)(AddCliChange);

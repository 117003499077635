import "./MyProfile.scss";
import connect                                    from "react-redux/es/connect/connect";
import React, { Component }                       from "react";
import PropTypes                                  from "prop-types";
import _                                          from "lodash";
import { clearStatePasswordUser, updatePassword } from "../../redux/userSlice";

class MyProfile extends Component {
  static propTypes = {
    user: PropTypes.object,
    
    passwordUser: PropTypes.object,
    passwordUserProcess: PropTypes.bool,
    
    updatePassword: PropTypes.func.isRequired,
    clearStatePasswordUser: PropTypes.func.isRequired,
  };
  
  constructor(props) {
    super(props);
    
    this.state = {
      oldPassword: "",
      newPassword: "",
      confirmPassword: ""
    };
    
    this.handleChange = this.handleChange.bind(this);
  }
  
  componentWillUnmount() {
    const { clearStatePasswordUser } = this.props;
    clearStatePasswordUser();
  }
  
  handleChange = (event) => {
    const { target } = event;
    let { id } = target;
    let value = target.value;
    
    if (target.type === "checkbox") {
      value = target.checked;
    } else {
      if (target.type === "radio") {
        id = target.name;
        value = target.value;
      } else {
        value = target.value;
      }
    }
    
    this.setState({
      [id]: value,
    });
  };
  
  submitForm(event) {
    const { updatePassword } = this.props;
    
    event.preventDefault();
    
    const userData = {
      oldPassword: this.state.oldPassword,
      newPassword: this.state.newPassword,
      confirmPassword: this.state.confirmPassword,
    };
    
    updatePassword(undefined, userData);
  }
  
  render() {
    const { oldPassword, newPassword, confirmPassword } = this.state;
    const { user, passwordUser, passwordUserProcess } = this.props;
    
    return (
      <div className="my-profile section">
        <div className="section-title">
          <h2>My Profile</h2>
        </div>
        
        
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Username: {user.username}</h5>
            <p className="card-text">First Name: {user.firstName} -- Last Name: {user.lastName}</p>
          </div>
        </div>
        
        <div className="row">
          <div className="col-12">
            
            <form onSubmit={(event) => this.submitForm(event)} autoComplete="off">
              
              <div className="row mt-4">
                <div className="col-4">
                  <label htmlFor="oldPassword" className="form-label">Old Password</label>
                  <input type="password" className="form-control" name="oldPassword" id="oldPassword"
                         placeholder="********"
                         autoComplete="new-password" value={oldPassword}
                         onChange={(event) => this.handleChange(event)} required/>
                </div>
                
                <div className="col-4">
                  <label htmlFor="newPassword" className="form-label">New Password</label>
                  <input type="password" className="form-control" name="newPassword" id="newPassword"
                         placeholder="********"
                         autoComplete="off" value={newPassword}
                         onChange={(event) => this.handleChange(event)} required/>
                </div>
                
                <div className="col-4">
                  <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                  <input type="password" className="form-control" name="confirmPassword" id="confirmPassword"
                         placeholder="********"
                         autoComplete="off" value={confirmPassword}
                         onChange={(event) => this.handleChange(event)} required/>
                </div>
              </div>
              
              <div className="row mt-5">
                <div className="col-12">
                  {
                    (passwordUserProcess === false)
                      ? <button type="submit" className="btn btn-primary"><i className="fas fa-key"></i> Update Password
                      </button>
                      : <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Update
                        Password
                      </button>
                  }
                </div>
              </div>
            </form>
            
            {(!_.isEmpty(passwordUser.errors))
              ? <div className="alert alert-danger login-error" role="alert">
                {
                  passwordUser.errors.map(error => <div key={error.fieldName + error.message}>
                    {
                      (error.fieldName !== undefined)
                        ? error.fieldName + " - "
                        : null
                    }
                    {error.message}
                  </div>)
                }
              </div>
              : null
            }
          
          </div>
        </div>
      
      </div>
    );
  }
}

export default connect(
  (state, props) => {
    return {
      user: state.user.login,
      
      passwordUser: state.user.passwordUser,
      passwordUserProcess: state.user.passwordUserProcess
    };
  },
  {
    updatePassword: (userId, userData) => updatePassword(userId, userData),
    clearStatePasswordUser: () => clearStatePasswordUser()
  }
)(MyProfile);

import React, { Component }               from 'react';

import "./DialerList.scss";
import { Link }                           from "react-router-dom";
import connect                            from "react-redux/es/connect/connect";
import { deleteDialer, getListOfDialers } from "../../redux/dialerSlice";
import PropTypes                          from "prop-types";
import history                            from "../../history";
import _                                  from "lodash";
import Loading                            from "../loading/Loading";
import ConfirmationModal                  from "../modal/ConfirmationModal";
import { DialerWCompAuth, isDialerW }     from "../../security/Authorization";

const $ = require('jquery');
$.DataTable = require('datatables.net-bs5');

const AddButton = () => {
  return <div className="row mt-5">
    <div className="col-12">
      <Link to="/admin/dialer/add/dialer">
        <button type="button" className="btn btn-lg btn-outline-success">
          <i className="fas fa-plus-circle"></i> Add New Dialer Campaign
        </button>
      </Link>
    </div>
  </div>;
};
const AddButtonAuth = DialerWCompAuth(AddButton);

class DialerList extends Component {
  static propTypes = {
    dialerList: PropTypes.array,
    listLoaded: PropTypes.bool,
    
    getListOfDialers: PropTypes.func.isRequired,
    deleteDialer: PropTypes.func.isRequired,
  };
  
  constructor(props) {
    super(props);
    
    this.tableAPI = undefined;
    
    this.state = {
      deleteModalIsOpen: false,
      dialerId: undefined,
      dialerText: undefined
    };
    
    this.toggleModal = this.toggleModal.bind(this);
    this.deleteDialer = this.deleteDialer.bind(this);
    this.deleteDialerButton = this.deleteDialerButton.bind(this);
  }
  
  componentDidMount() {
    const { dialerList, listLoaded, getListOfDialers } = this.props;
    window.reactHistory = history;
    window.deleteDialerButton = this.deleteDialerButton;
    
    if (listLoaded === false || _.isEmpty(dialerList)) {
      getListOfDialers();
    }
    
    this.initTable();
  }
  
  componentWillUnmount() {
    this.tableAPI.destroy();
  }
  
  componentDidUpdate(prevProps, prevState) {
    // update Dialer list table only if we receive a new list
    if (!_.isEqual(prevProps.dialerList, this.props.dialerList)) {
      this.updateTable();
    }
  }
  
  toggleModal() {
    this.setState({
      deleteModalIsOpen: !this.state.deleteModalIsOpen
    });
  }
  
  deleteDialer(dialerId) {
    const { deleteDialer } = this.props;
    deleteDialer(dialerId);
    this.toggleModal();
  }
  
  deleteDialerButton(dialerId, dialerName) {
    this.setState({
      deleteModalIsOpen: true,
      dialerId: dialerId,
      dialerText: dialerName
    });
  }
  
  updateTable() {
    const { dialerList, listLoaded } = this.props;
    
    if (listLoaded === true) {
      this.tableAPI.clear();
      this.tableAPI.rows.add(dialerList);
      this.tableAPI.draw();
    }
  }
  
  initTable() {
    const { dialerList } = this.props;
    
    this.$el = $(this.el);
    this.tableAPI = this.$el.DataTable({
      data: dialerList,
      pageLength: 10,
      order: [],
      columns: [
        { title: "Dialer Id", data: "dialerId" },
        { title: "Dialer Name", data: "dialerName" },
        {
          data: "createdDate",
          title: 'Created Date',
          wrap: true,
          render: function (item) {
            const date = new Date(item);
            return date.toLocaleDateString();
          },
        },
        { title: "Created By", data: "createdBy" },
        {
          data: null,
          title: 'Actions',
          wrap: true,
          width: "315px",
          render: function (item) {
            // escape special characters
            const dialerName = item.dialerName.replace(/'/g, "\\'");
            
            if (isDialerW()) {
              return '<div class="btn-group"> ' +
                '<a onclick="window.reactHistory.push(\'/admin/dialer/edit/' + item.id + '\')" class="dialer-actions">' +
                '<button type="button" class="btn btn-outline-info"><i class="fas fa-edit"></i> Edit</button>' +
                '</a>' +
                '<a onclick="window.reactHistory.push(\'/admin/dialer/clone/' + item.id + '\')" class="dialer-actions">' +
                '<button type="button" class="btn btn-outline-warning"><i class="fas fa-clone"></i> Clone</button>' +
                '</a>' +
                '<a onclick="window.deleteDialerButton(' + item.id + ', \'' + dialerName + '\')" class="dialer-actions">' +
                '<button type="button" class="btn btn-outline-danger"><i class="fas fa-trash"></i> Delete</button>' +
                '</a>' +
                '</div>';
            }
            return '<div class="btn-group"> ' +
              '<a onclick="window.reactHistory.push(\'/admin/dialer/edit/' + item.id + '\')" class="dialer-actions">' +
              '<button type="button" class="btn btn-outline-info"><i class="fas fa-eye"></i> View</button>' +
              '</a>' +
              '</div>';
          },
          orderable: false
        }
      ]
    });
  }
  
  render() {
    const { listLoaded } = this.props;
    
    return (
      <div className="list-of-dialer section">
        
        <div className="section-title">
          <h2>List of Dialer Campaigns</h2>
        </div>
        
        <Loading display={!listLoaded}/>
        
        <ConfirmationModal
          isOpen={this.state.deleteModalIsOpen}
          title={"Dialer Campaign: " + this.state.dialerText}
          content="<p>Are you sure you want to delete this Dialer Campaign? <br/> <b>All associated data will be lost!</b></p>"
          actionText="Delete"
          toggle={this.toggleModal}
          doAction={() => this.deleteDialer(this.state.dialerId)}/>
        
        <div className="row">
          <div className="col-12">
            <table className="table table-hover" ref={el => this.el = el}>
            </table>
          </div>
        </div>
        
        <AddButtonAuth/>
      </div>
    );
  };
}

export default connect(
  (state) => {
    return {
      dialerList: state.dialer.list,
      listLoaded: state.dialer.listLoaded
    };
  },
  {
    getListOfDialers: () => getListOfDialers(),
    deleteDialer: (dialerId) => deleteDialer(dialerId),
  }
)(DialerList);



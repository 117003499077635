import React, { Component }                                from 'react';
import connect                                             from "react-redux/es/connect/connect";
import { Link }                                            from "react-router-dom";
import { ToastContainer }                                  from 'react-toastify';

import "./DefaultHeader.scss";
import PropTypes                                           from "prop-types";
import { PopoverBody, PopoverHeader, UncontrolledPopover } from "reactstrap";
import { getHomePage }                                     from "../../security/Authorization";

/**
 * The Default Header
 */
class DefaultHeader extends Component {
  static propTypes = {
    user: PropTypes.object
  };
  
  render() {
    const { user } = this.props;
    
    return (
      <div>
        <header id="header" className="fixed-top">
          <div className="container-fluid">
            <div className="row">
              <div className="col-8">
                <h1 className="logo me-auto"><Link to={getHomePage()}>Saste Admin Interface</Link></h1>
              </div>
              
              <div className="col-4">
                <Link to="/profile">
                  <div className="icons user float-end" aria-label="User Profile" data-cooltipz-dir="bottom">
                    <i className="fas fa-user fa-2x"></i>
                    <span className="username">{user?.username}</span>
                  </div>
                </Link>
                
                <Link to="/logout">
                  <div className="icons logout float-end" aria-label="Logout" data-cooltipz-dir="bottom">
                    <i className="fas fa-sign-out-alt fa-2x"></i>
                  </div>
                </Link>
                
                <div className="icons notifications float-end">
                  <i className="fas fa-bell fa-2x" id="notifications"></i>
                  <span className="notification-number translate-middle badge rounded-pill bg-danger">2</span>
                </div>
                <UncontrolledPopover
                  placement="bottom"
                  flip
                  target="notifications">
                  <PopoverHeader>
                    Notifications
                  </PopoverHeader>
                  <PopoverBody>
                    <div className="row">
                      <div className="col-12 border-bottom pb-3">Notification 1</div>
                      <div className="col-12 pt-3">Notification 2</div>
                    </div>
                  </PopoverBody>
                </UncontrolledPopover>
              </div>
            </div>
          </div>
        </header>
        
        <ToastContainer/>
      </div>
    );
  };
}


export default connect(
  (state) => {
    return {
      user: state.user.login
    };
  },
  {}
)(DefaultHeader);

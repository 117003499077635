import React, { Component }                                    from 'react';

import "./CloneDialer.scss";
import connect                                                 from "react-redux/es/connect/connect";
import _                                                       from "lodash";
import { clearStateCreatedDialer, cloneDialer, getDialerById } from "../../redux/dialerSlice";
import PropTypes                                               from "prop-types";
import Loading                                                 from "../loading/Loading";

class CloneDialer extends Component {
  static propTypes = {
    dialer: PropTypes.object,
    dialerDestinationLoaded: PropTypes.bool,
    
    createdDialer: PropTypes.object,
    createDialerProcess: PropTypes.bool,
    
    getDialerById: PropTypes.func.isRequired,
    cloneDialer: PropTypes.func.isRequired,
    clearStateCreatedDialer: PropTypes.func.isRequired,
  };
  
  constructor(props) {
    super(props);
    
    this.state = {
      dialerId: "",
      dialerName: "",
    };
    
    this.handleChange = this.handleChange.bind(this);
  }
  
  componentWillUnmount() {
    const { clearStateCreatedDialer } = this.props;
    clearStateCreatedDialer();
  }
  
  handleChange = (event) => {
    const { target } = event;
    let { id } = target;
    let value = target.value;
    
    if (target.type === "checkbox") {
      value = target.checked;
    } else {
      if (target.type === "radio") {
        id = target.name;
        value = target.value;
      } else {
        value = target.value;
      }
    }
    
    this.setState({
      [id]: value,
    });
  };
  
  submitForm(event) {
    const { cloneDialer } = this.props;
    
    event.preventDefault();
    
    // extract Dialer id
    let dialerId;
    if (this.props?.match?.params?.id !== undefined) {
      dialerId = _.parseInt(this.props.match.params.id);
    }
    if (dialerId === undefined) {
      console.error("Something is wrong! we don't have the Dialer id at this point!");
      return;
    }
    
    cloneDialer(dialerId, this.state);
  }
  
  componentDidMount() {
    const { dialer, dialerDestinationLoaded, getDialerById } = this.props;
    
    if (this.props?.match?.params?.id !== undefined) {
      const dialerId = _.parseInt(this.props.match.params.id);
      if (dialerDestinationLoaded === false || dialer === undefined) {
        getDialerById(dialerId);
      }
    }
  }
  
  render() {
    const { dialerId, dialerName } = this.state;
    const { dialer, dialerDestinationLoaded, createdDialer, createDialerProcess } = this.props;
    
    return (
      <div className="dialer-clone section">
        
        <div className="section-title">
          <h2>Clone Dialer Campaign</h2>
        </div>
        
        <Loading display={!dialerDestinationLoaded}/>
        
        <div className="alert alert-warning" role="alert">
          <i className="fas fa-exclamation-circle"></i> All the content of the bellow Dialer will be duplicated.
          Depending on the size of the Dialer this might take a while.
        </div>
        
        {
          (dialer !== undefined)
            ? <div className="card">
              <div className="card-body">
                <h5 className="card-title">Cloning Dialer with Unique Id: {dialer.dialerId} -- Dialer
                  Name: {dialer.dialerName}</h5>
                <p className="card-text">Created
                  By: {dialer.createdBy} on {new Date(dialer.createdDate).toLocaleDateString()} date</p>
              </div>
            </div>
            : null
        }
        
        {
          (dialer !== undefined)
            ? <div className="row">
              <div className="col-12">
                <form onSubmit={(event) => this.submitForm(event)}>
                  
                  <div className="row mt-3">
                    <div className="col-6">
                      <label htmlFor="dialerId" className="form-label">Dialer Id</label>
                      <input type="number" className="form-control" name="dialerId" id="dialerId"
                             placeholder="dialer id"
                             value={dialerId}
                             onChange={(event) => this.handleChange(event)} required/>
                      <div className="form-text">Please provide a unique number with exactly 5 digits</div>
                    </div>
                    
                    <div className="col-6">
                      <label htmlFor="dialerName" className="form-label">Dialer Name</label>
                      <input type="text" className="form-control" name="dialerName" id="dialerName"
                             placeholder="dialer name"
                             value={dialerName}
                             onChange={(event) => this.handleChange(event)} required/>
                      <div className="form-text">Please provide a unique name</div>
                    </div>
                  
                  </div>
                  
                  
                  <div className="row mt-5">
                    <div className="col-12">
                      {
                        (createDialerProcess === false)
                          ?
                          <button type="submit" className="btn btn-warning"><i className="fas fa-clone"></i> Clone
                            Dialer Campaign
                          </button>
                          : <button className="btn btn-warning" type="button" disabled>
                            <span className="spinner-border spinner-border-sm" role="status"
                                  aria-hidden="true"></span> Clone Dialer Campaign
                          </button>
                      }
                    </div>
                  </div>
                
                </form>
                
                {(!_.isEmpty(createdDialer.errors))
                  ? <div className="alert alert-danger login-error" role="alert">
                    {
                      createdDialer.errors.map(error => <div key={error.fieldName + error.message}>
                        {
                          (error.fieldName !== undefined)
                            ? error.fieldName + " - "
                            : null
                        }
                        {error.message}
                      </div>)
                    }
                  </div>
                  : null
                }
              </div>
            </div>
            : null
        }
      
      </div>
    );
  };
}

export default connect(
  (state, props) => {
    let dialerId;
    if (props?.match?.params?.id !== undefined) {
      dialerId = _.parseInt(props.match.params.id);
    }
    
    return {
      dialer: state.dialer.dialerDestinationList[dialerId],
      dialerDestinationLoaded: state.dialer.dialerDestinationLoaded[dialerId] !== undefined ? state.dialer.dialerDestinationLoaded[dialerId] : false,
      
      createdDialer: state.dialer.createdDialer,
      createDialerProcess: state.dialer.createDialerProcess
    };
  },
  {
    getDialerById: (dialerId) => getDialerById(dialerId),
    cloneDialer: (dialerId, dialerData) => cloneDialer(dialerId, dialerData),
    clearStateCreatedDialer: () => clearStateCreatedDialer()
  }
)(CloneDialer);

import { createSlice }         from "@reduxjs/toolkit";
import { fetchListOfMetadata } from "../api/Api";

const initialState = {
  list: {},             // list of metadata
  listLoaded: false
};

export const metadataSlice = createSlice({
  name: 'metadata',
  initialState,
  reducers: {
    fetchMetadata: (state, action) => {
      state.list = action.payload;
      state.listLoaded = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase("user/logoutSuccess", (state, action) => {
        return initialState;
      });
  }
});

export const {
  fetchMetadata,
} = metadataSlice.actions;

export const getListOfMetadata = () => async (dispatch, getState) => {
  try {
    const { data } = await fetchListOfMetadata();
    dispatch(fetchMetadata(data));
  } catch (error) {
    console.log(error);
  }
};

export default metadataSlice.reducer;

import React, { Component }                from 'react';

import "./AddFas.scss";
import connect                             from "react-redux/es/connect/connect";
import PropTypes                           from "prop-types";
import _                                   from "lodash";
import { clearStateCreatedFas, createFas } from "../../redux/fasSlice";

class AddFas extends Component {
  static propTypes = {
    createdFas: PropTypes.object,
    createFasProcess: PropTypes.bool,
    
    createFas: PropTypes.func.isRequired,
    clearStateCreatedFas: PropTypes.func.isRequired,
  };
  
  constructor(props) {
    super(props);
    
    this.state = {
      fasId: "",
      fasName: "",
      fasPercentage: undefined,
      busyPercentage: undefined,
      ringPercentage: undefined,
      file: undefined
    };
    
    this.handleChange = this.handleChange.bind(this);
  }
  
  componentWillUnmount() {
    const { clearStateCreatedFas } = this.props;
    clearStateCreatedFas();
  }
  
  handleChange = (event) => {
    const { target } = event;
    let { id } = target;
    let value = target.value;
    
    if (target.type === "file") {
      value = target.files[0];
    } else {
      if (target.type === "checkbox") {
        value = target.checked;
      } else {
        if (target.type === "radio") {
          id = target.name;
          value = target.value;
        } else {
          value = target.value;
        }
      }
    }
    
    this.setState({
      [id]: value,
    });
  };
  
  submitForm(event) {
    const { createFas } = this.props;
    event.preventDefault();
    
    // create an object of formData
    const formData = new FormData();
    
    const fasGatewayCreate = new Blob([JSON.stringify({
      fasId: this.state.fasId,
      fasName: this.state.fasName,
      fasPercentage: this.state.fasPercentage,
      busyPercentage: this.state.busyPercentage,
      ringPercentage: this.state.ringPercentage
    })], {
      type: 'application/json'
    });
    
    // update the formData object
    formData.append("fasGatewayCreate", fasGatewayCreate);
    formData.append("file", this.state.file);
    
    createFas(formData);
  }
  
  render() {
    const { fasId, fasName, fasPercentage, busyPercentage, ringPercentage } = this.state;
    const { createdFas, createFasProcess } = this.props;
    
    return (
      <div className="add-fas section">
        
        <div className="section-title">
          <h2>Add new FAS</h2>
        </div>
        
        <div className="row">
          <div className="col-12">
            
            <form onSubmit={(event) => this.submitForm(event)}>
              
              <div className="row mt-3">
                <div className="col-6">
                  <label htmlFor="fasId" className="form-label">FAS Id</label>
                  <input type="number" min={10000} max={99999} className="form-control" name="fasId" id="fasId"
                         placeholder="fas id"
                         value={fasId}
                         onChange={(event) => this.handleChange(event)} required/>
                  <div className="form-text">Please provide a unique number with exactly 5 digits</div>
                </div>
                
                <div className="col-6">
                  <label htmlFor="fasName" className="form-label">FAS Name</label>
                  <input type="text" className="form-control" name="fasName" id="fasName"
                         placeholder="fas name"
                         value={fasName}
                         onChange={(event) => this.handleChange(event)} required/>
                  <div className="form-text">Please provide a unique name</div>
                </div>
              </div>
              
              <div className="row mt-3">
                <div className="col-4">
                  <label htmlFor="fasPercentage" className="form-label">FAS Percentage</label>
                  <input type="number" className="form-control" name="fasPercentage" id="fasPercentage"
                         placeholder="percentage"
                         value={fasPercentage}
                         onChange={(event) => this.handleChange(event)} required/>
                </div>
                
                <div className="col-4">
                  <label htmlFor="busyPercentage" className="form-label">Busy Percentage</label>
                  <input type="number" className="form-control" name="busyPercentage" id="busyPercentage"
                         placeholder="percentage"
                         value={busyPercentage}
                         onChange={(event) => this.handleChange(event)} required/>
                </div>
                
                <div className="col-4">
                  <label htmlFor="ringPercentage" className="form-label">Ring Percentage</label>
                  <input type="number" className="form-control" name="ringPercentage" id="ringPercentage"
                         placeholder="percentage"
                         value={ringPercentage}
                         onChange={(event) => this.handleChange(event)} required/>
                </div>
              </div>
              
              <div className="row mt-3">
                <div className="col-6">
                  <label htmlFor="file" className="form-label">Upload FAS MP3 file</label>
                  <input className="form-control" type="file" name="file" id="file"
                         onChange={(event) => this.handleChange(event)} required/>
                  <div className="form-text">Upload a .mp3 file - this can be edited later</div>
                </div>
              </div>
              
              <div className="row mt-5">
                <div className="col-12">
                  {
                    (createFasProcess === false)
                      ? <button type="submit" className="btn btn-primary"><i className="fas fa-save"></i> Create FAS
                      </button>
                      : <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Create
                        FAS
                      </button>
                  }
                </div>
              </div>
            
            </form>
            
            {(!_.isEmpty(createdFas.errors))
              ? <div className="alert alert-danger login-error" role="alert">
                {
                  createdFas.errors.map(error => <div key={error.fieldName + error.message}>
                    {
                      (error.fieldName !== undefined)
                        ? error.fieldName + " - "
                        : null
                    }
                    {error.message}
                  </div>)
                }
              </div>
              : null
            }
          
          </div>
        </div>
      
      </div>
    );
  };
}

export default connect(
  (state) => {
    return {
      createdFas: state.fas.createdFas,
      createFasProcess: state.fas.createFasProcess
    };
  },
  {
    createFas: (fasData) => createFas(fasData),
    clearStateCreatedFas: () => clearStateCreatedFas()
  }
)(AddFas);

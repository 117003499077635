import { createSlice } from "@reduxjs/toolkit";
import {
  addCli,
  addCliChange,
  cloneCliById,
  deleteCliById,
  deleteCliChangeById,
  deleteCliNumberById,
  editCliChangeById,
  fetchCliById,
  fetchCliChangeById,
  fetchListOfClis
}                from "../api/Api";
import _         from "lodash";
import history   from "../history";
import { toast } from "react-toastify";

const initialState = {
  list: [],             // list of CLIs
  listLoaded: false,
  
  createdCli: {},       // new CLI entity/data
  createCliProcess: false,
  
  cliChangeList: {},    // Map of <Id, Cli>
  cliChangeLoaded: {},
  
  createdCliChange: {}, // new CLI Change entity/data
  createCliChangeProcess: false,
  
  cliChangeProfile: {}, // edit CLI Change entity (this is separate state since we need to load this every time)
  cliChangeProfileLoaded: false,
  
  editCliChangeProcess: false,
  deleteCliNumberProcess: false,
};

export const cliSlice = createSlice({
  name: 'cli',
  initialState,
  reducers: {
    /*****************************************************************************************
     * CLI Reducers
     *****************************************************************************************/
    clearCreatedCli: (state, action) => {
      state.createdCli = {};
    },
    
    createCliInvoked: (state, action) => {
      state.createCliProcess = true;
    },
    
    createCliSuccess: (state, action) => {
      state.createdCli = action.payload;
      state.list = [action.payload, ...state.list];
      state.createCliProcess = false;
    },
    
    createCliFailure: (state, action) => {
      // check if we have validation error or any other type of errors
      let errors;
      if (action.payload.errors === undefined) {
        errors = [];
        errors.push(action.payload);
      } else {
        errors = action.payload.errors;
      }
      
      state.createdCli.errors = errors;
      state.createCliProcess = false;
    },
    
    deleteCliSuccess: (state, action) => {
      const cliId = action.payload;
      state.list = _.filter(state.list, cli => cli.id !== cliId);
    },
    
    fetchClis: (state, action) => {
      state.list = action.payload;
      state.listLoaded = true;
    },
    
    fetchCliInvoked: (state, action) => {
      const cliId = action.payload;
      state.cliChangeLoaded[cliId] = false;
    },
    
    fetchCliFailure: (state, action) => {
      const cliId = action.payload;
      state.cliChangeLoaded[cliId] = false;
    },
    
    fetchCliSuccess: (state, action) => {
      const cliId = action.payload.id;
      state.cliChangeList[cliId] = action.payload;
      state.cliChangeLoaded[cliId] = true;
    },
    
    /*****************************************************************************************
     * CLI Change Reducers
     *****************************************************************************************/
    
    clearCreateCliChange: (state, action) => {
      state.createdCliChange = {};
    },
    
    createCliChangeInvoked: (state, action) => {
      state.createCliChangeProcess = true;
    },
    
    createCliChangeSuccess: (state, action) => {
      const { cliId, data } = action.payload;
      state.createdCliChange = data;
      state.createCliChangeProcess = false;
      
      if (state.cliChangeList[cliId] === undefined) {
        state.cliChangeList[cliId] = {
          cliChangeCampaigns: []
        };
      }
      
      state.cliChangeList[cliId].cliChangeCampaigns = [data, ...state.cliChangeList[cliId].cliChangeCampaigns];
    },
    
    createCliChangeFailure: (state, action) => {
      // check if we have validation error or any other type of errors
      let errors;
      if (action.payload.errors === undefined) {
        errors = [];
        errors.push(action.payload);
      } else {
        errors = action.payload.errors;
      }
      
      state.createdCliChange.errors = errors;
      state.createCliChangeProcess = false;
    },
    
    editCliChangeInvoked: (state, action) => {
      state.editCliChangeProcess = true;
    },
    
    editCliChangeSuccess: (state, action) => {
      state.cliChangeProfile = action.payload;
      state.editCliChangeProcess = false;
      
      // update the list of CLIs
      _.forEach(state.cliChangeList, function (value, key) {
        const cliChange = _.find(value.cliChangeCampaigns, item => item.id === action.payload.id);
        
        if (cliChange !== undefined) {
          cliChange.cliChangePolicy = action.payload.cliChangePolicy;
          cliChange.cliChangeType = action.payload.cliChangeType;
          cliChange.enabled = action.payload.enabled;
        }
      });
    },
    
    editCliChangeFailure: (state, action) => {
      // check if we have validation error or any other type of errors
      let errors;
      if (action.payload.errors === undefined) {
        errors = [];
        errors.push(action.payload);
      } else {
        errors = action.payload.errors;
      }
      
      state.cliChangeProfile.errors = errors;
      state.editCliChangeProcess = false;
    },
    
    deleteCliChangeSuccess: (state, action) => {
      const { cliId, cliChangeId } = action.payload;
      
      state.cliChangeList[cliId].cliChangeCampaigns = _.filter(
        state.cliChangeList[cliId].cliChangeCampaigns,
        cliChange => cliChange.id !== cliChangeId);
    },
    
    fetchCliChangeInvoked: (state, action) => {
      state.cliChangeProfile = {};
      state.cliChangeProfileLoaded = false;
    },
    
    fetchCliChangeSuccess: (state, action) => {
      state.cliChangeProfile = action.payload;
      state.cliChangeProfileLoaded = true;
    },
    
    deleteCliNumberInvoked: (state, action) => {
      state.deleteCliNumberProcess = true;
    },
    
    deleteCliNumberSuccess: (state, action) => {
      state.deleteCliNumberProcess = false;
      // const cliNumberId = action.payload;
      // state.cliChangeProfile.cliCampaignNumbers = _.filter(state.cliChangeProfile.cliCampaignNumbers, cliNumber => cliNumber.id !== cliNumberId);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase("user/logoutSuccess", (state, action) => {
        return initialState;
      });
  }
});

export const {
  clearCreatedCli,
  createCliInvoked,
  createCliSuccess,
  createCliFailure,
  deleteCliSuccess,
  fetchClis,
  fetchCliInvoked,
  fetchCliSuccess,
  fetchCliFailure,
  
  clearCreateCliChange,
  createCliChangeInvoked,
  createCliChangeSuccess,
  createCliChangeFailure,
  editCliChangeInvoked,
  editCliChangeSuccess,
  editCliChangeFailure,
  deleteCliChangeSuccess,
  fetchCliChangeInvoked,
  fetchCliChangeSuccess,
  
  deleteCliNumberInvoked,
  deleteCliNumberSuccess
} = cliSlice.actions;

/*****************************************************************************************
 * CLI Actions
 *****************************************************************************************/
export const getListOfClis = () => async (dispatch, getState) => {
  try {
    const { data } = await fetchListOfClis();
    dispatch(fetchClis(data));
  } catch (error) {
    console.log(error);
  }
};

export const getCliById = (cliId) => async (dispatch, getState) => {
  dispatch(fetchCliInvoked(cliId));
  try {
    const { data } = await fetchCliById(cliId);
    dispatch(fetchCliSuccess(data));
  } catch (error) {
    console.log(error);
    dispatch(fetchCliFailure(cliId));
  }
};

export const clearStateCreatedCli = () => async (dispatch, getState) => {
  dispatch(clearCreatedCli()); // make sure to reset any previous data fetched / errors
};

export const createCli = (cliData) => async (dispatch, getState) => {
  dispatch(createCliInvoked());
  
  try {
    const { data } = await addCli(cliData);
    dispatch(createCliSuccess(data));
  
    toast.success(`Successfully Added CLI Campaign: ${data.campaignName}`);
    
    // redirect user back to CLI Listing
    history.push('/admin/cli');
  } catch (error) {
    const { data } = error.response;
    
    dispatch(createCliFailure(data));
  }
};

export const cloneCli = (cliId, cliData) => async (dispatch, getState) => {
  dispatch(createCliInvoked());
  
  try {
    const { data } = await cloneCliById(cliId, cliData);
    dispatch(createCliSuccess(data));
  
    toast.success(`Successfully Cloned CLI Campaign!`);
    
    // redirect user back to CLI Listing
    history.push('/admin/cli');
  } catch (error) {
    const { data } = error.response;
    
    dispatch(createCliFailure(data));
  }
};

export const deleteCli = (cliId) => async (dispatch, getState) => {
  try {
    await deleteCliById(cliId);
    dispatch(deleteCliSuccess(cliId));
  
    toast.success(`Successfully Deleted CLI Campaign!`);
  } catch (error) {
    console.log(error);
  }
};

/*****************************************************************************************
 * CLI Change Actions
 *****************************************************************************************/
export const clearStateCliChangeById = (cliChangeId) => async (dispatch, getState) => {
  dispatch(fetchCliChangeInvoked(cliChangeId)); // make sure to reset any previous data fetched
};

export const getCliChangeById = (cliChangeId) => async (dispatch, getState) => {
  dispatch(fetchCliChangeInvoked(cliChangeId)); // make sure to reset any previous data fetched
  try {
    const { data } = await fetchCliChangeById(cliChangeId);
    dispatch(fetchCliChangeSuccess(data));
  } catch (error) {
    console.log(error);
  }
};

export const clearStateCreateCliChange = () => async (dispatch, getState) => {
  dispatch(clearCreateCliChange()); // make sure to reset any previous data fetched / errors
};

export const createCliChange = (cliId, cliChangeData) => async (dispatch, getState) => {
  dispatch(createCliChangeInvoked());
  
  try {
    const { data } = await addCliChange(cliId, cliChangeData);
    dispatch(createCliChangeSuccess({ cliId, data }));
  
    toast.success(`Successfully Added CLI Change: ${data.destinationName}`);
    
    // redirect user back to CLI Listing
    history.push(`/admin/cli/edit/${cliId}`);
  } catch (error) {
    const { data } = error.response;
    
    dispatch(createCliChangeFailure(data));
  }
};

export const editCliChange = (cliChangeId, cliChangeData) => async (dispatch, getState) => {
  dispatch(editCliChangeInvoked());
  
  try {
    const { data } = await editCliChangeById(cliChangeId, cliChangeData);
    dispatch(editCliChangeSuccess(data));
  
    toast.success(`Successfully Updated CLI Change: ${data.destinationName}`);
  } catch (error) {
    const { data } = error.response;
    
    dispatch(editCliChangeFailure(data));
  }
};

export const deleteCliChange = (cliId, cliChangeId) => async (dispatch, getState) => {
  try {
    await deleteCliChangeById(cliChangeId);
    dispatch(deleteCliChangeSuccess({ cliId, cliChangeId }));
  
    toast.success(`Successfully Deleted CLI Change!`);
  } catch (error) {
    console.log(error);
  }
};

export const deleteCliNumber = (cliNumberId) => async (dispatch, getState) => {
  dispatch(deleteCliNumberInvoked());
  try {
    await deleteCliNumberById(cliNumberId);
    dispatch(deleteCliNumberSuccess(cliNumberId));
  
    toast.success(`Successfully Deleted CLI Number!`);
  } catch (error) {
    console.log(error);
  }
};

export default cliSlice.reducer;

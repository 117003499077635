import React, { Component }                from 'react';

import "./AddCli.scss";
import connect                             from "react-redux/es/connect/connect";
import { clearStateCreatedCli, createCli } from "../../redux/cliSlice";
import PropTypes                           from "prop-types";
import _                                   from "lodash";

class AddCli extends Component {
  static propTypes = {
    createdCli: PropTypes.object,
    createCliProcess: PropTypes.bool,
    
    createCli: PropTypes.func.isRequired,
    clearStateCreatedCli: PropTypes.func.isRequired,
  };
  
  constructor(props) {
    super(props);
    
    this.state = {
      campaignId: "",
      campaignName: "",
    };
    
    this.handleChange = this.handleChange.bind(this);
  }
  
  componentWillUnmount() {
    const { clearStateCreatedCli } = this.props;
    clearStateCreatedCli();
  }
  
  handleChange = (event) => {
    const { target } = event;
    let { id } = target;
    let value = target.value;
    
    if (target.type === "checkbox") {
      value = target.checked;
    } else {
      if (target.type === "radio") {
        id = target.name;
        value = target.value;
      } else {
        value = target.value;
      }
    }
    
    this.setState({
      [id]: value,
    });
  };
  
  submitForm(event) {
    const { createCli } = this.props;
    
    event.preventDefault();
    
    createCli(this.state);
  }
  
  render() {
    const { campaignId, campaignName } = this.state;
    const { createdCli, createCliProcess } = this.props;
    
    return (
      <div className="add-cli section">
        
        <div className="section-title">
          <h2>Add new CLI Campaign</h2>
        </div>
        
        
        <div className="row">
          <div className="col-12">
            <form onSubmit={(event) => this.submitForm(event)}>
              
              <div className="row mt-3">
                <div className="col-6">
                  <label htmlFor="campaignId" className="form-label">Campaign Id</label>
                  <input type="number" min={10000} max={99999} className="form-control" name="campaignId"
                         id="campaignId" placeholder="campaign id" value={campaignId}
                         onChange={(event) => this.handleChange(event)} required/>
                  <div className="form-text">Please provide a unique number with exactly 5 digits</div>
                </div>
                
                <div className="col-6">
                  <label htmlFor="campaignName" className="form-label">Campaign Name</label>
                  <input type="text" className="form-control" name="campaignName" id="campaignName"
                         placeholder="campaign name"
                         value={campaignName}
                         onChange={(event) => this.handleChange(event)} required/>
                  <div className="form-text">Please provide a unique name</div>
                </div>
              </div>
              
              
              <div className="row mt-5">
                <div className="col-12">
                  {
                    (createCliProcess === false)
                      ? <button type="submit" className="btn btn-primary"><i className="fas fa-save"></i> Create CLI
                        Campaign
                      </button>
                      : <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Create
                        CLI Campaign
                      </button>
                  }
                </div>
              </div>
            
            </form>
            
            {(!_.isEmpty(createdCli.errors))
              ? <div className="alert alert-danger login-error" role="alert">
                {
                  createdCli.errors.map(error => <div key={error.fieldName + error.message}>
                    {
                      (error.fieldName !== undefined)
                        ? error.fieldName + " - "
                        : null
                    }
                    {error.message}
                  </div>)
                }
              </div>
              : null
            }
          </div>
        </div>
      
      </div>
    );
  };
}

export default connect(
  (state) => {
    return {
      createdCli: state.cli.createdCli,
      createCliProcess: state.cli.createCliProcess
    };
  },
  {
    createCli: (cliData) => createCli(cliData),
    clearStateCreatedCli: () => clearStateCreatedCli()
  }
)(AddCli);

import { createSlice }                                                       from "@reduxjs/toolkit";
import _                                                                     from "lodash";
import { addFas, deleteFasById, editFasById, fetchFasById, fetchListOfFass } from "../api/Api";
import history                                                               from "../history";
import { toast }                                                             from "react-toastify";

const initialState = {
  list: [],             // list of FASs
  listLoaded: false,
  
  createdFas: {},       // new FAS entity/data
  createFasProcess: false,
  
  editedFas: {}, // edit FAS entity/data
  fasProfileLoaded: true,
  
  editFasProcess: false
};

export const fasSlice = createSlice({
  name: 'fas',
  initialState,
  reducers: {
    clearCreatedFas: (state, action) => {
      state.createdFas = {};
    },
    
    createFasInvoked: (state, action) => {
      state.createFasProcess = true;
    },
    
    createFasSuccess: (state, action) => {
      state.createdFas = action.payload;
      state.list = [action.payload, ...state.list];
      state.createFasProcess = false;
    },
    
    createFasFailure: (state, action) => {
      // check if we have validation error or any other type of errors
      let errors;
      if (action.payload.errors === undefined) {
        errors = [];
        errors.push(action.payload);
      } else {
        errors = action.payload.errors;
      }
      
      state.createdFas.errors = errors;
      state.createFasProcess = false;
    },
    
    deleteFasSuccess: (state, action) => {
      const fasId = action.payload;
      state.list = _.filter(state.list, fas => fas.id !== fasId);
    },
    
    fetchfass: (state, action) => {
      state.list = action.payload;
      state.listLoaded = true;
    },
    
    fetchFasInvoked: (state, action) => {
      state.fasProfileLoaded = false;
    },
    
    fetchFasFailure: (state, action) => {
      state.fasProfileLoaded = false;
    },
    
    fetchFasSuccess: (state, action) => {
      if (_.isEmpty(state.list)) {
        state.list = [];
        state.list.push(action.payload);
      } else {
        // add fas if it's not already in store
        const fas = _.find(state.list, fas => fas.id === action.payload.id);
        
        if (fas === undefined) {
          state.list.push(action.payload);
        }
      }
      
      state.fasProfileLoaded = true;
    },
    
    clearEditFas: (state, action) => {
      state.editedFas = {};
    },
    
    editFasInvoked: (state, action) => {
      state.editFasProcess = true;
    },
    
    editFasSuccess: (state, action) => {
      state.editedFas = action.payload;
      
      // update state
      const fasId = _.findIndex(state.list, fas => fas.id === action.payload.id);
      if (fasId !== -1) {
        // maybe it's better to just update the entire object than each field (like we did in edit Dialer Destination)
        state.list[fasId] = action.payload;
      }
      
      state.editFasProcess = false;
    },
    
    editFasFailure: (state, action) => {
      // check if we have validation error or any other type of errors
      let errors;
      if (action.payload.errors === undefined) {
        errors = [];
        errors.push(action.payload);
      } else {
        errors = action.payload.errors;
      }
      
      state.editedFas.errors = errors;
      state.editFasProcess = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase("user/logoutSuccess", (state, action) => {
        return initialState;
      });
  }
});

export const {
  clearCreatedFas,
  createFasInvoked,
  createFasSuccess,
  createFasFailure,
  
  editFasInvoked,
  editFasSuccess,
  editFasFailure,
  
  deleteFasSuccess,
  
  fetchfass,
  fetchFasInvoked,
  fetchFasSuccess,
  fetchFasFailure,
  
  clearEditFas,
} = fasSlice.actions;

export const getListOfFass = () => async (dispatch, getState) => {
  try {
    const { data } = await fetchListOfFass();
    dispatch(fetchfass(data));
  } catch (error) {
    console.log(error);
  }
};

export const getFasById = (fasId) => async (dispatch, getState) => {
  dispatch(fetchFasInvoked());
  try {
    const { data } = await fetchFasById(fasId);
    dispatch(fetchFasSuccess(data));
  } catch (error) {
    console.log(error);
    dispatch(fetchFasFailure());
  }
};

export const clearStateCreatedFas = () => async (dispatch, getState) => {
  dispatch(clearCreatedFas()); // make sure to reset any previous data fetched / errors
};

export const createFas = (fasData) => async (dispatch, getState) => {
  dispatch(createFasInvoked());
  
  try {
    const { data } = await addFas(fasData);
    dispatch(createFasSuccess(data));
    
    // redirect user back to Fas Listing
    history.push('/admin/fas');
  } catch (error) {
    const { data } = error.response;
    
    dispatch(createFasFailure(data));
  }
};

export const deleteFas = (fasId) => async (dispatch, getState) => {
  try {
    await deleteFasById(fasId);
    dispatch(deleteFasSuccess(fasId));
  } catch (error) {
    console.log(error);
  }
};

export const clearStateEditFas = () => async (dispatch, getState) => {
  dispatch(clearEditFas()); // make sure to reset any previous data fetched / errors
};

export const editFas = (fasId, fasData) => async (dispatch, getState) => {
  dispatch(editFasInvoked());
  
  try {
    const { data } = await editFasById(fasId, fasData);
    dispatch(editFasSuccess(data));
    
    toast.success(`Successfully Updated Fas: ${data.fasName}`);
    
    // redirect user back to Fas Listing
    history.push('/admin/fas');
  } catch (error) {
    const { data } = error.response;
    
    dispatch(editFasFailure(data));
  }
};

export default fasSlice.reducer;

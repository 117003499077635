import React, { Component } from 'react';

import "./NotFound404.scss";

export default class NotFound404 extends Component {
  render() {
    return (
      <div className="distribution-profile section">
        
        <div className="section-title">
          <h2><i className="fa-solid fa-face-tired"></i> Ups! Nothing found...</h2>
        </div>
      
      </div>
    );
  };
}

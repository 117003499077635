import { createSlice } from "@reduxjs/toolkit";
import _               from "lodash";
import {
  addDistribution,
  deleteDistributionById,
  editDistributionById,
  fetchDistributionById,
  fetchListOfDistributions
}                      from "../api/Api";
import history         from "../history";
import { toast }       from "react-toastify";

const initialState = {
  list: [],             // list of Distributions
  listLoaded: false,
  
  createdDistribution: {},       // new Distribution entity/data
  createDistributionProcess: false,
  
  editedDistribution: {}, // edit distribution entity/data
  distributionProfileLoaded: true,
  
  editDistributionProcess: false
};

export const distributionSlice = createSlice({
  name: 'distribution',
  initialState,
  reducers: {
    clearCreatedDistribution: (state, action) => {
      state.createdDistribution = {};
    },
    
    createDistributionInvoked: (state, action) => {
      state.createDistributionProcess = true;
    },
    
    createDistributionSuccess: (state, action) => {
      state.createdDistribution = action.payload;
      state.list = [action.payload, ...state.list];
      state.createDistributionProcess = false;
    },
    
    createDistributionFailure: (state, action) => {
      // check if we have validation error or any other type of errors
      let errors;
      if (action.payload.errors === undefined) {
        errors = [];
        errors.push(action.payload);
      } else {
        errors = action.payload.errors;
      }
      
      state.createdDistribution.errors = errors;
      state.createDistributionProcess = false;
    },
    
    deleteDistributionSuccess: (state, action) => {
      const distributionId = action.payload;
      state.list = _.filter(state.list, distribution => distribution.id !== distributionId);
    },
    
    fetchDistributions: (state, action) => {
      state.list = action.payload;
      state.listLoaded = true;
    },
    
    fetchDistributionInvoked: (state, action) => {
      state.distributionProfileLoaded = false;
    },
    
    fetchDistributionFailure: (state, action) => {
      state.distributionProfileLoaded = false;
    },
    
    fetchDistributionSuccess: (state, action) => {
      if (_.isEmpty(state.list)) {
        state.list = [];
        state.list.push(action.payload);
      } else {
        // add distribution if it's not already in store
        const distribution = _.find(state.list, distribution => distribution.id === action.payload.id);
        
        if (distribution === undefined) {
          state.list.push(action.payload);
        }
      }
      
      state.distributionProfileLoaded = true;
    },
    
    clearEditDistribution: (state, action) => {
      state.editedDistribution = {};
    },
    
    editDistributionInvoked: (state, action) => {
      state.editDistributionProcess = true;
    },
    
    editDistributionSuccess: (state, action) => {
      state.editedDistribution = action.payload;
      
      // update state
      const distributionId = _.findIndex(state.list, distribution => distribution.id === action.payload.id);
      if (distributionId !== -1) {
        // maybe it's better to just update the entire object than each field (like we did in edit Dialer Destination)
        state.list[distributionId] = action.payload;
      }
      
      state.editDistributionProcess = false;
    },
    
    editDistributionFailure: (state, action) => {
      // check if we have validation error or any other type of errors
      let errors;
      if (action.payload.errors === undefined) {
        errors = [];
        errors.push(action.payload);
      } else {
        errors = action.payload.errors;
      }
      
      state.editedDistribution.errors = errors;
      state.editDistributionProcess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase("user/logoutSuccess", (state, action) => {
        return initialState;
      });
  }
});

export const {
  clearCreatedDistribution,
  createDistributionInvoked,
  createDistributionSuccess,
  createDistributionFailure,
  
  editDistributionInvoked,
  editDistributionSuccess,
  editDistributionFailure,
  
  deleteDistributionSuccess,
  
  fetchDistributions,
  fetchDistributionInvoked,
  fetchDistributionSuccess,
  fetchDistributionFailure,
  
  clearEditDistribution,
} = distributionSlice.actions;

export const getListOfDistributions = () => async (dispatch, getState) => {
  try {
    const { data } = await fetchListOfDistributions();
    dispatch(fetchDistributions(data));
  } catch (error) {
    console.log(error);
  }
};

export const getDistributionById = (distributionId) => async (dispatch, getState) => {
  dispatch(fetchDistributionInvoked());
  try {
    const { data } = await fetchDistributionById(distributionId);
    dispatch(fetchDistributionSuccess(data));
  } catch (error) {
    console.log(error);
    dispatch(fetchDistributionFailure());
  }
};

export const clearStateCreatedDistribution = () => async (dispatch, getState) => {
  dispatch(clearCreatedDistribution()); // make sure to reset any previous data fetched / errors
};

export const createDistribution = (distributionData) => async (dispatch, getState) => {
  dispatch(createDistributionInvoked());
  
  try {
    const { data } = await addDistribution(distributionData);
    dispatch(createDistributionSuccess(data));
    
    // redirect user back to Distribution Listing
    history.push('/admin/distribution');
  } catch (error) {
    const { data } = error.response;
    
    dispatch(createDistributionFailure(data));
  }
};

export const deleteDistribution = (distributionId) => async (dispatch, getState) => {
  try {
    await deleteDistributionById(distributionId);
    dispatch(deleteDistributionSuccess(distributionId));
  } catch (error) {
    console.log(error);
  }
};

export const clearStateEditDistribution = () => async (dispatch, getState) => {
  dispatch(clearEditDistribution()); // make sure to reset any previous data fetched / errors
};

export const editDistribution = (distributionId, distributionData) => async (dispatch, getState) => {
  dispatch(editDistributionInvoked());
  
  try {
    const { data } = await editDistributionById(distributionId, distributionData);
    dispatch(editDistributionSuccess(data));
    
    toast.success(`Successfully Updated Distribution: ${data.distributionName}`);
    
    // redirect user back to Distribution Listing
    history.push('/admin/distribution');
  } catch (error) {
    const { data } = error.response;
    
    dispatch(editDistributionFailure(data));
  }
};

export default distributionSlice.reducer;

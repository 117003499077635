import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {}
};

export const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    update: (state, action) => {
      state.value = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase("user/logoutSuccess", (state, action) => {
        return initialState;
      });
  }
});

export const { update } = locationSlice.actions;

export const updateLocation = ({ dispatch }) => {
  return (nextLocation) => {
    dispatch(update(nextLocation));
  };
};


export default locationSlice.reducer;

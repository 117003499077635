import React, { Component } from 'react';
import PropTypes            from "prop-types";
import "./Loading.scss";

/**
 * Loading Component.
 */
export default class Loading extends Component {
  static propTypes = {
    display: PropTypes.bool.isRequired,
  };
  
  render() {
    const { display } = this.props;
    
    return (
      <div>
        {(display === true)
          ? <div className="d-flex justify-content-center loading">
            <div className="spinner-border text-primary m-3" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
          : null
        }
      </div>
    );
  };
};

import React, { Component } from 'react';

import "./AuditList.scss";
import connect              from "react-redux/es/connect/connect";
import { API_FETCH_AUDITS } from "../../api/Api";
import tokenService         from "../../security/TokenService";

const $ = require('jquery');
$.DataTable = require('datatables.net-bs5');

class AuditList extends Component {
  static propTypes = {};
  
  constructor(props) {
    super(props);
    
    this.tableAPI = undefined;
  }
  
  componentDidMount() {
    this.initTable();
  }
  
  componentWillUnmount() {
    this.tableAPI.destroy();
  }
  
  initTable() {
    this.$el = $(this.el);
    this.tableAPI = this.$el.DataTable({
      pageLength: 10,
      order: [],
      language: {
        // processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> '
      },
      processing: true,
      serverSide: true,
      ajax: {
        url: API_FETCH_AUDITS,
        headers: {
          Authorization: `Bearer ${tokenService.getUser()?.token}`,
          sessionId: tokenService.getUser()?.sessionId
        },
        data: function (formData) {
          return {
            draw: formData.draw,
            start: formData.start,
            length: formData.length,
            search: formData.search.value
          };
        },
        error: function (xhr, error, code) {
          // do nothing;
        }
      },
      columns: [
        { title: "User", data: "username", orderable: false },
        {
          data: "date",
          title: 'Date',
          wrap: true,
          orderable: false,
          render: function (item) {
            const date = new Date(item);
            return date.toLocaleDateString();
          },
        },
        { title: "Action", data: "action", orderable: false },
        { title: "Info", data: "info", orderable: false }
      ]
    });
  }
  
  render() {
    return (
      <div className="audit-list section">
        
        <div className="section-title">
          <h2>Audit</h2>
        </div>
        
        <div className="row">
          <div className="col-12">
            <table className="table table-hover" ref={el => this.el = el}>
            </table>
          </div>
        </div>
      
      </div>
    );
  };
}

export default connect(
  (state) => {
    return {};
  },
  {}
)(AuditList);

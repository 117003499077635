import "./AddUser.scss";

import React, { Component }                 from "react";
import PropTypes                            from "prop-types";
import Select                               from "react-select";
import connect                              from "react-redux/es/connect/connect";
import { allRoles }                         from "../../security/RolesConstants";
import _                                    from "lodash";
import { clearStateCreateUser, createUser } from "../../redux/userSlice";
import { toParams }                         from "../../util/SelectorUtils";

class AddUser extends Component {
  static propTypes = {
    createdUser: PropTypes.object,
    createUserProcess: PropTypes.bool,
    
    createUser: PropTypes.func.isRequired,
    clearStateCreateUser: PropTypes.func.isRequired,
  };
  
  constructor(props) {
    super(props);
    
    this.state = {
      username: "",
      firstName: "",
      lastName: "",
      roles: [],
      enabled: true,
      password: "",
      confirmPassword: ""
    };
    
    this.handleChange = this.handleChange.bind(this);
    this.handleRolesChange = this.handleRolesChange.bind(this);
  }
  
  componentWillUnmount() {
    const { clearStateCreateUser } = this.props;
    clearStateCreateUser();
  }
  
  handleChange = (event) => {
    const { target } = event;
    let { id } = target;
    let value = target.value;
    
    if (target.type === "checkbox") {
      value = target.checked;
    } else {
      if (target.type === "radio") {
        id = target.name;
        value = target.value;
      } else {
        value = target.value;
      }
    }
    
    this.setState({
      [id]: value,
    });
  };
  
  handleRolesChange = (roles) => {
    this.setState({
      roles
    });
  };
  
  submitForm(event) {
    const { createUser } = this.props;
    
    event.preventDefault();
    
    const userData = {
      username: this.state.username,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      roles: toParams(this.state.roles),
      enabled: this.state.enabled,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
    };
    
    createUser(userData);
  }
  
  render() {
    const { username, firstName, lastName, roles, enabled, password, confirmPassword } = this.state;
    const { createdUser, createUserProcess } = this.props;
    
    return (
      <div className="add-user section">
        <div className="section-title">
          <h2>Add New User</h2>
        </div>
        
        <div className="row">
          <div className="col-12">
            
            <form onSubmit={(event) => this.submitForm(event)} autoComplete="off">
              
              <div className="row mt-3">
                <div className="col-4">
                  <label htmlFor="username" className="form-label">Username</label>
                  <input type="text" className="form-control" name="username" id="username" placeholder="username"
                         autoComplete="off" value={username}
                         onChange={(event) => this.handleChange(event)} required/>
                </div>
                
                <div className="col-4">
                  <label htmlFor="firstName" className="form-label">First Name</label>
                  <input type="text" className="form-control" name="firstName" id="firstName"
                         placeholder="first name"
                         value={firstName}
                         onChange={(event) => this.handleChange(event)} required/>
                </div>
                
                <div className="col-4">
                  <label htmlFor="lastName" className="form-label">Last Name</label>
                  <input type="text" className="form-control" name="lastName" id="lastName" placeholder="last name"
                         value={lastName}
                         onChange={(event) => this.handleChange(event)} required/>
                </div>
              </div>
              
              <div className="row mt-4">
                <div className="col-8">
                  <label htmlFor="roles" className="form-label">Roles</label>
                  <Select isMulti
                          id="roles"
                          name="roles"
                          options={allRoles}
                          value={roles}
                          classNamePrefix="select"
                          onChange={(event) => this.handleRolesChange(event)}/>
                </div>
                
                <div className="col-4 d-flex justify-content-center">
                  <div>
                    <label htmlFor="enabled" className="form-label">Enabled</label>
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" id="enabled"
                             checked={enabled ? enabled : false}
                             onChange={(event) => this.handleChange(event)}/>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="row mt-4">
                <div className="col-6">
                  <label htmlFor="password" className="form-label">Password</label>
                  <input type="password" className="form-control" name="password" id="password" placeholder="********"
                         autoComplete="new-password" value={password}
                         onChange={(event) => this.handleChange(event)} required/>
                </div>
                
                <div className="col-6">
                  <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                  <input type="password" className="form-control" name="confirmPassword" id="confirmPassword"
                         placeholder="********"
                         autoComplete="off" value={confirmPassword}
                         onChange={(event) => this.handleChange(event)} required/>
                </div>
              </div>
              
              <div className="row mt-5">
                <div className="col-12">
                  {
                    (createUserProcess === false)
                      ? <button type="submit" className="btn btn-primary"><i className="fas fa-save"></i> Create User
                      </button>
                      : <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Create
                        User
                      </button>
                  }
                </div>
              </div>
            </form>
            
            {(!_.isEmpty(createdUser.errors))
              ? <div className="alert alert-danger login-error" role="alert">
                {
                  createdUser.errors.map(error => <div key={error.fieldName + error.message}>
                    {
                      (error.fieldName !== undefined)
                        ? error.fieldName + " - "
                        : null
                    }
                    {error.message}
                  </div>)
                }
              </div>
              : null
            }
          
          </div>
        </div>
      </div>
    );
  };
}

export default connect(
  (state, props) => {
    return {
      createdUser: state.user.createdUser,
      createUserProcess: state.user.createUserProcess
    };
  },
  {
    createUser: (userData) => createUser(userData),
    clearStateCreateUser: () => clearStateCreateUser()
  }
)(AddUser);

import { createSlice } from "@reduxjs/toolkit";
import {
  addUser,
  deleteUserById,
  editUserById,
  fetchListOfUsers,
  fetchUserById,
  loginUser,
  logoutUser,
  updateCurrentUserPassword,
  updateUserPasswordById
}                      from "../api/Api";
import history         from '../history';
import tokenService    from "../security/TokenService";
import _               from "lodash";
import { toast }       from "react-toastify";
import { getHomePage } from "../security/Authorization";
import { ADMIN }       from "../security/RolesConstants";

const initialState = {
  login: {},
  list: [],           // list of users
  listLoaded: false,
  
  createdUser: {},    // new user entity/data
  createUserProcess: false,
  
  editedUser: {},       // edit user entity/data
  editUserProcess: false,
  
  passwordUser: {},       // edit user password
  passwordUserProcess: false,
};


export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      // also store the login data to local storage
      tokenService.setUser(action.payload);
      
      state.login = action.payload;
    },
    
    loginInvoked: (state, action) => {
    
    },
    
    loginFailure: (state, action) => {
      const { message } = action.payload;
      
      tokenService.removeUser();
      
      if (message !== undefined) {
        state.fail = message;
      }
    },
    
    logoutSuccess: (state, action) => {
      tokenService.removeUser();
      
      return initialState;
    },
    
    loadLocalStorage: (state, action) => {
      state.login = action.payload;
    },
    
    clearCreateUser: (state, action) => {
      state.createdUser = {};
    },
    
    createUserInvoked: (state, action) => {
      state.createUserProcess = true;
    },
    
    createUserSuccess: (state, action) => {
      state.createdUser = action.payload;
      state.list = [action.payload, ...state.list];
      state.createUserProcess = false;
    },
    
    createUserFailure: (state, action) => {
      // check if we have validation error or any other type of errors
      let errors;
      if (action.payload.errors === undefined) {
        errors = [];
        errors.push(action.payload);
      } else {
        errors = action.payload.errors;
      }
      
      state.createdUser.errors = errors;
      state.createUserProcess = false;
    },
    
    clearPasswordUser: (state, action) => {
      state.passwordUser = {};
    },
    
    updatePasswordUserInvoked: (state, action) => {
      state.passwordUserProcess = true;
    },
    
    updatePasswordUserSuccess: (state, action) => {
      state.passwordUser = action.payload;
      state.passwordUserProcess = false;
    },
    
    updatePasswordUserFailure: (state, action) => {
      // check if we have validation error or any other type of errors
      let errors;
      if (action.payload.errors === undefined) {
        errors = [];
        errors.push(action.payload);
      } else {
        errors = action.payload.errors;
      }
      
      state.passwordUser.errors = errors;
      state.passwordUserProcess = false;
    },
    
    clearEditUser: (state, action) => {
      state.editedUser = {};
    },
    
    editUserInvoked: (state, action) => {
      state.editUserProcess = true;
    },
    
    editUserSuccess: (state, action) => {
      state.editedUser = action.payload;
      
      // update state
      const userId = _.findIndex(state.list, user => user.id === action.payload.id);
      if (userId !== -1) {
        state.list[userId] = action.payload;
      }
      
      state.editUserProcess = false;
    },
    
    editUserFailure: (state, action) => {
      // check if we have validation error or any other type of errors
      let errors;
      if (action.payload.errors === undefined) {
        errors = [];
        errors.push(action.payload);
      } else {
        errors = action.payload.errors;
      }
      
      state.editedUser.errors = errors;
      state.editUserProcess = false;
    },
    
    deleteUserSuccess: (state, action) => {
      const userId = action.payload;
      state.list = _.filter(state.list, user => user.id !== userId);
    },
    
    fetchUsers: (state, action) => {
      state.list = action.payload;
      state.listLoaded = true;
    },
    
    fetchUser: (state, action) => {
      if (_.isEmpty(state.list)) {
        state.list = [];
        state.list.push(action.payload);
      } else {
        // add user if it's not already in store
        const user = _.find(state.list, user => user.id === action.payload.id);
        
        if (user === undefined) {
          state.list.push(action.payload);
        }
      }
    }
  }
});

export const {
  loginSuccess,
  loginInvoked,
  loginFailure,
  logoutSuccess,
  
  loadLocalStorage,
  
  clearCreateUser,
  createUserInvoked,
  createUserSuccess,
  createUserFailure,
  deleteUserSuccess,
  
  clearEditUser,
  editUserInvoked,
  editUserSuccess,
  editUserFailure,
  
  clearPasswordUser,
  updatePasswordUserInvoked,
  updatePasswordUserSuccess,
  updatePasswordUserFailure,
  
  fetchUsers,
  fetchUser
} = userSlice.actions;

export const performLogin = (username, password) => async (dispatch, getState) => {
  dispatch(loginInvoked());
  
  try {
    const { data } = await loginUser({ username, password });
    dispatch(loginSuccess(data));
    
    // redirect user to home page if it's logged in
    history.push(getHomePage());
  } catch (error) {
    const { data } = error.response;
    
    dispatch(loginFailure(data));
  }
};

export const performLogout = () => async (dispatch, getState) => {
  try {
    await logoutUser();
    dispatch(logoutSuccess());
    
    history.push('/login');
  } catch (error) {
    console.log(error);
    
    // no matter what we perform the logout action
    dispatch(logoutSuccess());
  }
};

export const loadUserLocalStorage = (user) => (dispatch, getState) => {
  dispatch(loadLocalStorage(user));
};

export const getListOfUsers = () => async (dispatch, getState) => {
  try {
    const { data } = await fetchListOfUsers();
    dispatch(fetchUsers(data));
  } catch (error) {
    console.log(error);
  }
};

export const getUserById = (userId) => async (dispatch, getState) => {
  try {
    const { data } = await fetchUserById(userId);
    dispatch(fetchUser(data));
  } catch (error) {
    console.log(error);
  }
};

export const clearStateCreateUser = () => async (dispatch, getState) => {
  dispatch(clearCreateUser()); // make sure to reset any previous data fetched / errors
};

export const createUser = (userData) => async (dispatch, getState) => {
  dispatch(createUserInvoked());
  
  try {
    const { data } = await addUser(userData);
    dispatch(createUserSuccess(data));
    
    toast.success(`Successfully Added User: ${data.username}`);
    
    // redirect user back to User Listing
    history.push('/admin/users');
  } catch (error) {
    const { data } = error.response;
    
    dispatch(createUserFailure(data));
  }
};

export const clearStateEditUser = () => async (dispatch, getState) => {
  dispatch(clearEditUser()); // make sure to reset any previous data fetched / errors
};

export const editUser = (userId, userData) => async (dispatch, getState) => {
  dispatch(editUserInvoked());
  
  try {
    const { data } = await editUserById(userId, userData);
    dispatch(editUserSuccess(data));
    
    toast.success(`Successfully Updated User: ${data.username}`);
    
    // redirect user back to User Listing
    history.push('/admin/users');
  } catch (error) {
    const { data } = error.response;
    
    dispatch(editUserFailure(data));
  }
};

export const clearStatePasswordUser = () => async (dispatch, getState) => {
  dispatch(clearPasswordUser()); // make sure to reset any previous data fetched / errors
};

export const updatePassword = (userId, userData) => async (dispatch, getState) => {
  dispatch(updatePasswordUserInvoked());
  
  try {
    let data;
    if (userId === undefined) {
      data = await updateCurrentUserPassword(userData);
    } else {
      data = await updateUserPasswordById(userId, userData);
    }
    dispatch(updatePasswordUserSuccess(data));
    
    toast.success(`Successfully Updated Password`);
    
    // redirect user back to User Listing or My Profile depending on it's role
    if (tokenService.hasRoles([ADMIN])) {
      history.push('/admin/users');
    } else {
      history.push('/profile');
    }
  } catch (error) {
    const { data } = error.response;
    
    dispatch(updatePasswordUserFailure(data));
  }
};

export const deleteUser = (userId) => async (dispatch, getState) => {
  try {
    await deleteUserById(userId);
    dispatch(deleteUserSuccess(userId));
    
    toast.success(`Successfully Deleted User!`);
  } catch (error) {
    console.log(error);
  }
};

export default userSlice.reducer;

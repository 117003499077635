import React, { Component } from 'react';

import "./DialerProfile.scss";
import connect              from "react-redux/es/connect/connect";
import _                    from "lodash";
import PropTypes            from "prop-types";
import {
  deleteDialerDestination,
  getDialerById,
  resetDialerDestination,
  testDialerDestination
}                           from "../../redux/dialerSlice";
import history              from "../../history";
import Loading              from "../loading/Loading";
import ConfirmationModal    from "../modal/ConfirmationModal";
import {
  Link
}                           from "react-router-dom";
import {
  DialerWCompAuth,
  isDialerW
}                           from "../../security/Authorization";

const $ = require('jquery');
$.DataTable = require('datatables.net-bs5');

const AddButton = ({ dialer }) => {
  return <div className="row mt-5">
    <div className="col-12">
      <Link to={`/admin/dialer/${dialer.id}/dialer-destination/add/dialer-destination`}>
        <button type="button" className="btn btn-lg btn-outline-success">
          <i className="fas fa-plus-circle"></i> Add New Dialer Destination
        </button>
      </Link>
    </div>
  </div>;
};
const AddButtonAuth = DialerWCompAuth(AddButton);

class DialerProfile extends Component {
  static propTypes = {
    dialer: PropTypes.object,
    dialerDestinationLoaded: PropTypes.bool,
    
    getDialerById: PropTypes.func.isRequired,
    deleteDialerDestination: PropTypes.func.isRequired,
    testDialerDestination: PropTypes.func.isRequired,
    resetDialerDestination: PropTypes.func.isRequired,
  };
  
  constructor(props) {
    super(props);
    
    this.tableAPI = undefined;
    
    this.state = {
      deleteModalIsOpen: false,
      dialerDestinationId: undefined,
      dialerDestinationText: undefined,
      
      testModalIsOpen: false,
      testDialerDestinationId: undefined,
      testDialerDestinationText: undefined,
      
      resetModalIsOpen: false,
      resetDialerDestinationId: undefined,
      resetDialerDestinationText: undefined,
    };
    
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.toggleTestModal = this.toggleTestModal.bind(this);
    this.toggleResetModal = this.toggleResetModal.bind(this);
    
    this.deleteDialerDestination = this.deleteDialerDestination.bind(this);
    this.testDialerDestination = this.testDialerDestination.bind(this);
    this.resetDialerDestination = this.resetDialerDestination.bind(this);
    
    this.deleteDialerDestinationButton = this.deleteDialerDestinationButton.bind(this);
    this.testDialerDestinationButton = this.testDialerDestinationButton.bind(this);
    this.resetDialerDestinationButton = this.resetDialerDestinationButton.bind(this);
  }
  
  componentDidMount() {
    const { dialer, dialerDestinationLoaded, getDialerById } = this.props;
    
    window.reactHistory = history;
    
    window.deleteDialerDestinationButton = this.deleteDialerDestinationButton;
    window.testDialerDestinationButton = this.testDialerDestinationButton;
    window.resetDialerDestinationButton = this.resetDialerDestinationButton;
    
    if (this.props?.match?.params?.id !== undefined) {
      const dialerId = _.parseInt(this.props.match.params.id);
      if (dialerDestinationLoaded === false || dialer === undefined) {
        getDialerById(dialerId);
      }
    }
    
    this.initTable();
  }
  
  componentWillUnmount() {
    this.tableAPI.destroy();
  }
  
  componentDidUpdate(prevProps, prevState) {
    // update Dialer Destination list table only if we receive a new list
    if (!_.isEqual(prevProps.dialer, this.props.dialer)) {
      console.log(">>>>>!!!!!!!!!!!!!!!!!!!!!!!>>>>>>>>");
      this.updateTable();
    }
  }
  
  toggleDeleteModal() {
    this.setState({
      deleteModalIsOpen: !this.state.deleteModalIsOpen
    });
  }
  
  toggleTestModal() {
    this.setState({
      testModalIsOpen: !this.state.testModalIsOpen
    });
  }
  
  toggleResetModal() {
    this.setState({
      resetModalIsOpen: !this.state.resetModalIsOpen
    });
  }
  
  deleteDialerDestination(dialerDestinationId) {
    const { deleteDialerDestination } = this.props;
    
    // extract Dialer id
    let dialerId;
    if (this.props?.match?.params?.id !== undefined) {
      dialerId = _.parseInt(this.props.match.params.id);
    }
    if (dialerId === undefined) {
      console.error("Something is wrong! we don't have the Dialer id at this point!");
      return;
    }
    
    deleteDialerDestination(dialerId, dialerDestinationId);
    
    this.toggleDeleteModal();
  }
  
  testDialerDestination(dialerDestinationId) {
    const { testDialerDestination } = this.props;
    
    testDialerDestination(dialerDestinationId);
    
    this.toggleTestModal();
  }
  
  resetDialerDestination(dialerDestinationId) {
    const { resetDialerDestination } = this.props;
    
    resetDialerDestination(dialerDestinationId);
    
    this.toggleResetModal();
  }
  
  deleteDialerDestinationButton(dialerDestinationId, dialerDestinationName) {
    this.setState({
      deleteModalIsOpen: true,
      dialerDestinationId: dialerDestinationId,
      dialerDestinationText: dialerDestinationName
    });
  }
  
  testDialerDestinationButton(dialerDestinationId, dialerDestinationName) {
    this.setState({
      testModalIsOpen: true,
      testDialerDestinationId: dialerDestinationId,
      testDialerDestinationText: dialerDestinationName
    });
  }
  
  resetDialerDestinationButton(dialerDestinationId, dialerDestinationName) {
    this.setState({
      resetModalIsOpen: true,
      resetDialerDestinationId: dialerDestinationId,
      resetDialerDestinationText: dialerDestinationName
    });
  }
  
  updateTable() {
    const { dialer, dialerDestinationLoaded } = this.props;
    
    if (dialerDestinationLoaded === true) {
      this.tableAPI.clear();
      this.tableAPI.rows.add(dialer.dialerDestinationCampaigns);
      this.tableAPI.draw();
    }
  }
  
  initTable() {
    const { dialer } = this.props;
    
    this.$el = $(this.el);
    this.tableAPI = this.$el.DataTable({
      data: dialer !== undefined ? dialer.dialerDestinationCampaigns : [],
      pageLength: 10,
      order: [],
      scrollX: true,
      
      columns: [
        {
          "className": 'dt-control',
          "orderable": false,
          "data": null,
          "defaultContent": ''
        },
        
        { title: "Dest. Name", data: "dialerDestinationName" },
        { title: "Min. No", data: "generatedMinutesNo", render: item => item.toLocaleString() },
        { title: "Total Attempts", data: "totalAttempts", render: item => item.toLocaleString() },
        { title: "Dialer Ans.", data: "totalDialerAnswered", render: item => item.toLocaleString() },
        { title: "Dialer Rejects", data: "totalDialerRejects", render: item => item.toLocaleString() },
        { title: "Wrongly Ans.", data: "totalWronglyAnswered", render: item => item.toLocaleString() },
        { title: "Carrier Rejects", data: "totalCarrierRejects", render: item => item.toLocaleString() },
        { title: "Active Channels", data: "activeChannels", render: item => item.toLocaleString() },
        { title: "Enabled", data: "enabled" },
        
        {
          data: null,
          title: 'Actions',
          wrap: true,
          width: "100px",
          render: function (item) {
            const dialerDestinationName = item.dialerDestinationName.replace(/'/g, "\\'");
            
            if (isDialerW()) {
              return '<div class="btn-group"> ' +
                '<a aria-label="Edit Dialer Destination" data-cooltipz-dir="left" onclick="window.reactHistory.push(\'/admin/dialer/dialer-destination/edit/' + item.id + '\')" class="dialer-destination-actions">' +
                '<button type="button" class="btn btn-outline-info"><i class="fas fa-edit"></i></button>' +
                '</a>' +
                '<a aria-label="Test Dialer Destination" data-cooltipz-dir="left" onclick="window.testDialerDestinationButton(' + item.id + ', \'' + dialerDestinationName + '\')" class="dialer-destination-actions">' +
                '<button type="button" class="btn btn-outline-success"><i class="fas fa-check-double"></i></button>' +
                '</a>' +
                '<a aria-label="Reset Dialer Destination" data-cooltipz-dir="left" onclick="window.resetDialerDestinationButton(' + item.id + ', \'' + dialerDestinationName + '\')" class="dialer-destination-actions">' +
                '<button type="button" class="btn btn-outline-secondary"><i class="fas fa-retweet"></i></button>' +
                '</a>' +
                '<a aria-label="Delete Dialer Destination" data-cooltipz-dir="left" onclick="window.deleteDialerDestinationButton(' + item.id + ', \'' + dialerDestinationName + '\')" class="dialer-destination-actions">' +
                '<button type="button" class="btn btn-outline-danger"><i class="fas fa-trash"></i></button>' +
                '</a>' +
                '</div>';
            }
            return '<div class="btn-group"> ' +
              '<a aria-label="View Dialer Destination" data-cooltipz-dir="left" onclick="window.reactHistory.push(\'/admin/dialer/dialer-destination/edit/' + item.id + '\')" class="dialer-destination-actions">' +
              '<button type="button" class="btn btn-outline-info"><i class="fas fa-eye"></i></button>' +
              '</a>' +
              '</div>';
          },
          orderable: false
        }
      ]
    });
    
    // `d` is the original data object for the row
    this.format = (d) => {
      return '<table cellpadding="5" cellspacing="0">'
        
        + '<tr>'
        + '<td>Distribution:</td>'
        + '<td>' + d.distribution + '</td>'
        + '</tr>'
        
        + '<tr>'
        + '<td>Email Notifications:</td>'
        + '<td>' + d.emailNotifications + '</td>'
        + '</tr>'
        
        + '<tr>'
        + '<td>Weekdays Daily Minutes:</td>'
        + '<td>' + d.weekdaysDailyMinutes + '</td>'
        + '</tr>'
        
        + '<tr>'
        + '<td>Weekend Daily Minutes:</td>'
        + '<td>' + d.weekendDailyMinutes + '</td>'
        + '</tr>'
        
        + '<tr>'
        + '<td>ACD:</td>'
        + '<td>' + d.acd + '</td>'
        + '</tr>'
        
        + '<tr>'
        + '<td>ASR:</td>'
        + '<td>' + d.asr + '</td>'
        + '</tr>'
        
        + '<tr>'
        + '<td>Daily Variation:</td>'
        + '<td>' + d.dailyVariation + '</td>'
        + '</tr>'
        
        + '<tr>'
        + '<td>Daily Fails Stop:</td>'
        + '<td>' + d.dailyFailsStop + '</td>'
        + '</tr>'
        
        + '<tr>'
        + '<td>G729 Enabled:</td>'
        + '<td>' + d.g729Enabled + '</td>'
        + '</tr>'
        
        + '<tr>'
        + '<td>B-number only Validation:</td>'
        + '<td>' + d.bNoValidation + '</td>'
        + '</tr>'
        
        + '<tr>'
        + '<td>Created Date:</td>'
        + '<td>' + new Date(d.createdDate).toLocaleDateString() + '</td>'
        + '</tr>'
        
        + '<tr>'
        + '<td>Created By:</td>'
        + '<td>' + d.createdBy + '</td>'
        + '</tr>'
        
        + '</table>';
    };
    
    const self = this;
    // Add event listener for opening and closing details
    $('.dialer-profile tbody').on('click', 'td.dt-control', function () {
      var tr = $(this).closest('tr');
      var row = self.tableAPI.row(tr);
      
      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
        tr.removeClass('shown');
      } else {
        // Open this row
        row.child(self.format(row.data())).show();
        tr.addClass('shown');
      }
    });
  }
  
  render() {
    const { dialer, dialerDestinationLoaded } = this.props;
    
    return (
      <div className="dialer-profile section">
        
        <div className="section-title">
          <h2>Edit Dialer Campaign</h2>
        </div>
        
        <Loading display={!dialerDestinationLoaded}/>
        
        <ConfirmationModal
          isOpen={this.state.deleteModalIsOpen}
          title={"Dialer Destination Campaign: " + this.state.dialerDestinationText}
          content="<p>Are you sure you want to delete this Dialer Destination Campaign? <br/> <b>All associated data will be lost!</b></p>"
          actionText="Delete"
          toggle={this.toggleDeleteModal}
          doAction={() => this.deleteDialerDestination(this.state.dialerDestinationId)}/>
        
        <ConfirmationModal
          isOpen={this.state.testModalIsOpen}
          title={"Dialer Destination Campaign: " + this.state.testDialerDestinationText}
          content="<p>Are you sure you want to start testing this Dialer Destination Campaign?</p>"
          actionText="Test"
          color="success"
          toggle={this.toggleTestModal}
          doAction={() => this.testDialerDestination(this.state.testDialerDestinationId)}/>
        
        <ConfirmationModal
          isOpen={this.state.resetModalIsOpen}
          title={"Dialer Destination Campaign: " + this.state.resetDialerDestinationText}
          content="<p>Are you sure you want to reset statistics for this Dialer Destination Campaign?</p>"
          actionText="Reset"
          color="warning"
          toggle={this.toggleResetModal}
          doAction={() => this.resetDialerDestination(this.state.resetDialerDestinationId)}/>
        
        {
          (dialer !== undefined)
            ? <div className="card">
              <div className="card-body">
                <h5 className="card-title">Dialer Unique Id: {dialer.dialerId} -- Dialer Name: {dialer.dialerName}</h5>
                <p className="card-text">Created
                  By: {dialer.createdBy} on {new Date(dialer.createdDate).toLocaleDateString()} date</p>
                
                {
                  (!_.isNil(dialer.clonedFromId))
                    ? <h6><i className="fas fa-triangle-exclamation"></i> This Dialer Campaign is cloned from <Link
                      className="cloned-link" to={"/admin/dialer/edit/" + dialer.clonedFromId}
                      onClick={() => setTimeout(() => {
                        window.location.reload(); // just reload the page to force re-fetching the new data
                      }, 1)}>
                      this one with id {dialer.clonedFromId}</Link></h6>
                    : null
                }
              </div>
            </div>
            : null
        }
        
        <div className="row mt-4">
          <div className="col-12">
            <table className="table table-hover" ref={el => this.el = el}>
            </table>
          </div>
        </div>
        
        {
          (dialer !== undefined)
            ? <AddButtonAuth dialer={dialer}/>
            : null
        }
      
      </div>
    );
  };
}

export default connect(
  (state, props) => {
    let dialerId;
    if (props?.match?.params?.id !== undefined) {
      dialerId = _.parseInt(props.match.params.id);
    }
    
    return {
      dialer: state.dialer.dialerDestinationList[dialerId],
      dialerDestinationLoaded: state.dialer.dialerDestinationLoaded[dialerId] !== undefined ? state.dialer.dialerDestinationLoaded[dialerId] : false
    };
  },
  {
    getDialerById: (dialerId) => getDialerById(dialerId),
    deleteDialerDestination: (dialerId, dialerDestinationId) => deleteDialerDestination(dialerId, dialerDestinationId),
    testDialerDestination: (dialerDestinationId) => testDialerDestination(dialerDestinationId),
    resetDialerDestination: (dialerDestinationId) => resetDialerDestination(dialerDestinationId),
  }
)(DialerProfile);



import _ from "lodash";

export const ADMIN = "ADMIN";
export const CLI_READ = "CLI_READ";
export const CLI_WRITE = "CLI_WRITE";
export const DIALER_READ = "DIALER_READ";
export const DIALER_WRITE = "DIALER_WRITE";
export const DASHBOARD_READ = "DASHBOARD_READ";
export const FAS_READ = "FAS_READ";
export const FAS_WRITE = "FAS_WRITE";
export const RECORDING_READ = "RECORDING_READ";
export const RECORDING_WRITE = "RECORDING_WRITE";

export const allRoles = [
  { value: ADMIN, label: ADMIN },
  { value: CLI_READ, label: CLI_READ },
  { value: CLI_WRITE, label: CLI_WRITE },
  { value: DIALER_READ, label: DIALER_READ },
  { value: DIALER_WRITE, label: DIALER_WRITE },
  { value: DASHBOARD_READ, label: DASHBOARD_READ },
  { value: FAS_READ, label: FAS_READ },
  { value: FAS_WRITE, label: FAS_WRITE },
  { value: RECORDING_READ, label: RECORDING_READ },
  { value: RECORDING_WRITE, label: RECORDING_WRITE }
];

export const exportRoles = (rolesFromBE) => {
  return _.filter(allRoles, role => rolesFromBE.includes(role.value));
};

import React, { Component }         from 'react';

import "./FasList.scss";
import connect                      from "react-redux/es/connect/connect";
import { Link }                     from "react-router-dom";
import { FasWCompAuth, isFasW }     from "../../security/Authorization";
import PropTypes                    from "prop-types";
import history                      from "../../history";
import _                            from "lodash";
import Loading                      from "../loading/Loading";
import ConfirmationModal            from "../modal/ConfirmationModal";
import { deleteFas, getListOfFass } from "../../redux/fasSlice";

const $ = require('jquery');
$.DataTable = require('datatables.net-bs5');

const AddButton = () => {
  return <div className="row mt-5">
    <div className="col-12">
      <Link to="/admin/fas/add/fas">
        <button type="button" className="btn btn-lg btn-outline-success">
          <i className="fas fa-plus-circle"></i> Add FAS
        </button>
      </Link>
    </div>
  </div>;
};
const AddButtonAuth = FasWCompAuth(AddButton);

class FasList extends Component {
  static propTypes = {
    fasList: PropTypes.array,
    listLoaded: PropTypes.bool,
    
    getListOfFass: PropTypes.func.isRequired,
    deleteFas: PropTypes.func.isRequired,
  };
  
  constructor(props) {
    super(props);
    
    this.tableAPI = undefined;
    
    this.state = {
      deleteModalIsOpen: false,
      fasId: undefined,
      fasText: undefined
    };
    
    this.toggleModal = this.toggleModal.bind(this);
    this.deleteFas = this.deleteFas.bind(this);
    this.deleteFasButton = this.deleteFasButton.bind(this);
  }
  
  componentDidMount() {
    const { fasList, listLoaded, getListOfFass } = this.props;
    window.reactHistory = history;
    window.deleteFasButton = this.deleteFasButton;
    
    if (listLoaded === false || _.isEmpty(fasList)) {
      getListOfFass();
    }
    
    this.initTable();
  }
  
  componentWillUnmount() {
    this.tableAPI.destroy();
  }
  
  componentDidUpdate(prevProps, prevState) {
    // update FAS list table only if we receive a new list
    if (!_.isEqual(prevProps.fasList, this.props.fasList)) {
      this.updateTable();
    }
  }
  
  toggleModal() {
    this.setState({
      deleteModalIsOpen: !this.state.deleteModalIsOpen
    });
  }
  
  deleteFas(fasId) {
    const { deleteFas } = this.props;
    deleteFas(fasId);
    this.toggleModal();
  }
  
  deleteFasButton(fasId, fasName) {
    this.setState({
      deleteModalIsOpen: true,
      fasId: fasId,
      fasText: fasName
    });
  }
  
  updateTable() {
    const { fasList, listLoaded } = this.props;
    
    if (listLoaded === true) {
      this.tableAPI.clear();
      this.tableAPI.rows.add(fasList);
      this.tableAPI.draw();
    }
  }
  
  initTable() {
    const { fasList } = this.props;
    
    this.$el = $(this.el);
    this.tableAPI = this.$el.DataTable({
      data: fasList,
      pageLength: 10,
      order: [],
      columns: [
        { title: "FAS ID", data: "fasId" },
        { title: "FAS Name", data: "fasName" },
        { title: "FAS", data: "fasPercentage", render: item => item + "%" },
        { title: "Busy", data: "busyPercentage", render: item => item + "%" },
        { title: "Ring", data: "ringPercentage", render: item => item + "%" },
        {
          data: "createdDate",
          title: 'Created Date',
          wrap: true,
          orderable: false,
          render: function (item) {
            const date = new Date(item);
            return date.toLocaleDateString();
          },
        },
        { title: "Created By", data: "createdBy", orderable: false },
        {
          data: null,
          title: 'Actions',
          wrap: true,
          width: "210px",
          render: function (item) {
            if (isFasW()) {
              return '<div class="btn-group"> ' +
                '<a onclick="window.reactHistory.push(\'/admin/fas/edit/' + item.id + '\')" class="fas-actions">' +
                '<button type="button" class="btn btn-outline-info"><i class="fas fa-edit"></i> Edit</button>' +
                '</a>' +
                '<a onclick="window.deleteFasButton(' + item.id + ', \'' + item.fasName + '\')" class="fas-actions">' +
                '<button type="button" class="btn btn-outline-danger"><i class="fas fa-trash"></i> Delete</button>' +
                '</a>';
            }
            return '<div class="btn-group"> ' +
              '<a onclick="window.reactHistory.push(\'/admin/fas/edit/' + item.id + '\')" class="fas-actions">' +
              '<button type="button" class="btn btn-outline-info"><i class="fas fa-eye"></i> View</button>' +
              '</a>' +
              '</div>';
          },
          orderable: false
        }
      ]
    });
  }
  
  render() {
    const { listLoaded } = this.props;
    
    return (
      <div className="list-of-fas section">
        
        <div className="section-title">
          <h2>List of FAS</h2>
        </div>
        
        
        <Loading display={!listLoaded}/>
        
        <ConfirmationModal
          isOpen={this.state.deleteModalIsOpen}
          title={"FAS: " + this.state.fasText}
          content="<p>Are you sure you want to delete this FAS?</p>"
          actionText="Delete"
          toggle={this.toggleModal}
          doAction={() => this.deleteFas(this.state.fasId)}/>
        
        <div className="row">
          <div className="col-12">
            <table className="table table-hover" ref={el => this.el = el}>
            </table>
          </div>
        </div>
        
        <AddButtonAuth/>
      
      </div>
    );
  };
}

export default connect(
  (state) => {
    return {
      fasList: state.fas.list,
      listLoaded: state.fas.listLoaded
    };
  },
  {
    getListOfFass: () => getListOfFass(),
    deleteFas: (fasId) => deleteFas(fasId),
  }
)(FasList);

import React, { Component } from 'react';
import PropTypes            from "prop-types";

import connect                                            from "react-redux/es/connect/connect";
import DefaultHeader                                      from "./DefaultHeader";
import DefaultSidebar                                     from "./DefaultSidebar";
import history                                            from "../../history";

import "./DefaultLayout.scss";
import { getListOfMetadata }                              from "../../redux/metadataSlice";
import _                                                  from "lodash";
import tokenService                                       from "../../security/TokenService";
import { ADMIN, CLI_WRITE, DASHBOARD_READ, DIALER_WRITE } from "../../security/RolesConstants";


/**
 * The Default Layout component used to display the application.
 */
class DefaultLayout extends Component {
  static propTypes = {
    children: PropTypes.element,
    
    user: PropTypes.object,
    
    metadataList: PropTypes.object,
    metadataListLoaded: PropTypes.bool,
    
    getListOfMetadata: PropTypes.func.isRequired,
  };
  
  constructor(props) {
    super(props);
    
    // temporary fix to redirect un-logged users
    setTimeout(() => {
      const { user } = this.props;
      
      if (user?.username === undefined) {
        history.push('/login');
      }
    }, 50);
  }
  
  componentDidMount() {
    const { user, metadataList, metadataListLoaded, getListOfMetadata } = this.props;
    
    if (user?.username !== undefined
      && tokenService.hasRoles([ADMIN, CLI_WRITE, DIALER_WRITE, DASHBOARD_READ])
      && (metadataListLoaded === false || _.isEmpty(metadataList))) {
      getListOfMetadata();
    }
  }
  
  render() {
    return (
      <div className="default-layout">
        <DefaultHeader/>
        
        <div className="container-fluid">
          <div className="main row">
            <div className="sidebar col-3">
              <div className="row">
                <div className="position-fixed h-65 col-3">
                  <DefaultSidebar/>
                </div>
              </div>
            </div>
            
            <div className="content col-9">
              <div className="row">
                <div className="col-12">
                  
                  {this.props.children}
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default connect(
  (state) => {
    return {
      user: state.user.login,
      metadataList: state.metadata.list,
      metadataListLoaded: state.metadata.listLoaded
    };
  },
  {
    getListOfMetadata: () => getListOfMetadata(),
  }
)(DefaultLayout);

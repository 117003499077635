import React, { Component } from 'react';
import connect              from "react-redux/es/connect/connect";
import {
  ADMIN,
  CLI_READ,
  CLI_WRITE,
  DASHBOARD_READ,
  DIALER_READ,
  DIALER_WRITE,
  FAS_READ,
  FAS_WRITE,
  RECORDING_READ,
  RECORDING_WRITE
}                           from "./RolesConstants";
import Forbidden403         from "../errors/Forbidden403";

import "./Authorization.scss";
import tokenService         from "./TokenService";

// Authorization HOC
const Authorization = (allowedRoles, isPage = true) =>
  (WrappedComponent) => {
    class WithAuthorization extends Component {
      render() {
        const { user } = this.props;
        const roles = user?.roles;
        
        let allowed = false;
        if (roles !== undefined) {
          for (let role of roles) {
            if (allowedRoles.includes(role)) {
              allowed = true;
              break;
            }
          }
        }
        
        if (allowed) {
          return <WrappedComponent {...this.props} />;
        } else {
          if (isPage) { // for pages we display a message while components are just hidden
            return <Forbidden403/>;
          } else {
            return null;
          }
        }
      }
    }
    
    return connect(
      (state) => {
        return {
          user: state.user.login
        };
      },
      {}
    )(WithAuthorization);
  };

export default Authorization;

export const AdminPageAuth = Authorization([ADMIN]);
// export const AdminCompAuth = Authorization([ADMIN], false);

export const CliRPageAuth = Authorization([ADMIN, CLI_READ]);
export const CliWPageAuth = Authorization([ADMIN, CLI_WRITE]);
export const CliWCompAuth = Authorization([ADMIN, CLI_WRITE], false);
export const isCliW = () => {
  return tokenService.hasRoles([ADMIN, CLI_WRITE]);
};

export const DialerRPageAuth = Authorization([ADMIN, DIALER_READ]);
export const DialerWPageAuth = Authorization([ADMIN, DIALER_WRITE]);
export const DialerWCompAuth = Authorization([ADMIN, DIALER_WRITE], false);
export const isDialerW = () => {
  return tokenService.hasRoles([ADMIN, DIALER_WRITE]);
};

export const FasRPageAuth = Authorization([ADMIN, FAS_READ]);
export const FasWPageAuth = Authorization([ADMIN, FAS_WRITE]);
export const FasWCompAuth = Authorization([ADMIN, FAS_WRITE], false);
export const isFasW = () => {
  return tokenService.hasRoles([ADMIN, FAS_WRITE]);
};

export const RecordingRPageAuth = Authorization([ADMIN, RECORDING_READ]);
export const RecordingWPageAuth = Authorization([ADMIN, RECORDING_WRITE]);
export const RecordingWCompAuth = Authorization([ADMIN, RECORDING_WRITE], false);
export const isRecordingW = () => {
  return tokenService.hasRoles([ADMIN, RECORDING_WRITE]);
};

export const DashboardRPageAuth = Authorization([ADMIN, DASHBOARD_READ]);

/**
 * Get the home page for the current user.
 */
export const getHomePage = () => {
  if (tokenService.hasRoles([ADMIN])) {
    return '/admin/users';
  }
  
  if (tokenService.hasRoles([CLI_READ])) {
    return '/admin/cli';
  }
  
  if (tokenService.hasRoles([DIALER_READ])) {
    return '/admin/dialer';
  }
  
  if (tokenService.hasRoles([FAS_READ])) {
    return '/admin/fas';
  }
  
  if (tokenService.hasRoles([RECORDING_READ])) {
    return '/admin/recording';
  }
  
  if (tokenService.hasRoles([DASHBOARD_READ])) {
    return '/admin/dashboard';
  }
  
  return '/admin/dashboard';
};

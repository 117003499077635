import { configureStore }                  from '@reduxjs/toolkit';
import locationReducer, { updateLocation } from './locationSlice';
import userReducer                         from './userSlice';
import cliReducer                          from './cliSlice';
import dialerReducer                       from './dialerSlice';
import distributionReducer                 from './distributionSlice';
import fasReducer                          from './fasSlice';
import recordingReducer                    from './recordingSlice';
import dashboardReducer                    from './dashboardSlice';
import auditReducer                        from './auditSlice';
import metadataReducer                     from './metadataSlice';
import history                             from '../history';

export const store = configureStore({
  reducer: {
    location: locationReducer,
    user: userReducer,
    cli: cliReducer,
    dialer: dialerReducer,
    distribution: distributionReducer,
    fas: fasReducer,
    recording: recordingReducer,
    dashboard: dashboardReducer,
    audit: auditReducer,
    metadata: metadataReducer
  }
});

history.listen(updateLocation(store));

import { httpDelete, httpGet, httpGetBlob, httpPatch, httpPost } from "./Ajax.js";

export const API_ROOT_URL = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
  ? "http://localhost:8080"
  : "";

const BASE_API_URI = API_ROOT_URL + "/api";

/*****************************************************************************************
 * Users Endpoints
 *****************************************************************************************/
export const API_LOGIN = BASE_API_URI + "/user/login";
export const API_LOGOUT = BASE_API_URI + "/user/logout";
export const API_REFRESH_TOKEN = BASE_API_URI + "/user/renew-token";

export const API_FETCH_USERS = BASE_API_URI + "/user/list";
export const API_FETCH_USER = BASE_API_URI + "/user/${userId}";
export const API_ADD_USER = BASE_API_URI + "/user/add";
export const API_EDIT_USER = BASE_API_URI + "/user/${userId}";
export const API_UPDATE_PASSWORD_CURRENT_USER = BASE_API_URI + "/user/password";
export const API_UPDATE_PASSWORD_USER = BASE_API_URI + "/user/password/${userId}";
export const API_DELETE_USER = BASE_API_URI + "/user/${userId}";

/*****************************************************************************************
 * Audit Endpoints
 *****************************************************************************************/
export const API_FETCH_AUDITS = BASE_API_URI + "/audit/list";

/*****************************************************************************************
 * Metadata Endpoints
 *****************************************************************************************/
export const API_FETCH_METADATA = BASE_API_URI + "/metadata/list";

/*****************************************************************************************
 * Reporting Endpoints
 *****************************************************************************************/
export const API_FETCH_REPORTING = BASE_API_URI + "/reporting/list/${type}";
export const API_FETCH_REPORTING_COUNTS = BASE_API_URI + "/reporting/counts";

/*****************************************************************************************
 * CLI Endpoints
 *****************************************************************************************/
export const API_FETCH_CLIS = BASE_API_URI + "/cli/list";
export const API_FETCH_CLI = BASE_API_URI + "/cli/${cliId}";
export const API_ADD_CLI = BASE_API_URI + "/cli/add";
export const API_CLONE_CLI = BASE_API_URI + "/cli/clone/${cliId}";
export const API_DELETE_CLI = BASE_API_URI + "/cli/${cliId}";

export const API_FETCH_CLI_CHANGE = BASE_API_URI + "/cli-change/${cliChangeId}";
export const API_ADD_CLI_CHANGE = BASE_API_URI + "/cli-change/${cliId}/add";
export const API_EDIT_CLI_CHANGE = BASE_API_URI + "/cli-change/${cliChangeId}";
export const API_DELETE_CLI_CHANGE = BASE_API_URI + "/cli-change/${cliChangeId}";

export const API_FETCH_CLI_NUMBERS = BASE_API_URI + "/cli-change/${cliChangeId}/numbers";
export const API_DELETE_CLI_NUMBER = BASE_API_URI + "/cli-change/number/${cliNumberId}";

/*****************************************************************************************
 * Dialer Endpoints
 *****************************************************************************************/
export const API_FETCH_DIALERS = BASE_API_URI + "/dialer/list";
export const API_FETCH_DIALER = BASE_API_URI + "/dialer/${dialerId}";
export const API_ADD_DIALER = BASE_API_URI + "/dialer/add";
export const API_CLONE_DIALER = BASE_API_URI + "/dialer/clone/${dialerId}";
export const API_DELETE_DIALER = BASE_API_URI + "/dialer/${dialerId}";

export const API_FETCH_DIALER_DESTINATION = BASE_API_URI + "/dialer-destination/${dialerDestinationId}";
export const API_ADD_DIALER_DESTINATION = BASE_API_URI + "/dialer-destination/${dialerId}/add";
export const API_EDIT_DIALER_DESTINATION = BASE_API_URI + "/dialer-destination/${dialerDestinationId}";
export const API_DELETE_DIALER_DESTINATION = BASE_API_URI + "/dialer-destination/${dialerDestinationId}";
export const API_TEST_DIALER_DESTINATION = BASE_API_URI + "/dialer-destination/test/${dialerDestinationId}";
export const API_RESET_DIALER_DESTINATION = BASE_API_URI + "/dialer-destination/reset/${dialerDestinationId}";

export const API_FETCH_DIALER_NUMBERS = BASE_API_URI + "/dialer-destination/${dialerDestinationId}/numbers/${type}";
export const API_DELETE_DIALER_NUMBER = BASE_API_URI + "/dialer-destination/number/${dialerNumberId}/${type}";

/*****************************************************************************************
 * Distribution Endpoints
 *****************************************************************************************/
export const API_FETCH_DISTRIBUTIONS = BASE_API_URI + "/distribution/list";
export const API_FETCH_DISTRIBUTION = BASE_API_URI + "/distribution/${distributionId}";
export const API_ADD_DISTRIBUTION = BASE_API_URI + "/distribution/add";
export const API_EDIT_DISTRIBUTION = BASE_API_URI + "/distribution/${distributionId}";
export const API_DELETE_DISTRIBUTION = BASE_API_URI + "/distribution/${distributionId}";

/*****************************************************************************************
 * FAS Endpoints
 *****************************************************************************************/
export const API_FETCH_FASS = BASE_API_URI + "/fas/list";
export const API_FETCH_FAS = BASE_API_URI + "/fas/${fasId}";
export const API_ADD_FAS = BASE_API_URI + "/fas/add";
export const API_EDIT_FAS = BASE_API_URI + "/fas/${fasId}";
export const API_DELETE_FAS = BASE_API_URI + "/fas/${fasId}";

/*****************************************************************************************
 * Recordings GW Endpoints
 *****************************************************************************************/
export const API_FETCH_RECORDINGS = BASE_API_URI + "/recgw/list";
export const API_FETCH_RECORDING = BASE_API_URI + "/recgw/${recordingId}";
export const API_ADD_RECORDING = BASE_API_URI + "/recgw/add";
export const API_EDIT_RECORDING = BASE_API_URI + "/recgw/${recordingId}";
export const API_DELETE_RECORDING = BASE_API_URI + "/recgw/${recordingId}";

/*****************************************************************************************
 * Recordings Search Endpoints
 *****************************************************************************************/
export const API_SEARCH_RECORDINGS = BASE_API_URI + "/recording/list";
export const API_DOWNLOAD_RECORDING_CONTENT_DISK = BASE_API_URI + "/recording/disk/${fileId}";
export const API_DOWNLOAD_ALL_RECORDING_CONTENT_DISK = BASE_API_URI + "/recording/disk/all";
export const API_DELETE_SEARCH_RECORDING = BASE_API_URI + "/recording/${recordingId}";
export const API_DELETE_ALL_SEARCH_RECORDING = BASE_API_URI + "/recording/all";

/*****************************************************************************************
 * Files Endpoints
 *****************************************************************************************/
export const API_DOWNLOAD_FILE_CONTENT = BASE_API_URI + "/file/${fileId}";
export const API_DOWNLOAD_FILE_CONTENT_DISK = BASE_API_URI + "/file/disk/${fileId}";

/*****************************************************************************************
 * Users APIs
 *****************************************************************************************/

/**
 * Try to login the user. This API should return user info.
 */
export const loginUser = (params) => {
  return new Promise((resolve, reject) => {
    httpPost(API_LOGIN, params).then(resolve).catch(reject);
  });
};

/**
 * Logout an user.
 */
export const logoutUser = (params) => {
  return new Promise((resolve, reject) => {
    httpGet(API_LOGOUT).then(resolve).catch(reject);
  });
};

/**
 * Fetch the list of users from the system
 */
export const fetchListOfUsers = () => {
  return new Promise((resolve, reject) => {
    httpGet(API_FETCH_USERS).then(resolve).catch(reject);
  });
};

/**
 * Fetch one user from the system
 */
export const fetchUserById = (userId) => {
  return new Promise((resolve, reject) => {
    httpGet(API_FETCH_USER.interpolate({
      userId
    })).then(resolve).catch(reject);
  });
};

/**
 * Add a new User in the system
 */
export const addUser = (params) => {
  return new Promise((resolve, reject) => {
    httpPost(API_ADD_USER, params).then(resolve).catch(reject);
  });
};

export const editUserById = (userId, params) => {
  return new Promise((resolve, reject) => {
    httpPatch(API_EDIT_USER.interpolate({
      userId
    }), params).then(resolve).catch(reject);
  });
};

export const updateCurrentUserPassword = (params) => {
  return new Promise((resolve, reject) => {
    httpPatch(API_UPDATE_PASSWORD_CURRENT_USER, params).then(resolve).catch(reject);
  });
};

export const updateUserPasswordById = (userId, params) => {
  return new Promise((resolve, reject) => {
    httpPatch(API_UPDATE_PASSWORD_USER.interpolate({
      userId
    }), params).then(resolve).catch(reject);
  });
};

/**
 * Delete User from the system
 */
export const deleteUserById = (userId) => {
  return new Promise((resolve, reject) => {
    httpDelete(API_DELETE_USER.interpolate({
      userId
    })).then(resolve).catch(reject);
  });
};

/*****************************************************************************************
 * Audit APIs
 *****************************************************************************************/

/**
 * Fetch the list of audits from the system
 */
export const fetchListOfAudits = () => {
  return new Promise((resolve, reject) => {
    httpGet(API_FETCH_AUDITS).then(resolve).catch(reject);
  });
};

/*****************************************************************************************
 * Metadata APIs
 *****************************************************************************************/

/**
 * Fetch the list of metadata from the system
 */
export const fetchListOfMetadata = () => {
  return new Promise((resolve, reject) => {
    httpGet(API_FETCH_METADATA).then(resolve).catch(reject);
  });
};

/*****************************************************************************************
 * Reporting APIs
 *****************************************************************************************/

/**
 * Fetch reporting by type
 */
export const fetchReportingByType = (type) => {
  return new Promise((resolve, reject) => {
    httpGet(API_FETCH_REPORTING.interpolate({
      type
    })).then(resolve).catch(reject);
  });
};

/**
 * Fetch reporting counts
 */
export const fetchReportingCounts = () => {
  return new Promise((resolve, reject) => {
    httpGet(API_FETCH_REPORTING_COUNTS).then(resolve).catch(reject);
  });
};

/*****************************************************************************************
 * CLI APIs
 *****************************************************************************************/
/**
 * Fetch the list of CLIs from the system
 */
export const fetchListOfClis = () => {
  return new Promise((resolve, reject) => {
    httpGet(API_FETCH_CLIS).then(resolve).catch(reject);
  });
};

/**
 * Fetch one CLI from the system
 */
export const fetchCliById = (cliId) => {
  return new Promise((resolve, reject) => {
    httpGet(API_FETCH_CLI.interpolate({
      cliId
    })).then(resolve).catch(reject);
  });
};

/**
 * Add a new CLI in the system
 */
export const addCli = (params) => {
  return new Promise((resolve, reject) => {
    httpPost(API_ADD_CLI, params).then(resolve).catch(reject);
  });
};

/**
 * Clone a CLI in the system
 */
export const cloneCliById = (cliId, params) => {
  return new Promise((resolve, reject) => {
    httpPost(API_CLONE_CLI.interpolate({
      cliId
    }), params).then(resolve).catch(reject);
  });
};

/**
 * Delete CLI from the system
 */
export const deleteCliById = (cliId) => {
  return new Promise((resolve, reject) => {
    httpDelete(API_DELETE_CLI.interpolate({
      cliId
    })).then(resolve).catch(reject);
  });
};

/**
 * Fetch one CLI Change from the system
 */
export const fetchCliChangeById = (cliChangeId) => {
  return new Promise((resolve, reject) => {
    httpGet(API_FETCH_CLI_CHANGE.interpolate({
      cliChangeId
    })).then(resolve).catch(reject);
  });
};

/**
 * Add a new CLI Change in the system
 */
export const addCliChange = (cliId, params) => {
  return new Promise((resolve, reject) => {
    httpPost(API_ADD_CLI_CHANGE.interpolate({
      cliId
    }), params).then(resolve).catch(reject);
  });
};

/**
 * Edit a CLI Change in the system
 */
export const editCliChangeById = (cliChangeId, params) => {
  return new Promise((resolve, reject) => {
    httpPatch(API_EDIT_CLI_CHANGE.interpolate({
      cliChangeId
    }), params).then(resolve).catch(reject);
  });
};

/**
 * Delete CLI Change from the system
 */
export const deleteCliChangeById = (cliChangeId) => {
  return new Promise((resolve, reject) => {
    httpDelete(API_DELETE_CLI_CHANGE.interpolate({
      cliChangeId
    })).then(resolve).catch(reject);
  });
};

/**
 * Delete CLI Change Number from the system
 */
export const deleteCliNumberById = (cliNumberId) => {
  return new Promise((resolve, reject) => {
    httpDelete(API_DELETE_CLI_NUMBER.interpolate({
      cliNumberId
    })).then(resolve).catch(reject);
  });
};

/*****************************************************************************************
 * Dialer APIs
 *****************************************************************************************/

/**
 * Fetch the list of Dialers from the system
 */
export const fetchListOfDialers = () => {
  return new Promise((resolve, reject) => {
    httpGet(API_FETCH_DIALERS).then(resolve).catch(reject);
  });
};

/**
 * Fetch one Dialer from the system
 */
export const fetchDialerById = (dialerId) => {
  return new Promise((resolve, reject) => {
    httpGet(API_FETCH_DIALER.interpolate({
      dialerId
    })).then(resolve).catch(reject);
  });
};

/**
 * Add a new Dialer in the system
 */
export const addDialer = (params) => {
  return new Promise((resolve, reject) => {
    httpPost(API_ADD_DIALER, params).then(resolve).catch(reject);
  });
};

/**
 * Clone a Dialer in the system
 */
export const cloneDialerById = (dialerId, params) => {
  return new Promise((resolve, reject) => {
    httpPost(API_CLONE_DIALER.interpolate({
      dialerId
    }), params).then(resolve).catch(reject);
  });
};

/**
 * Delete Dialer from the system
 */
export const deleteDialerById = (dialerId) => {
  return new Promise((resolve, reject) => {
    httpDelete(API_DELETE_DIALER.interpolate({
      dialerId
    })).then(resolve).catch(reject);
  });
};

/**
 * Fetch one Dialer Destination from the system
 */
export const fetchDialerDestinationById = (dialerDestinationId) => {
  return new Promise((resolve, reject) => {
    httpGet(API_FETCH_DIALER_DESTINATION.interpolate({
      dialerDestinationId
    })).then(resolve).catch(reject);
  });
};

/**
 * Add a new Dialer Destination in the system
 */
export const addDialerDestination = (dialerId, params) => {
  return new Promise((resolve, reject) => {
    httpPost(API_ADD_DIALER_DESTINATION.interpolate({
      dialerId
    }), params).then(resolve).catch(reject);
  });
};

/**
 * Edit a Dialer Destination in the system
 */
export const editDialerDestinationById = (dialerDestinationId, params) => {
  return new Promise((resolve, reject) => {
    httpPatch(API_EDIT_DIALER_DESTINATION.interpolate({
      dialerDestinationId
    }), params).then(resolve).catch(reject);
  });
};

/**
 * Delete Dialer Destination from the system
 */
export const deleteDialerDestinationById = (dialerDestinationId) => {
  return new Promise((resolve, reject) => {
    httpDelete(API_DELETE_DIALER_DESTINATION.interpolate({
      dialerDestinationId
    })).then(resolve).catch(reject);
  });
};

/**
 * Test Dialer Destination
 */
export const testDialerDestinationById = (dialerDestinationId) => {
  return new Promise((resolve, reject) => {
    httpPost(API_TEST_DIALER_DESTINATION.interpolate({
      dialerDestinationId
    })).then(resolve).catch(reject);
  });
};

/**
 * Reset Dialer Destination
 */
export const resetDialerDestinationById = (dialerDestinationId) => {
  return new Promise((resolve, reject) => {
    httpPost(API_RESET_DIALER_DESTINATION.interpolate({
      dialerDestinationId
    })).then(resolve).catch(reject);
  });
};

/**
 * Delete Dialer Number from the system
 */
export const deleteDialerNumberById = (dialerNumberId, type) => {
  return new Promise((resolve, reject) => {
    httpDelete(API_DELETE_DIALER_NUMBER.interpolate({
      dialerNumberId,
      type
    })).then(resolve).catch(reject);
  });
};

/*****************************************************************************************
 * Distribution APIs
 *****************************************************************************************/
/**
 * Fetch the list of Distributions from the system
 */
export const fetchListOfDistributions = () => {
  return new Promise((resolve, reject) => {
    httpGet(API_FETCH_DISTRIBUTIONS).then(resolve).catch(reject);
  });
};

/**
 * Fetch one Distribution from the system
 */
export const fetchDistributionById = (distributionId) => {
  return new Promise((resolve, reject) => {
    httpGet(API_FETCH_DISTRIBUTION.interpolate({
      distributionId
    })).then(resolve).catch(reject);
  });
};

/**
 * Add a new Distribution in the system
 */
export const addDistribution = (params) => {
  return new Promise((resolve, reject) => {
    httpPost(API_ADD_DISTRIBUTION, params).then(resolve).catch(reject);
  });
};

/**
 * Edit a Distribution in the system
 */
export const editDistributionById = (distributionId, params) => {
  return new Promise((resolve, reject) => {
    httpPatch(API_EDIT_DISTRIBUTION.interpolate({
      distributionId
    }), params).then(resolve).catch(reject);
  });
};

/**
 * Delete Distribution from the system
 */
export const deleteDistributionById = (distributionId) => {
  return new Promise((resolve, reject) => {
    httpDelete(API_DELETE_DISTRIBUTION.interpolate({
      distributionId
    })).then(resolve).catch(reject);
  });
};

/*****************************************************************************************
 * FAS APIs
 *****************************************************************************************/
/**
 * Fetch the list of FASs from the system
 */
export const fetchListOfFass = () => {
  return new Promise((resolve, reject) => {
    httpGet(API_FETCH_FASS).then(resolve).catch(reject);
  });
};

/**
 * Fetch one FAS from the system
 */
export const fetchFasById = (fasId) => {
  return new Promise((resolve, reject) => {
    httpGet(API_FETCH_FAS.interpolate({
      fasId
    })).then(resolve).catch(reject);
  });
};

/**
 * Add a new FAS in the system
 */
export const addFas = (params) => {
  return new Promise((resolve, reject) => {
    httpPost(API_ADD_FAS, params).then(resolve).catch(reject);
  });
};

/**
 * Edit a FAS in the system
 */
export const editFasById = (fasId, params) => {
  return new Promise((resolve, reject) => {
    httpPatch(API_EDIT_FAS.interpolate({
      fasId
    }), params).then(resolve).catch(reject);
  });
};

/**
 * Delete FAS from the system
 */
export const deleteFasById = (fasId) => {
  return new Promise((resolve, reject) => {
    httpDelete(API_DELETE_FAS.interpolate({
      fasId
    })).then(resolve).catch(reject);
  });
};

/*****************************************************************************************
 * Recording /GW APIs
 *****************************************************************************************/
/**
 * Fetch the list of Recordings from the system
 */
export const fetchListOfRecordings = () => {
  return new Promise((resolve, reject) => {
    httpGet(API_FETCH_RECORDINGS).then(resolve).catch(reject);
  });
};

/**
 * Fetch one Recording from the system
 */
export const fetchRecordingById = (recordingId) => {
  return new Promise((resolve, reject) => {
    httpGet(API_FETCH_RECORDING.interpolate({
      recordingId
    })).then(resolve).catch(reject);
  });
};

/**
 * Add a new Recording in the system
 */
export const addRecording = (params) => {
  return new Promise((resolve, reject) => {
    httpPost(API_ADD_RECORDING, params).then(resolve).catch(reject);
  });
};

/**
 * Edit a Recording in the system
 */
export const editRecordingById = (recordingId, params) => {
  return new Promise((resolve, reject) => {
    httpPatch(API_EDIT_RECORDING.interpolate({
      recordingId
    }), params).then(resolve).catch(reject);
  });
};

/**
 * Delete Recording GW from the system
 */
export const deleteRecordingById = (recordingId) => {
  return new Promise((resolve, reject) => {
    httpDelete(API_DELETE_RECORDING.interpolate({
      recordingId
    })).then(resolve).catch(reject);
  });
};

/**
 * Delete Recording "Search" from the system
 */
export const deleteRecordingSearchById = (recordingId) => {
  return new Promise((resolve, reject) => {
    httpDelete(API_DELETE_SEARCH_RECORDING.interpolate({
      recordingId
    })).then(resolve).catch(reject);
  });
};

/**
 * Delete All Recording "Search" from the system
 */
export const deleteRecordingSearchAll = (params) => {
  return new Promise((resolve, reject) => {
    httpDelete(API_DELETE_ALL_SEARCH_RECORDING, params).then(resolve).catch(reject);
  });
};

/*****************************************************************************************
 * File APIs
 *****************************************************************************************/

/**
 * Fetch File Content
 */
export const fetchFileContent = (fileId) => {
  return new Promise((resolve, reject) => {
    httpGetBlob(API_DOWNLOAD_FILE_CONTENT.interpolate({
      fileId
    })).then(resolve).catch(reject);
  });
};

/**
 * Fetch File Content from disk
 */
export const fetchFileContentFromDisk = (fileId) => {
  return new Promise((resolve, reject) => {
    httpGetBlob(API_DOWNLOAD_FILE_CONTENT_DISK.interpolate({
      fileId
    })).then(resolve).catch(reject);
  });
};

/**
 * Fetch Recording Content from disk
 */
export const fetchRecordingContentFromDisk = (fileId) => {
  return new Promise((resolve, reject) => {
    httpGetBlob(API_DOWNLOAD_RECORDING_CONTENT_DISK.interpolate({
      fileId
    })).then(resolve).catch(reject);
  });
};

/**
 * Fetch All Recordings Content from disk
 */
export const fetchAllRecordingContentFromDisk = (params) => {
  return new Promise((resolve, reject) => {
    httpGetBlob(API_DOWNLOAD_ALL_RECORDING_CONTENT_DISK, params).then(resolve).catch(reject);
  });
};

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: {},             // list of audits
  listLoaded: false
};

export const auditSlice = createSlice({
  name: 'audit',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase("user/logoutSuccess", (state, action) => {
        return initialState;
      });
  }
});

// export const {} = auditSlice.actions;

export default auditSlice.reducer;

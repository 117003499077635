import React, { Component }                                                       from 'react';
import { Link }                                                                   from "react-router-dom";
import history                                                                    from "../../history";
import connect                                                                    from "react-redux/es/connect/connect";

import "./DefaultSidebar.scss";
import PropTypes                                                                  from "prop-types";
import { ADMIN, CLI_READ, DASHBOARD_READ, DIALER_READ, FAS_READ, RECORDING_READ } from "../../security/RolesConstants";
import _                                                                          from "lodash";
import tokenService                                                               from "../../security/TokenService";

class DefaultSidebar extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired
  };
  
  constructor() {
    super();
    
    // Define the initial state:
    this.state = {
      allOptions: [
        {
          id: "users",
          name: "Users",
          link: "/admin/users",
          icon: "fas fa-users fa-lg",
          active: true,
          roles: [ADMIN]
        },
        {
          id: "cli",
          name: "CLI",
          link: "/admin/cli",
          icon: "fas fa-history fa-lg",
          active: false,
          roles: [ADMIN, CLI_READ]
        },
        {
          id: "dialer",
          name: "Dialer",
          link: "/admin/dialer",
          icon: "fas fa-phone fa-lg",
          active: false,
          roles: [ADMIN, DIALER_READ]
        },
        {
          id: "distribution",
          name: "Distribution",
          link: "/admin/distribution",
          icon: "fas fa-project-diagram fa-lg",
          active: false,
          roles: [ADMIN, DIALER_READ]
        },
        {
          id: "fas",
          name: "FAS",
          link: "/admin/fas",
          icon: "fas fa-file-audio fa-lg",
          active: false,
          roles: [ADMIN, FAS_READ]
        },
        {
          id: "recgw",
          name: "Rec GW",
          link: "/admin/recgw",
          icon: "fas fa-sliders fa-lg",
          active: false,
          roles: [ADMIN, RECORDING_READ]
        },
        {
          id: "recording",
          name: "Recordings",
          link: "/admin/recording",
          icon: "fas fa-voicemail fa-lg",
          active: false,
          roles: [ADMIN, RECORDING_READ]
        },
        {
          id: "dashboards",
          name: "Dashboards",
          link: "/admin/dashboard",
          icon: "fas fa-chart-column fa-lg",
          active: false,
          roles: [ADMIN, DASHBOARD_READ]
        },
        {
          id: "audit",
          name: "Audit",
          link: "/admin/audit",
          icon: "fas fa-clipboard-list fa-lg",
          active: false,
          roles: [ADMIN]
        }
      ]
    };
    
    // filter current user options
    this.state.options = _.filter(this.state.allOptions, option => {
      if (_.isEmpty(option.roles)) {
        return true;
      }
      
      return tokenService.hasRoles(option.roles);
    });
    
    // Binding this keyword
    this.handleNavClick = this.handleNavClick.bind(this);
  }
  
  componentDidMount() {
    const options = this.state.options;
    options.forEach(option => {
      if (history.location.pathname.includes(option.link)) {
        this.handleNavClick(option.id);
      }
    });
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      const options = this.state.options;
      options.forEach(option => {
        if (this.props.location.pathname.includes(option.link)) {
          this.handleNavClick(option.id);
        }
      });
    }
  }
  
  handleNavClick(activeOption) {
    const options = this.state.options;
    options.forEach(option => {
      option.active = false;
      if (option.id === activeOption) {
        option.active = true;
      }
    });
    
    // Changing state
    this.setState(options);
  }
  
  render() {
    const { options } = this.state;
    
    return (
      <div>
        <ul className="nav nav-pills flex-column mb-auto">
          {
            options.map(option =>
              <li className="nav-item" key={option.id}>
                <Link to={option.link} className={`nav-link ${option.active ? 'active' : ''}`}
                      onClick={() => this.handleNavClick(option.id)}>
                  <i className={option.icon}></i>
                  {option.name}
                </Link>
              </li>
            )
          }
        </ul>
      </div>
    );
  };
}

export default connect(
  (state) => {
    return {
      location: state.location.value
    };
  },
  {}
)(DefaultSidebar);

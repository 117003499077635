import React, { Component }                                           from 'react';

import "./AddDialerDestination.scss";
import connect                                                        from "react-redux/es/connect/connect";
import PropTypes                                                      from "prop-types";
import { clearStateCreateDialerDestination, createDialerDestination } from "../../redux/dialerSlice";
import _                                                              from "lodash";
import { toParam, toSelector }                                        from "../../util/SelectorUtils";
import Select                                                         from "react-select";

class AddDialerDestination extends Component {
  static propTypes = {
    distributions: PropTypes.array,
    
    createdDialerDestination: PropTypes.object,
    createDialerDestinationProcess: PropTypes.bool,
    
    createDialerDestination: PropTypes.func.isRequired,
    clearStateCreateDialerDestination: PropTypes.func.isRequired,
  };
  
  constructor(props) {
    super(props);
    
    this.state = {
      dialerDestinationName: "",
      distribution: undefined,
      emailNotifications: "",
      weekdaysDailyMinutes: undefined,
      weekendDailyMinutes: undefined,
      acd: undefined,
      asr: undefined,
      dailyVariation: undefined,
      dailyFailsStop: undefined,
      g729Enabled: true,
      bNoValidation: true,
      enabled: true,
      sourceFile: undefined,
      destinationFile: undefined
    };
    
    this.handleChange = this.handleChange.bind(this);
    this.handleDistributionChange = this.handleDistributionChange.bind(this);
  }
  
  componentWillUnmount() {
    const { clearStateCreateDialerDestination } = this.props;
    clearStateCreateDialerDestination();
  }
  
  handleChange = (event) => {
    const { target } = event;
    let { id } = target;
    let value = target.value;
    
    if (target.type === "file") {
      value = target.files[0];
    } else {
      if (target.type === "checkbox") {
        value = target.checked;
      } else {
        if (target.type === "radio") {
          id = target.name;
          value = target.value;
        } else {
          value = target.value;
        }
      }
    }
    
    this.setState({
      [id]: value,
    });
  };
  
  handleDistributionChange = (distribution) => {
    this.setState({
      distribution
    });
  };
  
  submitForm(event) {
    const { createDialerDestination } = this.props;
    event.preventDefault();
    
    // extract Dialer id
    let dialerId;
    if (this.props?.match?.params?.id !== undefined) {
      dialerId = _.parseInt(this.props.match.params.id);
    }
    
    if (dialerId === undefined) {
      console.error("Something is wrong! we don't have the Dialer id at this point!");
      return;
    }
    
    // create an object of formData
    const formData = new FormData();
    
    const dialerDestinationCreate = new Blob([JSON.stringify({
      dialerDestinationName: this.state.dialerDestinationName,
      distribution: toParam(this.state.distribution),
      emailNotifications: this.state.emailNotifications,
      weekdaysDailyMinutes: this.state.weekdaysDailyMinutes,
      weekendDailyMinutes: this.state.weekendDailyMinutes,
      acd: this.state.acd,
      asr: this.state.asr,
      dailyVariation: this.state.dailyVariation,
      dailyFailsStop: this.state.dailyFailsStop,
      g729Enabled: this.state.g729Enabled,
      bNoValidation: this.state.bNoValidation,
      enabled: this.state.enabled
    })], {
      type: 'application/json'
    });
    
    // update the formData object
    formData.append("dialerDestinationCreate", dialerDestinationCreate);
    formData.append("sourceFile", this.state.sourceFile);
    formData.append("destinationFile", this.state.destinationFile);
    
    createDialerDestination(dialerId, formData);
  }
  
  render() {
    const {
      dialerDestinationName,
      distribution,
      emailNotifications,
      weekdaysDailyMinutes,
      weekendDailyMinutes,
      acd,
      asr,
      dailyVariation,
      dailyFailsStop,
      g729Enabled,
      bNoValidation,
      enabled
    } = this.state;
    
    const { distributions, createdDialerDestination, createDialerDestinationProcess } = this.props;
    
    return (
      <div className="distribution-profile section">
        
        <div className="section-title">
          <h2>Add new Dialer Destination</h2>
        </div>
        
        <div className="row">
          <div className="col-12">
            <form onSubmit={(event) => this.submitForm(event)}>
              
              <div className="row mt-3">
                <div className="col-6">
                  <label htmlFor="dialerDestinationName" className="form-label">Dialer Destination Name</label>
                  <input type="text" className="form-control" name="dialerDestinationName" id="dialerDestinationName"
                         placeholder="destination name"
                         value={dialerDestinationName}
                         onChange={(event) => this.handleChange(event)} required/>
                  <div className="form-text">Please provide a unique name within this Dialer Destination</div>
                </div>
                
                <div className="col-6">
                  <label htmlFor="distribution" className="form-label">Distribution</label>
                  <Select
                    id="distribution"
                    name="distribution"
                    options={toSelector(distributions)}
                    value={distribution}
                    classNamePrefix="select"
                    onChange={(event) => this.handleDistributionChange(event)}/>
                </div>
              </div>
              
              <div className="row mt-3">
                <div className="col-12">
                  <label htmlFor="emailNotifications" className="form-label">Email Notifications</label>
                  <input type="text" className="form-control" name="emailNotifications" id="emailNotifications"
                         placeholder="email addresses"
                         value={emailNotifications}
                         onChange={(event) => this.handleChange(event)} required/>
                  <div className="form-text">Please provide a comma separated list of emails</div>
                </div>
              </div>
              
              <div className="row mt-3">
                <div className="col-6">
                  <label htmlFor="weekdaysDailyMinutes" className="form-label">Weekdays Daily Minutes</label>
                  <input type="number" className="form-control" name="weekdaysDailyMinutes" id="weekdaysDailyMinutes"
                         placeholder="minutes"
                         value={weekdaysDailyMinutes}
                         onChange={(event) => this.handleChange(event)} required/>
                </div>
                
                <div className="col-6">
                  <label htmlFor="weekendDailyMinutes" className="form-label">Weekend Daily Minutes</label>
                  <input type="number" className="form-control" name="weekendDailyMinutes" id="weekendDailyMinutes"
                         placeholder="minutes"
                         value={weekendDailyMinutes}
                         onChange={(event) => this.handleChange(event)} required/>
                </div>
              </div>
              
              <div className="row mt-3">
                <div className="col-4">
                  <label htmlFor="acd" className="form-label">ACD</label>
                  <input type="number" className="form-control" name="acd" id="acd"
                         placeholder="acd"
                         value={acd}
                         onChange={(event) => this.handleChange(event)} required/>
                </div>
                
                <div className="col-4">
                  <label htmlFor="asr" className="form-label">ASR</label>
                  <input type="number" className="form-control" name="asr" id="asr"
                         placeholder="asr"
                         value={asr}
                         onChange={(event) => this.handleChange(event)} required/>
                </div>
                
                <div className="col-4">
                  <label htmlFor="dailyVariation" className="form-label">Daily Variation</label>
                  <input type="number" className="form-control" name="dailyVariation" id="dailyVariation"
                         placeholder="variation"
                         value={dailyVariation}
                         onChange={(event) => this.handleChange(event)} required/>
                </div>
              </div>
              
              <div className="row mt-3">
                <div className="col-3">
                  <label htmlFor="dailyFailsStop" className="form-label">Daily Fails Stop</label>
                  <input type="number" className="form-control" name="dailyFailsStop" id="dailyFailsStop"
                         placeholder="fails"
                         value={dailyFailsStop}
                         onChange={(event) => this.handleChange(event)} required/>
                </div>
  
                <div className="col-3 d-flex justify-content-center">
                  <div className="mb-3">
                    <label htmlFor="g729Enabled" className="form-label">G729 Enabled</label>
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" id="g729Enabled"
                             checked={g729Enabled ? g729Enabled : false}
                             onChange={(event) => this.handleChange(event)}/>
                    </div>
                  </div>
                </div>
                
                <div className="col-3 d-flex justify-content-center">
                  <div className="mb-3">
                    <label htmlFor="bNoValidation" className="form-label">B-number only Validation</label>
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" id="bNoValidation"
                             checked={bNoValidation ? bNoValidation : false}
                             onChange={(event) => this.handleChange(event)}/>
                    </div>
                  </div>
                </div>
  
                <div className="col-3 d-flex justify-content-center">
                  <div className="mb-3">
                    <label htmlFor="enabled" className="form-label">Enabled</label>
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" id="enabled"
                             checked={enabled ? enabled : false}
                             onChange={(event) => this.handleChange(event)}/>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="row mt-3">
                <div className="col-6">
                  <label htmlFor="sourceFile" className="form-label">Upload Dialer Source Numbers</label>
                  <input className="form-control" type="file" name="sourceFile" id="sourceFile"
                         onChange={(event) => this.handleChange(event)}/>
                  <div className="form-text">(Optional) Upload an Excel document - this can be edited later</div>
                </div>
                
                <div className="col-6">
                  <label htmlFor="destinationFile" className="form-label">Upload Dialer Destination Numbers</label>
                  <input className="form-control" type="file" name="destinationFile" id="destinationFile"
                         onChange={(event) => this.handleChange(event)}/>
                  <div className="form-text">(Optional) Upload an Excel document - this can be edited later</div>
                </div>
              </div>
              
              <div className="row mt-5">
                <div className="col-12">
                  {
                    (createDialerDestinationProcess === false)
                      ? <button type="submit" className="btn btn-primary"><i className="fas fa-save"></i> Create Dialer
                        Destination
                      </button>
                      : <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Create
                        Dialer Destination
                      </button>
                  }
                </div>
              </div>
            
            </form>
            
            {(!_.isEmpty(createdDialerDestination.errors))
              ? <div className="alert alert-danger login-error" role="alert">
                {
                  createdDialerDestination.errors.map(error => <div key={error.fieldName + error.message}>
                    {
                      (error.fieldName !== undefined)
                        ? error.fieldName + " - "
                        : null
                    }
                    {error.message}
                  </div>)
                }
              </div>
              : null
            }
          </div>
        </div>
      
      </div>
    );
  };
}

export default connect(
  (state, props) => {
    return {
      distributions: state.metadata.list["distribution"],
      
      createdDialerDestination: state.dialer.createdDialerDestination,
      createDialerDestinationProcess: state.dialer.createDialerDestinationProcess
    };
  },
  {
    createDialerDestination: (dialerId, dialerDestinationData) => createDialerDestination(dialerId, dialerDestinationData),
    clearStateCreateDialerDestination: () => clearStateCreateDialerDestination()
  }
)(AddDialerDestination);

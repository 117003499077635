import React, { Component }                      from 'react';

import "./AddDialer.scss";
import connect                                   from "react-redux/es/connect/connect";
import { clearStateCreatedDialer, createDialer } from "../../redux/dialerSlice";
import PropTypes                                 from "prop-types";
import _                                         from "lodash";

class AddDialer extends Component {
  static propTypes = {
    createdDialer: PropTypes.object,
    createDialerProcess: PropTypes.bool,
    
    createDialer: PropTypes.func.isRequired,
    clearStateCreatedDialer: PropTypes.func.isRequired,
  };
  
  constructor(props) {
    super(props);
    
    this.state = {
      dialerId: "",
      dialerName: "",
    };
    
    this.handleChange = this.handleChange.bind(this);
  }
  
  componentWillUnmount() {
    const { clearStateCreatedDialer } = this.props;
    clearStateCreatedDialer();
  }
  
  handleChange = (event) => {
    const { target } = event;
    let { id } = target;
    let value = target.value;
    
    if (target.type === "checkbox") {
      value = target.checked;
    } else {
      if (target.type === "radio") {
        id = target.name;
        value = target.value;
      } else {
        value = target.value;
      }
    }
    
    this.setState({
      [id]: value,
    });
  };
  
  submitForm(event) {
    const { createDialer } = this.props;
    
    event.preventDefault();
    
    createDialer(this.state);
  }
  
  render() {
    const { dialerId, dialerName } = this.state;
    const { createdDialer, createDialerProcess } = this.props;
    
    return (
      <div className="add-dialer section">
        
        <div className="section-title">
          <h2>Add new Dialer Campaign</h2>
        </div>
        
        
        <div className="row">
          <div className="col-12">
            <form onSubmit={(event) => this.submitForm(event)}>
              
              <div className="row mt-3">
                <div className="col-6">
                  <label htmlFor="dialerId" className="form-label">Dialer Id</label>
                  <input type="number" min={10000} max={99999} className="form-control" name="dialerId" id="dialerId"
                         placeholder="dialer id"
                         value={dialerId}
                         onChange={(event) => this.handleChange(event)} required/>
                  <div className="form-text">Please provide a unique number with exactly 5 digits</div>
                </div>
                
                <div className="col-6">
                  <label htmlFor="dialerName" className="form-label">Dialer Name</label>
                  <input type="text" className="form-control" name="dialerName" id="dialerName"
                         placeholder="dialer name"
                         value={dialerName}
                         onChange={(event) => this.handleChange(event)} required/>
                  <div className="form-text">Please provide a unique name</div>
                </div>
              
              </div>
              
              
              <div className="row mt-5">
                <div className="col-12">
                  {
                    (createDialerProcess === false)
                      ? <button type="submit" className="btn btn-primary"><i className="fas fa-save"></i> Create Dialer
                        Campaign
                      </button>
                      : <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Create
                        Dialer Campaign
                      </button>
                  }
                </div>
              </div>
            
            </form>
            
            {(!_.isEmpty(createdDialer.errors))
              ? <div className="alert alert-danger login-error" role="alert">
                {
                  createdDialer.errors.map(error => <div key={error.fieldName + error.message}>
                    {
                      (error.fieldName !== undefined)
                        ? error.fieldName + " - "
                        : null
                    }
                    {error.message}
                  </div>)
                }
              </div>
              : null
            }
          </div>
        </div>
      
      </div>
    );
  };
}

export default connect(
  (state) => {
    return {
      createdDialer: state.dialer.createdDialer,
      createDialerProcess: state.dialer.createDialerProcess
    };
  },
  {
    createDialer: (dialerData) => createDialer(dialerData),
    clearStateCreatedDialer: () => clearStateCreatedDialer()
  }
)(AddDialer);

import React, { Component } from 'react';

import "./Forbidden403.scss";

export default class Forbidden403 extends Component {
  render() {
    return (
      <div className="row">
        
        <div className="unauthorized d-flex flex-column min-vh-100 justify-content-center align-items-center">
          
          <div className="lock">
            <i className="fas fa-lock fa-5x"></i>
          </div>
          <div className="message">
            <h1>Access to this page is restricted</h1>
            <p>Please check with the site admin if you believe this is a mistake.</p>
          </div>
        </div>
      
      </div>
    );
  };
}

import React, { Component }      from 'react';

import "./UserProfile.scss";
import connect                   from "react-redux/es/connect/connect";
import {
  clearStateEditUser,
  clearStatePasswordUser,
  editUser,
  getUserById,
  updatePassword
}                                from "../../redux/userSlice";
import PropTypes                 from "prop-types";
import Select                    from 'react-select';
import _                         from "lodash";
import { allRoles, exportRoles } from "../../security/RolesConstants";
import { toParams }              from "../../util/SelectorUtils";

class UserProfile extends Component {
  static propTypes = {
    user: PropTypes.object,
    
    passwordUser: PropTypes.object,
    passwordUserProcess: PropTypes.bool,
    
    getUserById: PropTypes.func.isRequired,
    clearStateEditUser: PropTypes.func.isRequired,
    editUser: PropTypes.func.isRequired,
    
    updatePassword: PropTypes.func.isRequired,
    clearStatePasswordUser: PropTypes.func.isRequired,
  };
  
  constructor(props) {
    super(props);
    
    this.state = {
      user: undefined,
      
      showChangePassword: false,
      newPassword: "",
      confirmPassword: ""
    };
    
    this.handleChange = this.handleChange.bind(this);
    this.handleRolesChange = this.handleRolesChange.bind(this);
    this.handlePassChange = this.handlePassChange.bind(this);
  }
  
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps?.user?.id !== prevState?.user?.id) {
      console.log("[[>>> getDerivedStateFromProps]]");
      return {
        user: {
          ...nextProps.user,
          roles: exportRoles(nextProps.user.roles)
        }
      };
    } else {
      return null;
    }
  }
  
  componentDidMount() {
    const { user, getUserById } = this.props;
    
    if (this.props?.match?.params?.id !== undefined) {
      const userId = _.parseInt(this.props.match.params.id);
      if (user === undefined) {
        getUserById(userId);
      }
    }
  }
  
  componentWillUnmount() {
    const { clearStateEditUser, clearStatePasswordUser } = this.props;
    clearStateEditUser();
    clearStatePasswordUser();
  }
  
  handleChange = (event) => {
    const { target } = event;
    let { id } = target;
    let value = target.value;
    
    
    if (target.type === "checkbox") {
      value = target.checked;
    } else {
      if (target.type === "radio") {
        id = target.name;
        value = target.value;
      } else {
        value = target.value;
      }
    }
    
    this.setState({
      user: {
        ...this.state["user"],
        [id]: value
      }
    });
  };
  
  handlePassChange = (event) => {
    const { target } = event;
    let { id } = target;
    let value = target.value;
    
    if (target.type === "checkbox") {
      value = target.checked;
    } else {
      if (target.type === "radio") {
        id = target.name;
        value = target.value;
      } else {
        value = target.value;
      }
    }
    
    this.setState({
      [id]: value,
    });
  };
  
  handleRolesChange = (roles) => {
    this.setState({
      user: {
        ...this.state["user"],
        roles
      }
    });
  };
  
  submitForm(event) {
    const { editUser } = this.props;
    
    event.preventDefault();
    
    // extract User id
    let userId;
    if (this.props?.match?.params?.id !== undefined) {
      userId = _.parseInt(this.props.match.params.id);
    }
    if (userId === undefined) {
      console.error("Something is wrong! we don't have the User id at this point!");
      return;
    }
    
    const userData = {
      username: this.state.user.username,
      firstName: this.state.user.firstName,
      lastName: this.state.user.lastName,
      roles: toParams(this.state.user.roles),
      enabled: this.state.user.enabled,
    };
    
    editUser(userId, userData);
  }
  
  submitPassForm(event) {
    const { updatePassword } = this.props;
    
    event.preventDefault();
    
    // extract CLI id
    let userId;
    if (this.props?.match?.params?.id !== undefined) {
      userId = _.parseInt(this.props.match.params.id);
    }
    if (userId === undefined) {
      console.error("Something is wrong! we don't have the User id at this point!");
      return;
    }
    
    const userData = {
      newPassword: this.state.newPassword,
      confirmPassword: this.state.confirmPassword,
    };
    
    updatePassword(userId, userData);
  }
  
  
  render() {
    const { user, showChangePassword, newPassword, confirmPassword } = this.state;
    const { editedUser, editUserProcess, passwordUser, passwordUserProcess } = this.props;
    
    return (
      <div className="user-profile section">
        <div className="section-title">
          <h2>Edit User</h2>
        </div>
        
        {(user !== undefined)
          ? <div className="row">
            <div className="col-12">
              
              <form onSubmit={(event) => this.submitForm(event)}>
                
                <div className="row mt-3">
                  <div className="col-4">
                    <label htmlFor="username" className="form-label">Username</label>
                    <input type="text" className="form-control" name="username" id="username" placeholder="username"
                           value={user?.username}
                           onChange={(event) => this.handleChange(event)} disabled/>
                  </div>
                  
                  <div className="col-4">
                    <label htmlFor="firstName" className="form-label">First Name</label>
                    <input type="text" className="form-control" name="firstName" id="firstName"
                           placeholder="first name"
                           value={user?.firstName}
                           onChange={(event) => this.handleChange(event)} required/>
                  </div>
                  
                  <div className="col-4">
                    <label htmlFor="lastName" className="form-label">Last Name</label>
                    <input type="text" className="form-control" name="lastName" id="lastName" placeholder="last name"
                           value={user?.lastName}
                           onChange={(event) => this.handleChange(event)} required/>
                  </div>
                </div>
                
                <div className="row mt-4">
                  <div className="col-8">
                    <label htmlFor="roles" className="form-label">Roles</label>
                    <Select isMulti
                            id="roles"
                            name="roles"
                            options={allRoles}
                            value={user?.roles}
                            classNamePrefix="select"
                            onChange={(event) => this.handleRolesChange(event)}/>
                  </div>
                  
                  <div className="col-4 d-flex justify-content-center">
                    <div>
                      <label htmlFor="enabled" className="form-label">Enabled</label>
                      <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="enabled"
                               checked={user?.enabled ? user.enabled : false}
                               onChange={(event) => this.handleChange(event)}/>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="row mt-5">
                  <div className="col-12">
                    {
                      (editUserProcess === false)
                        ? <button type="submit" className="btn btn-primary"><i className="fas fa-redo"></i> Update User
                        </button>
                        : <button className="btn btn-primary" type="button" disabled>
                          <span className="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span> Update User
                        </button>
                    }
                  
                  </div>
                </div>
              </form>
              
              
              {(!_.isEmpty(editedUser.errors))
                ? <div className="alert alert-danger login-error" role="alert">
                  {
                    editedUser.errors.map(error => <div key={error.fieldName + error.message}>
                      {
                        (error.fieldName !== undefined)
                          ? error.fieldName + " - "
                          : null
                      }
                      {error.message}
                    </div>)
                  }
                </div>
                : null
              }
            
            </div>
            
            
            <div className="col-12">
              
              <hr className="bg-info m-4 border-info"/>
              
              <div className="row">
                <div className="col-4">
                  <div>
                    <label htmlFor="showChangePassword" className="form-label">Change Password</label>
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" id="showChangePassword"
                             checked={showChangePassword ? showChangePassword : false}
                             onChange={(event) => this.handlePassChange(event)}/>
                    </div>
                  </div>
                </div>
              </div>
              
              {
                (showChangePassword === true)
                  ? <div>
                    <div className="row">
                      <div className="col-12">
                        
                        <form onSubmit={(event) => this.submitPassForm(event)} autoComplete="off">
                          
                          <div className="row mt-4">
                            <div className="col-4">
                              <label htmlFor="newPassword" className="form-label">New Password</label>
                              <input type="password" className="form-control" name="newPassword" id="newPassword"
                                     placeholder="********"
                                     autoComplete="off" value={newPassword}
                                     onChange={(event) => this.handlePassChange(event)} required/>
                            </div>
                            
                            <div className="col-4">
                              <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                              <input type="password" className="form-control" name="confirmPassword"
                                     id="confirmPassword"
                                     placeholder="********"
                                     autoComplete="off" value={confirmPassword}
                                     onChange={(event) => this.handlePassChange(event)} required/>
                            </div>
                            
                            <div className="col-4 d-flex align-self-center justify-content-center">
                              {
                                (passwordUserProcess === false)
                                  ?
                                  <button type="submit" className="btn btn-primary"><i className="fas fa-key"></i> Update
                                    Password
                                  </button>
                                  : <button className="btn btn-primary" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status"
                                          aria-hidden="true"></span> Update
                                    Password
                                  </button>
                              }
                            </div>
                          </div>
                        </form>
                        
                        {(!_.isEmpty(passwordUser.errors))
                          ? <div className="alert alert-danger login-error" role="alert">
                            {
                              passwordUser.errors.map(error => <div key={error.fieldName + error.message}>
                                {
                                  (error.fieldName !== undefined)
                                    ? error.fieldName + " - "
                                    : null
                                }
                                {error.message}
                              </div>)
                            }
                          </div>
                          : null
                        }
                      
                      </div>
                    </div>
                  </div>
                  : null
              }
            
            </div>
          </div>
          : null
        }
      </div>
    );
  };
}

export default connect(
  (state, props) => {
    let userId;
    if (props?.match?.params?.id !== undefined) {
      userId = _.parseInt(props.match.params.id);
    }
    
    return {
      user: _.find(state.user.list, user => user.id === userId),
      
      editedUser: state.user.editedUser,
      editUserProcess: state.user.editUserProcess,
      
      passwordUser: state.user.passwordUser,
      passwordUserProcess: state.user.passwordUserProcess
    };
  },
  {
    getUserById: (userId) => getUserById(userId),
    clearStateEditUser: () => clearStateEditUser(),
    editUser: (userId, userData) => editUser(userId, userData),
    
    updatePassword: (userId, userData) => updatePassword(userId, userData),
    clearStatePasswordUser: () => clearStatePasswordUser()
  }
)(UserProfile);

import React, { Component } from 'react';

import "./DialerDestinationProfile.scss";
import connect              from "react-redux/es/connect/connect";
import PropTypes            from "prop-types";
import {
  clearStateDialerDestinationById,
  deleteDialerNumber,
  editDialerDestination,
  getDialerDestinationById
}                           from "../../redux/dialerSlice";
import {
  toOption,
  toParam,
  toSelector
}                           from "../../util/SelectorUtils";
import history              from "../../history";
import _                    from "lodash";
import {
  API_FETCH_DIALER_NUMBERS
}                           from "../../api/Api";
import tokenService         from "../../security/TokenService";
import {
  DIALER_DESTINATION_NUMBER,
  DIALER_SOURCE_NUMBER
}                           from "../../security/Constants";
import Loading              from "../loading/Loading";
import ConfirmationModal    from "../modal/ConfirmationModal";
import Select               from "react-select";
import {
  saveFile
}                           from "../../util/File";
import {
  DialerWCompAuth,
  isDialerW
}                           from "../../security/Authorization";

const $ = require('jquery');
$.DataTable = require('datatables.net-bs5');

const UpdateButton = ({ editDialerDestinationProcess }) => {
  return <div className="row mt-5">
    <div className="col-12">
      {
        (editDialerDestinationProcess === false)
          ? <button type="submit" className="btn btn-primary"><i className="fas fa-redo"></i> Update
          </button>
          : <button className="btn btn-primary" type="button" disabled>
                          <span className="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span> Update
          </button>
      }
    </div>
  </div>;
};
const UpdateButtonAuth = DialerWCompAuth(UpdateButton);

class DialerDestinationProfile extends Component {
  static propTypes = {
    distributions: PropTypes.array,
    
    dialerDestinationProfile: PropTypes.object,
    dialerDestinationProfileLoaded: PropTypes.bool,
    
    editDialerDestinationProcess: PropTypes.bool,
    deleteDialerNumberProcess: PropTypes.bool,
    
    clearStateDialerDestinationById: PropTypes.func.isRequired,
    getDialerDestinationById: PropTypes.func.isRequired,
    editDialerDestination: PropTypes.func.isRequired,
    deleteDialerNumber: PropTypes.func.isRequired,
  };
  
  constructor(props) {
    super(props);
    
    this.tableSourceAPI = undefined;
    this.tableDestinationAPI = undefined;
    
    this.state = {
      deleteModalIsOpen: false,
      dialerNumberId: undefined,
      dialerNumberType: "",
      dialerNumberText: undefined,
      
      dialerDestinationId: undefined,
      distribution: undefined,
      emailNotifications: undefined,
      weekdaysDailyMinutes: undefined,
      weekendDailyMinutes: undefined,
      acd: undefined,
      asr: undefined,
      dailyVariation: undefined,
      dailyFailsStop: undefined,
      g729Enabled: undefined,
      bNoValidation: undefined,
      enabled: undefined,
      sourceFile: undefined,
      destinationFile: undefined
    };
    
    this.toggleModal = this.toggleModal.bind(this);
    this.deleteDialerNumber = this.deleteDialerNumber.bind(this);
    this.deleteDialerNumberButton = this.deleteDialerNumberButton.bind(this);
    
    this.downloadFile = this.downloadFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDistributionChange = this.handleDistributionChange.bind(this);
  }
  
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps?.dialerDestinationProfile?.id !== prevState?.dialerDestinationId) {
      console.log("[[>>> getDerivedStateFromProps]]");
      return {
        ...prevState,
        dialerDestinationId: nextProps.dialerDestinationProfile.id,
        distribution: toOption(nextProps.dialerDestinationProfile.distribution),
        emailNotifications: nextProps.dialerDestinationProfile.emailNotifications,
        weekdaysDailyMinutes: nextProps.dialerDestinationProfile.weekdaysDailyMinutes,
        weekendDailyMinutes: nextProps.dialerDestinationProfile.weekendDailyMinutes,
        acd: nextProps.dialerDestinationProfile.acd,
        asr: nextProps.dialerDestinationProfile.asr,
        dailyVariation: nextProps.dialerDestinationProfile.dailyVariation,
        dailyFailsStop: nextProps.dialerDestinationProfile.dailyFailsStop,
        g729Enabled: nextProps.dialerDestinationProfile.g729Enabled,
        bNoValidation: nextProps.dialerDestinationProfile.bNoValidation,
        enabled: nextProps.dialerDestinationProfile.enabled
      };
    } else {
      return null;
    }
  }
  
  componentDidMount() {
    const { getDialerDestinationById } = this.props;
    
    window.reactHistory = history;
    window.deleteDialerNumberButton = this.deleteDialerNumberButton;
    
    if (this.props?.match?.params?.id !== undefined) {
      const dialerDestinationId = _.parseInt(this.props.match.params.id);
      
      // no matter what fetch Dialer Destination and replace any data previously saved
      getDialerDestinationById(dialerDestinationId);
    }
    
    this.initTable();
  }
  
  componentWillUnmount() {
    const { clearStateDialerDestinationById } = this.props;
    
    if (this.props?.match?.params?.id !== undefined) {
      const dialerDestinationId = _.parseInt(this.props.match.params.id);
      clearStateDialerDestinationById(dialerDestinationId);
    }
    
    this.tableSourceAPI.destroy();
    this.tableDestinationAPI.destroy();
  }
  
  componentDidUpdate(prevProps, prevState) {
    // update Dialer Numbers list table only if user updated the list (new upload / delete)
    if ((prevProps.editDialerDestinationProcess === true && this.props.editDialerDestinationProcess === false)
      || (prevProps.deleteDialerNumberProcess === true && this.props.deleteDialerNumberProcess === false)) {
      console.log(">>>>>!!!!!!!!!!!!!!!!!!!!!!!>>>>>>>>");
      
      // reload both tables just to be sure that the user is seeing fresh data
      this.tableSourceAPI.ajax.reload();
      this.tableDestinationAPI.ajax.reload();
    }
  }
  
  toggleModal() {
    this.setState({
      deleteModalIsOpen: !this.state.deleteModalIsOpen
    });
  }
  
  deleteDialerNumber(dialerNumberId, dialerNumberType) {
    const { deleteDialerNumber } = this.props;
    
    deleteDialerNumber(dialerNumberId, dialerNumberType);
    
    this.toggleModal();
  }
  
  deleteDialerNumberButton(dialerNumberId, dialerNumberType, dialerNumberText) {
    this.setState({
      deleteModalIsOpen: true,
      dialerNumberId: dialerNumberId,
      dialerNumberType: dialerNumberType,
      dialerNumberText: dialerNumberText
    });
  }
  
  handleChange = (event) => {
    const { target } = event;
    let { id } = target;
    let value = target.value;
    
    if (target.type === "file") {
      value = target.files[0];
    } else {
      if (target.type === "checkbox") {
        value = target.checked;
      } else {
        if (target.type === "radio") {
          id = target.name;
          value = target.value;
        } else {
          value = target.value;
        }
      }
    }
    
    this.setState({
      [id]: value,
    });
  };
  
  handleDistributionChange = (distribution) => {
    this.setState({
      distribution
    });
  };
  
  downloadFile(file) {
    saveFile(file);
  }
  
  submitUpdateForm(event) {
    const { editDialerDestination } = this.props;
    event.preventDefault();
    
    // extract Dialer Destination id
    let dialerDestinationId;
    if (this.props?.match?.params?.id !== undefined) {
      dialerDestinationId = _.parseInt(this.props.match.params.id);
    }
    
    if (dialerDestinationId === undefined) {
      console.error("Something is wrong! we don't have the Dialer Destination id at this point!");
      return;
    }
    
    // create an object of formData
    const formData = new FormData();
    
    const dialerDestinationUpdate = new Blob([JSON.stringify({
      distribution: toParam(this.state.distribution),
      emailNotifications: this.state.emailNotifications,
      weekdaysDailyMinutes: this.state.weekdaysDailyMinutes,
      weekendDailyMinutes: this.state.weekendDailyMinutes,
      acd: this.state.acd,
      asr: this.state.asr,
      dailyVariation: this.state.dailyVariation,
      dailyFailsStop: this.state.dailyFailsStop,
      g729Enabled: this.state.g729Enabled,
      bNoValidation: this.state.bNoValidation,
      enabled: this.state.enabled
    })], {
      type: 'application/json'
    });
    
    // update the formData object
    formData.append("dialerDestinationUpdate", dialerDestinationUpdate);
    formData.append("sourceFile", this.state.sourceFile);
    formData.append("destinationFile", this.state.destinationFile);
    
    editDialerDestination(dialerDestinationId, formData);
  }
  
  initTable() {
    // extract Dialer Destination id
    let dialerDestinationId;
    if (this.props?.match?.params?.id !== undefined) {
      dialerDestinationId = _.parseInt(this.props.match.params.id);
    }
    
    if (dialerDestinationId === undefined) {
      console.error("Something is wrong! we don't have the Dialer Destination id at this point!");
      return;
    }
    
    this.$sourceEl = $(this.sourceEl);
    this.tableSourceAPI = this.$sourceEl.DataTable({
      pageLength: 10,
      order: [],
      serverSide: true,
      ajax: {
        url: API_FETCH_DIALER_NUMBERS.interpolate({
          dialerDestinationId,
          type: DIALER_SOURCE_NUMBER
        }),
        headers: {
          Authorization: `Bearer ${tokenService.getUser()?.token}`,
          sessionId: tokenService.getUser()?.sessionId
        },
        data: function (formData) {
          return {
            draw: formData.draw,
            start: formData.start,
            length: formData.length,
            search: formData.search.value
          };
        },
        error: function (xhr, error, code) {
          // do nothing;
        }
      },
      columns: [
        { title: "Dialer Source Number", data: "dialerNumber", orderable: false },
        {
          data: null,
          title: 'Actions',
          wrap: true,
          width: "105px",
          render: function (item) {
            if (isDialerW()) {
              return '<div class="btn-group"> ' +
                '<a onclick="window.deleteDialerNumberButton(' + item.id + ', \'' + DIALER_SOURCE_NUMBER + '\', \'' + item.dialerNumber + '\')" class="dialer-number-actions">' +
                '<button type="button" class="btn btn-outline-danger"><i class="fas fa-trash"></i> Delete</button>' +
                '</a>' +
                '</div>';
            }
            return '<div class="btn-group"> ' +
              '</div>';
          },
          orderable: false
        }
      ]
    });
    
    this.$destinationEl = $(this.destinationEl);
    this.tableDestinationAPI = this.$destinationEl.DataTable({
      pageLength: 10,
      order: [],
      serverSide: true,
      ajax: {
        url: API_FETCH_DIALER_NUMBERS.interpolate({
          dialerDestinationId,
          type: DIALER_DESTINATION_NUMBER
        }),
        headers: {
          Authorization: `Bearer ${tokenService.getUser()?.token}`,
          sessionId: tokenService.getUser()?.sessionId
        },
        data: function (formData) {
          return {
            draw: formData.draw,
            start: formData.start,
            length: formData.length,
            search: formData.search.value
          };
        },
        error: function (xhr, error, code) {
          // do nothing;
        }
      },
      columns: [
        { title: "Dialer Destination Number", data: "dialerNumber", orderable: false },
        {
          data: null,
          title: 'Actions',
          wrap: true,
          width: "105px",
          render: function (item) {
            if (isDialerW()) {
              return '<div class="btn-group"> ' +
                '<a onclick="window.deleteDialerNumberButton(' + item.id + ', \'' + DIALER_DESTINATION_NUMBER + '\', \'' + item.dialerNumber + '\')" class="dialer-number-actions">' +
                '<button type="button" class="btn btn-outline-danger"><i class="fas fa-trash"></i> Delete</button>' +
                '</a>' +
                '</div>';
            }
            return '<div class="btn-group"> ' +
              '</div>';
          },
          orderable: false
        }
      ]
    });
  }
  
  render() {
    const {
      distribution,
      emailNotifications,
      weekdaysDailyMinutes,
      weekendDailyMinutes,
      acd,
      asr,
      dailyVariation,
      dailyFailsStop,
      g729Enabled,
      bNoValidation,
      enabled
    } = this.state;
    const {
      distributions,
      dialerDestinationProfile,
      dialerDestinationProfileLoaded,
      editDialerDestinationProcess
    } = this.props;
    
    return (
      <div className="distribution-profile section">
        
        <div className="section-title">
          <h2>Edit Dialer Destination Campaign</h2>
        </div>
        
        <Loading display={!dialerDestinationProfileLoaded}/>
        
        <ConfirmationModal
          isOpen={this.state.deleteModalIsOpen}
          title={"Dialer " + this.state.dialerNumberType.capitalize() + " Campaign Number: " + this.state.dialerNumberText}
          content={"<p>Are you sure you want to delete this Dialer " + this.state.dialerNumberType.capitalize() + " Campaign Number?</p>"}
          actionText="Delete"
          toggle={this.toggleModal}
          doAction={() => this.deleteDialerNumber(this.state.dialerNumberId, this.state.dialerNumberType)}/>
        
        {
          (dialerDestinationProfile.dialerDestinationName !== undefined)
            ? <div>
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Dialer Destination
                    Name: {dialerDestinationProfile.dialerDestinationName} </h5>
                  <p className="card-text">Created
                    By: {dialerDestinationProfile.createdBy} on {new Date(dialerDestinationProfile.createdDate).toLocaleDateString()} date</p>
                </div>
              </div>
              
              <hr className="bg-info m-4 border-info"/>
              
              <div className="row">
                <div className="col-12">
                  <form onSubmit={(event) => this.submitUpdateForm(event)}>
                    
                    <div className="row">
                      <div className="col-6">
                        <label htmlFor="distribution" className="form-label">Distribution</label>
                        <Select
                          id="distribution"
                          name="distribution"
                          options={toSelector(distributions)}
                          value={distribution}
                          classNamePrefix="select"
                          onChange={(event) => this.handleDistributionChange(event)}/>
                      </div>
                      
                      <div className="col-6">
                        <label htmlFor="emailNotifications" className="form-label">Email Notifications</label>
                        <input type="text" className="form-control" name="emailNotifications" id="emailNotifications"
                               placeholder="email addresses"
                               value={emailNotifications}
                               onChange={(event) => this.handleChange(event)} required/>
                        <div className="form-text">Please provide a comma separated list of emails</div>
                      </div>
                    
                    </div>
                    
                    <div className="row mt-3">
                      <div className="col-6">
                        <label htmlFor="weekdaysDailyMinutes" className="form-label">Weekdays Daily Minutes</label>
                        <input type="number" className="form-control" name="weekdaysDailyMinutes"
                               id="weekdaysDailyMinutes"
                               placeholder="minutes"
                               value={weekdaysDailyMinutes}
                               onChange={(event) => this.handleChange(event)} required/>
                      </div>
                      
                      <div className="col-6">
                        <label htmlFor="weekendDailyMinutes" className="form-label">Weekend Daily Minutes</label>
                        <input type="number" className="form-control" name="weekendDailyMinutes" id="weekendDailyMinutes"
                               placeholder="minutes"
                               value={weekendDailyMinutes}
                               onChange={(event) => this.handleChange(event)} required/>
                      </div>
                    </div>
                    
                    <div className="row mt-3">
                      <div className="col-4">
                        <label htmlFor="acd" className="form-label">ACD</label>
                        <input type="number" className="form-control" name="acd" id="acd"
                               placeholder="acd"
                               value={acd}
                               onChange={(event) => this.handleChange(event)} required/>
                      </div>
                      
                      <div className="col-4">
                        <label htmlFor="asr" className="form-label">ASR</label>
                        <input type="number" className="form-control" name="asr" id="asr"
                               placeholder="asr"
                               value={asr}
                               onChange={(event) => this.handleChange(event)} required/>
                      </div>
                      
                      <div className="col-4">
                        <label htmlFor="dailyVariation" className="form-label">Daily Variation</label>
                        <input type="number" className="form-control" name="dailyVariation" id="dailyVariation"
                               placeholder="variation"
                               value={dailyVariation}
                               onChange={(event) => this.handleChange(event)} required/>
                      </div>
                    </div>
                    
                    <div className="row mt-3">
                      <div className="col-3">
                        <label htmlFor="dailyFailsStop" className="form-label">Daily Fails Stop</label>
                        <input type="number" className="form-control" name="dailyFailsStop" id="dailyFailsStop"
                               placeholder="fails"
                               value={dailyFailsStop}
                               onChange={(event) => this.handleChange(event)} required/>
                      </div>
                      
                      <div className="col-3 d-flex justify-content-center">
                        <div className="mb-3">
                          <label htmlFor="g729Enabled" className="form-label">G729 Enabled</label>
                          <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" id="g729Enabled"
                                   checked={g729Enabled ? g729Enabled : false}
                                   onChange={(event) => this.handleChange(event)}/>
                          </div>
                        </div>
                      </div>
                      
                      <div className="col-3 d-flex justify-content-center">
                        <div className="mb-3">
                          <label htmlFor="bNoValidation" className="form-label">B-number only Validation</label>
                          <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" id="bNoValidation"
                                   checked={bNoValidation ? bNoValidation : false}
                                   onChange={(event) => this.handleChange(event)}/>
                          </div>
                        </div>
                      </div>
                      
                      <div className="col-3 d-flex justify-content-center">
                        <div className="mb-3">
                          <label htmlFor="enabled" className="form-label">Enabled</label>
                          <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" id="enabled"
                                   checked={enabled ? enabled : false}
                                   onChange={(event) => this.handleChange(event)}/>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="row mt-3">
                      <div className="col-6">
                        <label htmlFor="sourceFile" className="form-label">Upload Dialer Source Numbers</label>
                        <input className="form-control" type="file" name="sourceFile" id="sourceFile"
                               onChange={(event) => this.handleChange(event)}/>
                        <div className="form-text">(Optional) Upload an Excel document - this can be edited later</div>
                      </div>
                      
                      <div className="col-6">
                        <label htmlFor="destinationFile" className="form-label">Upload Dialer Destination Numbers</label>
                        <input className="form-control" type="file" name="destinationFile" id="destinationFile"
                               onChange={(event) => this.handleChange(event)}/>
                        <div className="form-text">(Optional) Upload an Excel document - this can be edited later</div>
                      </div>
                    </div>
                    
                    <UpdateButtonAuth editDialerDestinationProcess={editDialerDestinationProcess}/>
                  
                  </form>
                  
                  {(!_.isEmpty(dialerDestinationProfile.errors))
                    ? <div className="alert alert-danger login-error" role="alert">
                      {
                        dialerDestinationProfile.errors.map(error => <div key={error.fieldName + error.message}>
                          {
                            (error.fieldName !== undefined)
                              ? error.fieldName + " - "
                              : null
                          }
                          {error.message}
                        </div>)
                      }
                    </div>
                    : null
                  }
                
                </div>
              </div>
              
              <hr className="bg-info m-4 border-info"/>
            </div>
            : null
        }
        
        {
          (!_.isEmpty(dialerDestinationProfile.sourceFiles) || !_.isEmpty(dialerDestinationProfile.destinationFiles))
            ? <div className="row">
              <div className="col-12">
                <h5 className="download"><i className="fas fa-download"></i> Download previously uploaded files</h5>
              </div>
              
              {
                (!_.isEmpty(dialerDestinationProfile.sourceFiles))
                  ? <div className="col-6">
                    <div className="table-responsive">
                      <table className="table align-middle">
                        <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Uploaded By</th>
                          <th scope="col">Uploaded Date</th>
                          <th scope="col">Download</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                          dialerDestinationProfile.sourceFiles.map(file => <tr key={file.id}>
                            <td><a aria-label="Download file" data-cooltipz-dir="right" href="" onClick={(event) => {
                              event.preventDefault();
                              this.downloadFile(file);
                            }}>{file.name}</a></td>
                            <td>{file.createdBy}</td>
                            <td>{new Date(file.createdDate).toLocaleDateString()}</td>
                            <td>
                              <button type="button" className="btn btn-primary" onClick={() => this.downloadFile(file)}>
                                <i className="fas fa-file-excel"></i> Download
                              </button>
                            </td>
                          </tr>)
                        }
                        </tbody>
                      </table>
                    </div>
                  </div>
                  : <div className="col-6"></div>
              }
              
              {
                (!_.isEmpty(dialerDestinationProfile.destinationFiles))
                  ? <div className="col-6">
                    <div className="table-responsive">
                      <table className="table align-middle">
                        <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Uploaded By</th>
                          <th scope="col">Uploaded Date</th>
                          <th scope="col">Download</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                          dialerDestinationProfile.destinationFiles.map(file => <tr key={file.id}>
                            <td><a aria-label="Download file" data-cooltipz-dir="right" href="" onClick={(event) => {
                              event.preventDefault();
                              this.downloadFile(file);
                            }}>{file.name}</a></td>
                            <td>{file.createdBy}</td>
                            <td>{new Date(file.createdDate).toLocaleDateString()}</td>
                            <td>
                              <button type="button" className="btn btn-primary" onClick={() => this.downloadFile(file)}>
                                <i className="fas fa-file-excel"></i> Download
                              </button>
                            </td>
                          </tr>)
                        }
                        </tbody>
                      </table>
                    </div>
                  </div>
                  : <div className="col-6"></div>
              }
              
              <div className="col-12">
                <hr className="bg-info m-4 border-info"/>
              </div>
            </div>
            : null
        }
        
        <div className="row mt-4">
          <div className="col-6 d-flex justify-content-center">
            <h6>Dialer Source Numbers</h6>
          </div>
          
          <div className="col-6 d-flex justify-content-center">
            <h6>Dialer Destination Numbers</h6>
          </div>
        </div>
        
        <div className="row">
          <div className="col-6">
            <table className="table table-hover" ref={el => this.sourceEl = el}>
            </table>
          </div>
          
          <div className="col-6">
            <table className="table table-hover" ref={el => this.destinationEl = el}>
            </table>
          </div>
        </div>
      
      </div>
    );
  };
}

export default connect(
  (state, props) => {
    return {
      distributions: state.metadata.list["distribution"],
      
      dialerDestinationProfile: state.dialer.dialerDestinationProfile,
      dialerDestinationProfileLoaded: state.dialer.dialerDestinationProfileLoaded,
      
      editDialerDestinationProcess: state.dialer.editDialerDestinationProcess,
      deleteDialerNumberProcess: state.dialer.deleteDialerNumberProcess
    };
  },
  {
    clearStateDialerDestinationById: (dialerDestinationId) => clearStateDialerDestinationById(dialerDestinationId),
    getDialerDestinationById: (dialerDestinationId) => getDialerDestinationById(dialerDestinationId),
    editDialerDestination: (dialerDestinationId, dialerDestinationData) => editDialerDestination(dialerDestinationId, dialerDestinationData),
    deleteDialerNumber: (dialerNumberId, dialerNumberType) => deleteDialerNumber(dialerNumberId, dialerNumberType),
  }
)(DialerDestinationProfile);

import React, { Component }         from 'react';

import "./CliList.scss";
import { Link }                     from "react-router-dom";
import connect                      from "react-redux/es/connect/connect";
import PropTypes                    from "prop-types";
import { deleteCli, getListOfClis } from "../../redux/cliSlice";
import history                      from "../../history";
import _                            from "lodash";
import Loading                      from "../loading/Loading";
import ConfirmationModal            from "../modal/ConfirmationModal";
import { CliWCompAuth, isCliW }     from "../../security/Authorization";

const $ = require('jquery');
$.DataTable = require('datatables.net-bs5');

const AddButton = () => {
  return <div className="row mt-5">
    <div className="col-12">
      <Link to="/admin/cli/add/cli">
        <button type="button" className="btn btn-lg btn-outline-success">
          <i className="fas fa-plus-circle"></i> Add New CLI Campaign
        </button>
      </Link>
    </div>
  </div>;
};
const AddButtonAuth = CliWCompAuth(AddButton);

class CliList extends Component {
  static propTypes = {
    cliList: PropTypes.array,
    listLoaded: PropTypes.bool,
    
    getListOfClis: PropTypes.func.isRequired,
    deleteCli: PropTypes.func.isRequired,
  };
  
  constructor(props) {
    super(props);
    
    this.tableAPI = undefined;
    
    this.state = {
      deleteModalIsOpen: false,
      cliId: undefined,
      cliText: undefined
    };
    
    this.toggleModal = this.toggleModal.bind(this);
    this.deleteCli = this.deleteCli.bind(this);
    this.deleteCliButton = this.deleteCliButton.bind(this);
  }
  
  componentDidMount() {
    const { cliList, listLoaded, getListOfClis } = this.props;
    window.reactHistory = history;
    window.deleteCliButton = this.deleteCliButton;
    
    if (listLoaded === false || _.isEmpty(cliList)) {
      getListOfClis();
    }
    
    this.initTable();
  }
  
  componentWillUnmount() {
    this.tableAPI.destroy();
  }
  
  componentDidUpdate(prevProps, prevState) {
    // update CLI list table only if we receive a new list
    if (!_.isEqual(prevProps.cliList, this.props.cliList)) {
      this.updateTable();
    }
  }
  
  toggleModal() {
    this.setState({
      deleteModalIsOpen: !this.state.deleteModalIsOpen
    });
  }
  
  deleteCli(cliId) {
    const { deleteCli } = this.props;
    deleteCli(cliId);
    this.toggleModal();
  }
  
  deleteCliButton(cliId, campaignName) {
    this.setState({
      deleteModalIsOpen: true,
      cliId: cliId,
      cliText: campaignName
    });
  }
  
  updateTable() {
    const { cliList, listLoaded } = this.props;
    
    if (listLoaded === true) {
      this.tableAPI.clear();
      this.tableAPI.rows.add(cliList);
      this.tableAPI.draw();
    }
  }
  
  initTable() {
    const { cliList } = this.props;
    
    this.$el = $(this.el);
    this.tableAPI = this.$el.DataTable({
      data: cliList,
      pageLength: 10,
      order: [],
      columns: [
        { title: "Campaign Id", data: "campaignId" },
        { title: "Campaign Name", data: "campaignName" },
        {
          data: "createdDate",
          title: 'Created Date',
          wrap: true,
          render: function (item) {
            const date = new Date(item);
            return date.toLocaleDateString();
          },
        },
        { title: "Created By", data: "createdBy" },
        {
          data: null,
          title: 'Actions',
          wrap: true,
          width: "315px",
          render: function (item) {
            const campaignName = item.campaignName.replace(/'/g, "\\'");
            
            if (isCliW()) {
              return '<div class="btn-group"> ' +
                '<a onclick="window.reactHistory.push(\'/admin/cli/edit/' + item.id + '\')" class="cli-actions">' +
                '<button type="button" class="btn btn-outline-info"><i class="fas fa-edit"></i> Edit</button>' +
                '</a>' +
                '<a onclick="window.reactHistory.push(\'/admin/cli/clone/' + item.id + '\')" class="cli-actions">' +
                '<button type="button" class="btn btn-outline-warning"><i class="fas fa-clone"></i> Clone</button>' +
                '</a>' +
                '<a onclick="window.deleteCliButton(' + item.id + ', \'' + campaignName + '\')" class="cli-actions">' +
                '<button type="button" class="btn btn-outline-danger"><i class="fas fa-trash"></i> Delete</button>' +
                '</a>' +
                '</div>';
            }
            
            return '<div class="btn-group"> ' +
              '<a onclick="window.reactHistory.push(\'/admin/cli/edit/' + item.id + '\')" class="cli-actions">' +
              '<button type="button" class="btn btn-outline-info"><i class="fas fa-eye"></i> View</button>' +
              '</a>' +
              '</div>';
          },
          orderable: false
        }
      ]
    });
  }
  
  render() {
    const { listLoaded } = this.props;
    
    return (
      <div className="list-of-cli section">
        
        <div className="section-title">
          <h2>List of CLI Campaigns</h2>
        </div>
        
        <Loading display={!listLoaded}/>
        
        <ConfirmationModal
          isOpen={this.state.deleteModalIsOpen}
          title={"CLI Campaign: " + this.state.cliText}
          content="<p>Are you sure you want to delete this CLI Campaign? <br/> <b>All associated data will be lost!</b></p>"
          actionText="Delete"
          toggle={this.toggleModal}
          doAction={() => this.deleteCli(this.state.cliId)}/>
        
        <div className="row">
          <div className="col-12">
            <table className="table table-hover" ref={el => this.el = el}>
            </table>
          </div>
        </div>
        
        <AddButtonAuth/>
      
      </div>
    );
  };
}

export default connect(
  (state) => {
    return {
      cliList: state.cli.list,
      listLoaded: state.cli.listLoaded
    };
  },
  {
    getListOfClis: () => getListOfClis(),
    deleteCli: (cliId) => deleteCli(cliId),
  }
)(CliList);


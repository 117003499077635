import "./CloneCli.scss";
import connect                                        from "react-redux/es/connect/connect";
import React, { Component }                           from "react";
import { clearStateCreatedCli, cloneCli, getCliById } from "../../redux/cliSlice";
import _                                              from "lodash";
import PropTypes                                      from "prop-types";
import Loading                                        from "../loading/Loading";

class CloneCli extends Component {
  static propTypes = {
    cli: PropTypes.object,
    cliChangeLoaded: PropTypes.bool,
    
    createdCli: PropTypes.object,
    createCliProcess: PropTypes.bool,
    
    getCliById: PropTypes.func.isRequired,
    cloneCli: PropTypes.func.isRequired,
    clearStateCreatedCli: PropTypes.func.isRequired,
  };
  
  constructor(props) {
    super(props);
    
    this.state = {
      campaignId: "",
      campaignName: "",
    };
    
    this.handleChange = this.handleChange.bind(this);
  }
  
  componentWillUnmount() {
    const { clearStateCreatedCli } = this.props;
    clearStateCreatedCli();
  }
  
  handleChange = (event) => {
    const { target } = event;
    let { id } = target;
    let value = target.value;
    
    if (target.type === "checkbox") {
      value = target.checked;
    } else {
      if (target.type === "radio") {
        id = target.name;
        value = target.value;
      } else {
        value = target.value;
      }
    }
    
    this.setState({
      [id]: value,
    });
  };
  
  submitForm(event) {
    const { cloneCli } = this.props;
    
    event.preventDefault();
    
    // extract CLI id
    let cliId;
    if (this.props?.match?.params?.id !== undefined) {
      cliId = _.parseInt(this.props.match.params.id);
    }
    if (cliId === undefined) {
      console.error("Something is wrong! we don't have the CLI id at this point!");
      return;
    }
    
    cloneCli(cliId, this.state);
  }
  
  componentDidMount() {
    const { cli, cliChangeLoaded, getCliById } = this.props;
    
    if (this.props?.match?.params?.id !== undefined) {
      const cliId = _.parseInt(this.props.match.params.id);
      if (cliChangeLoaded === false || cli === undefined) {
        getCliById(cliId);
      }
    }
  }
  
  render() {
    const { campaignId, campaignName } = this.state;
    const { cli, cliChangeLoaded, createdCli, createCliProcess } = this.props;
    
    return (
      <div className="cli-clone section">
        <div className="section-title">
          <h2>Clone CLI Campaign</h2>
        </div>
        
        <Loading display={!cliChangeLoaded}/>
        
        <div className="alert alert-warning" role="alert">
          <i className="fas fa-exclamation-circle"></i> All the content of the bellow CLI will be duplicated.
          Depending on the size of the CLI this might take a while.
        </div>
        
        {
          (cli !== undefined)
            ? <div className="card">
              <div className="card-body">
                <h5 className="card-title">Cloning CLI with Unique Id: {cli.campaignId} -- CLI
                  Name: {cli.campaignName}</h5>
                <p className="card-text">Created
                  By: {cli.createdBy} on {new Date(cli.createdDate).toLocaleDateString()} date</p>
              </div>
            </div>
            : null
        }
        
        {
          (cli !== undefined)
            ? <div className="row">
              <div className="col-12">
                <form onSubmit={(event) => this.submitForm(event)}>
                  
                  <div className="row mt-3">
                    <div className="col-6">
                      <label htmlFor="campaignId" className="form-label">Campaign Id</label>
                      <input type="number" className="form-control" name="campaignId" id="campaignId"
                             placeholder="campaign id"
                             value={campaignId}
                             onChange={(event) => this.handleChange(event)} required/>
                      <div className="form-text">Please provide a unique number with exactly 5 digits</div>
                    </div>
                    
                    <div className="col-6">
                      <label htmlFor="campaignName" className="form-label">Campaign Name</label>
                      <input type="text" className="form-control" name="campaignName" id="campaignName"
                             placeholder="campaign name"
                             value={campaignName}
                             onChange={(event) => this.handleChange(event)} required/>
                      <div className="form-text">Please provide a unique name</div>
                    </div>
                  
                  </div>
                  
                  
                  <div className="row mt-5">
                    <div className="col-12">
                      {
                        (createCliProcess === false)
                          ?
                          <button type="submit" className="btn btn-warning"><i className="fas fa-clone"></i> Clone
                            CLI
                            Campaign
                          </button>
                          : <button className="btn btn-warning" type="button" disabled>
                            <span className="spinner-border spinner-border-sm" role="status"
                                  aria-hidden="true"></span> Clone
                            CLI
                            Campaign
                          </button>
                      }
                    </div>
                  </div>
                
                </form>
                
                {(!_.isEmpty(createdCli.errors))
                  ? <div className="alert alert-danger login-error" role="alert">
                    {
                      createdCli.errors.map(error => <div key={error.fieldName + error.message}>
                        {
                          (error.fieldName !== undefined)
                            ? error.fieldName + " - "
                            : null
                        }
                        {error.message}
                      </div>)
                    }
                  </div>
                  : null
                }
              </div>
            </div>
            : null
        }
      
      
      </div>
    );
  }
}

export default connect(
  (state, props) => {
    let cliId;
    if (props?.match?.params?.id !== undefined) {
      cliId = _.parseInt(props.match.params.id);
    }
    
    return {
      cli: state.cli.cliChangeList[cliId],
      cliChangeLoaded: state.cli.cliChangeLoaded[cliId] !== undefined ? state.cli.cliChangeLoaded[cliId] : false,
      
      createdCli: state.cli.createdCli,
      createCliProcess: state.cli.createCliProcess
    };
  },
  {
    getCliById: (cliId) => getCliById(cliId),
    cloneCli: (cliId, cliData) => cloneCli(cliId, cliData),
    clearStateCreatedCli: () => clearStateCreatedCli()
  }
)(CloneCli);

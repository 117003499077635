import React, { Component }                            from 'react';

import "./AddRecording.scss";
import connect                                         from "react-redux/es/connect/connect";
import PropTypes                                       from "prop-types";
import _                                               from "lodash";
import { clearStateCreatedRecording, createRecording } from "../../redux/recordingSlice";

class AddRecording extends Component {
  static propTypes = {
    createdRecording: PropTypes.object,
    createRecordingProcess: PropTypes.bool,
    
    createRecording: PropTypes.func.isRequired,
    clearStateCreatedRecording: PropTypes.func.isRequired,
  };
  
  constructor(props) {
    super(props);
    
    this.state = {
      recordingId: "",
      recordingName: "",
      numberOfDays: "",
    };
    
    this.handleChange = this.handleChange.bind(this);
  }
  
  componentWillUnmount() {
    const { clearStateCreatedRecording } = this.props;
    clearStateCreatedRecording();
  }
  
  handleChange = (event) => {
    const { target } = event;
    let { id } = target;
    let value = target.value;
    
    if (target.type === "checkbox") {
      value = target.checked;
    } else {
      if (target.type === "radio") {
        id = target.name;
        value = target.value;
      } else {
        value = target.value;
      }
    }
    
    this.setState({
      [id]: value,
    });
  };
  
  submitForm(event) {
    const { createRecording } = this.props;
    event.preventDefault();
    
    createRecording(this.state);
  }
  
  render() {
    const { recordingId, recordingName, numberOfDays } = this.state;
    const { createdRecording, createRecordingProcess } = this.props;
    
    return (
      <div className="add-recording section">
        
        <div className="section-title">
          <h2>Add new Recording GW</h2>
        </div>
        
        <div className="row">
          <div className="col-12">
            
            <form onSubmit={(event) => this.submitForm(event)}>
              
              <div className="row mt-3">
                <div className="col-6">
                  <label htmlFor="recordingId" className="form-label">Recording GW Id</label>
                  <input type="number" min={10000} max={99999} className="form-control" name="recordingId"
                         id="recordingId"
                         placeholder="recording id"
                         value={recordingId}
                         onChange={(event) => this.handleChange(event)} required/>
                  <div className="form-text">Please provide a unique number with exactly 5 digits</div>
                </div>
                
                <div className="col-6">
                  <label htmlFor="recordingName" className="form-label">Recording GW Name</label>
                  <input type="text" className="form-control" name="recordingName" id="recordingName"
                         placeholder="recording name"
                         value={recordingName}
                         onChange={(event) => this.handleChange(event)} required/>
                  <div className="form-text">Please provide a unique name</div>
                </div>
              </div>
              
              <div className="row mt-3">
                <div className="col-4">
                  <label htmlFor="numberOfDays" className="form-label">Number of Days</label>
                  <input type="number" min={1} className="form-control" name="numberOfDays" id="numberOfDays"
                         placeholder="number of days"
                         value={numberOfDays}
                         onChange={(event) => this.handleChange(event)} required/>
                </div>
              </div>
              
              <div className="row mt-5">
                <div className="col-12">
                  {
                    (createRecordingProcess === false)
                      ?
                      <button type="submit" className="btn btn-primary"><i className="fas fa-save"></i> Create Recording
                        GW
                      </button>
                      : <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Create
                        Recording GW
                      </button>
                  }
                </div>
              </div>
            
            </form>
            
            {(!_.isEmpty(createdRecording.errors))
              ? <div className="alert alert-danger login-error" role="alert">
                {
                  createdRecording.errors.map(error => <div key={error.fieldName + error.message}>
                    {
                      (error.fieldName !== undefined)
                        ? error.fieldName + " - "
                        : null
                    }
                    {error.message}
                  </div>)
                }
              </div>
              : null
            }
          
          </div>
        </div>
      
      </div>
    );
  };
}

export default connect(
  (state) => {
    return {
      createdRecording: state.recording.createdRecording,
      createRecordingProcess: state.recording.createRecordingProcess
    };
  },
  {
    createRecording: (recordingData) => createRecording(recordingData),
    clearStateCreatedRecording: () => clearStateCreatedRecording()
  }
)(AddRecording);


import { createSlice } from "@reduxjs/toolkit";
import _               from "lodash";
import {
  addDialer,
  addDialerDestination,
  cloneDialerById,
  deleteDialerById,
  deleteDialerDestinationById,
  deleteDialerNumberById,
  editDialerDestinationById,
  fetchDialerById,
  fetchDialerDestinationById,
  fetchListOfDialers,
  resetDialerDestinationById,
  testDialerDestinationById
}                      from "../api/Api";
import history         from "../history";
import { toast }       from "react-toastify";

const initialState = {
  list: [],             // list of Dialers
  listLoaded: false,
  
  createdDialer: {},       // new Dialer entity/data
  createDialerProcess: false,
  
  dialerDestinationList: {},    // Map of <Id, Dialer>
  dialerDestinationLoaded: {},
  
  createdDialerDestination: {}, // new Dialer Destination entity/data
  createDialerDestinationProcess: false,
  
  dialerDestinationProfile: {}, // edit Dialer Destination entity (this is separate state since we need to load this every time)
  dialerDestinationProfileLoaded: false,
  
  editDialerDestinationProcess: false,
  deleteDialerNumberProcess: false,
};

export const dialerSlice = createSlice({
  name: 'dialer',
  initialState,
  reducers: {
    /*****************************************************************************************
     * Dialer Reducers
     *****************************************************************************************/
    clearCreatedDialer: (state, action) => {
      state.createdDialer = {};
    },
    
    createDialerInvoked: (state, action) => {
      state.createDialerProcess = true;
    },
    
    createDialerSuccess: (state, action) => {
      state.createdDialer = action.payload;
      state.list = [action.payload, ...state.list];
      state.createDialerProcess = false;
    },
    
    createDialerFailure: (state, action) => {
      // check if we have validation error or any other type of errors
      let errors;
      if (action.payload.errors === undefined) {
        errors = [];
        errors.push(action.payload);
      } else {
        errors = action.payload.errors;
      }
      
      state.createdDialer.errors = errors;
      state.createDialerProcess = false;
    },
    
    deleteDialerSuccess: (state, action) => {
      const dialerId = action.payload;
      state.list = _.filter(state.list, dialer => dialer.id !== dialerId);
    },
    
    fetchDialers: (state, action) => {
      state.list = action.payload;
      state.listLoaded = true;
    },
    
    fetchDialerInvoked: (state, action) => {
      const dialerId = action.payload;
      state.dialerDestinationLoaded[dialerId] = false;
    },
    
    fetchDialerFailure: (state, action) => {
      const dialerId = action.payload;
      state.dialerDestinationLoaded[dialerId] = false;
    },
    
    fetchDialerSuccess: (state, action) => {
      const dialerId = action.payload.id;
      state.dialerDestinationList[dialerId] = action.payload;
      state.dialerDestinationLoaded[dialerId] = true;
    },
    
    /*****************************************************************************************
     * Dialer Destination Reducers
     *****************************************************************************************/
    
    clearCreateDialerDestination: (state, action) => {
      state.createdDialerDestination = {};
    },
    
    createDialerDestinationInvoked: (state, action) => {
      state.createDialerDestinationProcess = true;
    },
    
    createDialerDestinationSuccess: (state, action) => {
      const { dialerId, data } = action.payload;
      state.createdDialerDestination = data;
      state.createDialerDestinationProcess = false;
      
      if (state.dialerDestinationList[dialerId] === undefined) {
        state.dialerDestinationList[dialerId] = {
          dialerDestinationCampaigns: []
        };
      }
      
      state.dialerDestinationList[dialerId].dialerDestinationCampaigns = [data, ...state.dialerDestinationList[dialerId].dialerDestinationCampaigns];
    },
    
    createDialerDestinationFailure: (state, action) => {
      // check if we have validation error or any other type of errors
      let errors;
      if (action.payload.errors === undefined) {
        errors = [];
        errors.push(action.payload);
      } else {
        errors = action.payload.errors;
      }
      
      state.createdDialerDestination.errors = errors;
      state.createDialerDestinationProcess = false;
    },
    
    editDialerDestinationInvoked: (state, action) => {
      state.editDialerDestinationProcess = true;
    },
    
    editDialerDestinationSuccess: (state, action) => {
      state.dialerDestinationProfile = action.payload;
      state.editDialerDestinationProcess = false;
      
      // update the list of Dialers
      _.forEach(state.dialerDestinationList, function (value, key) {
        const dialerDestination = _.find(value.dialerDestinationCampaigns, item => item.id === action.payload.id);
        
        if (dialerDestination !== undefined) {
          dialerDestination.distribution = action.payload.distribution;
          dialerDestination.emailNotifications = action.payload.emailNotifications;
          dialerDestination.weekdaysDailyMinutes = action.payload.weekdaysDailyMinutes;
          dialerDestination.weekendDailyMinutes = action.payload.weekendDailyMinutes;
          dialerDestination.acd = action.payload.acd;
          dialerDestination.asr = action.payload.asr;
          dialerDestination.dailyVariation = action.payload.dailyVariation;
          dialerDestination.dailyFailsStop = action.payload.dailyFailsStop;
          dialerDestination.g729Enabled = action.payload.g729Enabled;
          dialerDestination.bNoValidation = action.payload.bNoValidation;
          dialerDestination.enabled = action.payload.enabled;
        }
      });
    },
    
    editDialerDestinationFailure: (state, action) => {
      // check if we have validation error or any other type of errors
      let errors;
      if (action.payload.errors === undefined) {
        errors = [];
        errors.push(action.payload);
      } else {
        errors = action.payload.errors;
      }
      
      state.dialerDestinationProfile.errors = errors;
      state.editDialerDestinationProcess = false;
    },
    
    resetDialerDestinationSuccess: (state, action) => {
      state.dialerDestinationProfile = action.payload;
      
      // update the list of Dialers
      _.forEach(state.dialerDestinationList, function (value, key) {
        const dialerDestination = _.find(value.dialerDestinationCampaigns, item => item.id === action.payload.id);
        
        if (dialerDestination !== undefined) {
          dialerDestination.generatedMinutesNo = action.payload.generatedMinutesNo;
          dialerDestination.totalAttempts = action.payload.totalAttempts;
          dialerDestination.totalDialerAnswered = action.payload.totalDialerAnswered;
          dialerDestination.totalDialerRejects = action.payload.totalDialerRejects;
          dialerDestination.totalWronglyAnswered = action.payload.totalWronglyAnswered;
          dialerDestination.totalCarrierRejects = action.payload.totalCarrierRejects;
        }
      });
    },
    
    deleteDialerDestinationSuccess: (state, action) => {
      const { dialerId, dialerDestinationId } = action.payload;
      
      state.dialerDestinationList[dialerId].dialerDestinationCampaigns = _.filter(
        state.dialerDestinationList[dialerId].dialerDestinationCampaigns,
        dialerDestination => dialerDestination.id !== dialerDestinationId);
    },
    
    fetchDialerDestinationInvoked: (state, action) => {
      state.dialerDestinationProfile = {};
      state.dialerDestinationProfileLoaded = false;
    },
    
    fetchDialerDestinationSuccess: (state, action) => {
      state.dialerDestinationProfile = action.payload;
      state.dialerDestinationProfileLoaded = true;
    },
    
    deleteDialerNumberInvoked: (state, action) => {
      state.deleteDialerNumberProcess = true;
    },
    
    deleteDialerNumberSuccess: (state, action) => {
      state.deleteDialerNumberProcess = false;
    },
    
  },
  
  extraReducers: (builder) => {
    builder
      .addCase("user/logoutSuccess", (state, action) => {
        return initialState;
      });
  }
});

export const {
  clearCreatedDialer,
  createDialerInvoked,
  createDialerSuccess,
  createDialerFailure,
  deleteDialerSuccess,
  fetchDialers,
  fetchDialerInvoked,
  fetchDialerSuccess,
  fetchDialerFailure,
  
  clearCreateDialerDestination,
  createDialerDestinationInvoked,
  createDialerDestinationSuccess,
  createDialerDestinationFailure,
  editDialerDestinationInvoked,
  editDialerDestinationSuccess,
  editDialerDestinationFailure,
  resetDialerDestinationSuccess,
  deleteDialerDestinationSuccess,
  fetchDialerDestinationInvoked,
  fetchDialerDestinationSuccess,
  
  deleteDialerNumberInvoked,
  deleteDialerNumberSuccess
} = dialerSlice.actions;

/*****************************************************************************************
 * Dialer Actions
 *****************************************************************************************/

export const getListOfDialers = () => async (dispatch, getState) => {
  try {
    const { data } = await fetchListOfDialers();
    dispatch(fetchDialers(data));
  } catch (error) {
    console.log(error);
  }
};

export const getDialerById = (dialerId) => async (dispatch, getState) => {
  dispatch(fetchDialerInvoked(dialerId));
  try {
    const { data } = await fetchDialerById(dialerId);
    dispatch(fetchDialerSuccess(data));
  } catch (error) {
    console.log(error);
    dispatch(fetchDialerFailure(dialerId));
  }
};

export const clearStateCreatedDialer = () => async (dispatch, getState) => {
  dispatch(clearCreatedDialer()); // make sure to reset any previous data fetched / errors
};

export const createDialer = (dialerData) => async (dispatch, getState) => {
  dispatch(createDialerInvoked());
  
  try {
    const { data } = await addDialer(dialerData);
    dispatch(createDialerSuccess(data));
    
    toast.success(`Successfully Added Dialer Campaign: ${data.dialerName}`);
    
    // redirect user back to Dialer Listing
    history.push('/admin/dialer');
  } catch (error) {
    const { data } = error.response;
    
    dispatch(createDialerFailure(data));
  }
};

export const cloneDialer = (dialerId, dialerData) => async (dispatch, getState) => {
  dispatch(createDialerInvoked());
  
  try {
    const { data } = await cloneDialerById(dialerId, dialerData);
    dispatch(createDialerSuccess(data));
    
    toast.success(`Successfully Cloned Dialer Campaign!`);
    
    // redirect user back to Dialer Listing
    history.push('/admin/dialer');
  } catch (error) {
    const { data } = error.response;
    
    dispatch(createDialerFailure(data));
  }
};

export const deleteDialer = (dialerId) => async (dispatch, getState) => {
  try {
    await deleteDialerById(dialerId);
    dispatch(deleteDialerSuccess(dialerId));
    
    toast.success(`Successfully Deleted Dialer Campaign!`);
  } catch (error) {
    console.log(error);
  }
};

/*****************************************************************************************
 * Dialer Destination Actions
 *****************************************************************************************/
export const clearStateDialerDestinationById = (dialerDestinationId) => async (dispatch, getState) => {
  dispatch(fetchDialerDestinationInvoked(dialerDestinationId)); // make sure to reset any previous data fetched
};

export const getDialerDestinationById = (dialerDestinationId) => async (dispatch, getState) => {
  dispatch(fetchDialerDestinationInvoked(dialerDestinationId)); // make sure to reset any previous data fetched
  try {
    const { data } = await fetchDialerDestinationById(dialerDestinationId);
    dispatch(fetchDialerDestinationSuccess(data));
  } catch (error) {
    console.log(error);
  }
};

export const clearStateCreateDialerDestination = () => async (dispatch, getState) => {
  dispatch(clearCreateDialerDestination()); // make sure to reset any previous data fetched / errors
};

export const createDialerDestination = (dialerId, dialerDestinationData) => async (dispatch, getState) => {
  dispatch(createDialerDestinationInvoked());
  
  try {
    const { data } = await addDialerDestination(dialerId, dialerDestinationData);
    dispatch(createDialerDestinationSuccess({ dialerId, data }));
    
    toast.success(`Successfully Added Dialer Destination: ${data.dialerDestinationName}`);
    
    // redirect user back to Dialer Listing
    history.push(`/admin/dialer/edit/${dialerId}`);
  } catch (error) {
    const { data } = error.response;
    
    dispatch(createDialerDestinationFailure(data));
  }
};

export const editDialerDestination = (dialerDestinationId, dialerDestinationData) => async (dispatch, getState) => {
  dispatch(editDialerDestinationInvoked());
  
  try {
    const { data } = await editDialerDestinationById(dialerDestinationId, dialerDestinationData);
    dispatch(editDialerDestinationSuccess(data));
    
    toast.success(`Successfully Updated Dialer Destination: ${data.dialerDestinationName}`);
  } catch (error) {
    const { data } = error.response;
    
    dispatch(editDialerDestinationFailure(data));
  }
};

export const deleteDialerDestination = (dialerId, dialerDestinationId) => async (dispatch, getState) => {
  try {
    await deleteDialerDestinationById(dialerDestinationId);
    dispatch(deleteDialerDestinationSuccess({ dialerId, dialerDestinationId }));
    
    toast.success(`Successfully Deleted Dialer Destination!`);
  } catch (error) {
    console.log(error);
  }
};

export const testDialerDestination = (dialerDestinationId) => async (dispatch, getState) => {
  try {
    await testDialerDestinationById(dialerDestinationId);
    
    toast.info(`Successfully Started Testing Dialer!`);
  } catch (error) {
    console.log(error);
  }
};

export const resetDialerDestination = (dialerDestinationId) => async (dispatch, getState) => {
  try {
    const { data } = await resetDialerDestinationById(dialerDestinationId);
    dispatch(resetDialerDestinationSuccess(data));
    
    toast.info(`Successfully Reset Dialer Statistics!`);
  } catch (error) {
    console.log(error);
  }
};

export const deleteDialerNumber = (dialerNumberId, dialerNumberType) => async (dispatch, getState) => {
  dispatch(deleteDialerNumberInvoked());
  try {
    await deleteDialerNumberById(dialerNumberId, dialerNumberType);
    dispatch(deleteDialerNumberSuccess(dialerNumberId));
    
    toast.success(`Successfully Deleted Dialer Number!`);
  } catch (error) {
    console.log(error);
  }
};

export default dialerSlice.reducer;

import React, { Component }                           from 'react';

import "./DistributionList.scss";
import { Link }                                       from "react-router-dom";
import connect                                        from "react-redux/es/connect/connect";
import { deleteDistribution, getListOfDistributions } from "../../redux/distributionSlice";
import PropTypes                                      from "prop-types";
import history                                        from "../../history";
import _                                              from "lodash";
import Loading                                        from "../loading/Loading";
import ConfirmationModal                              from "../modal/ConfirmationModal";
import { DialerWCompAuth, isDialerW }                 from "../../security/Authorization";

const $ = require('jquery');
$.DataTable = require('datatables.net-bs5');

const AddButton = () => {
  return <div className="row mt-5">
    <div className="col-12">
      <Link to="/admin/distribution/add/distribution">
        <button type="button" className="btn btn-lg btn-outline-success">
          <i className="fas fa-plus-circle"></i> Add New Distribution
        </button>
      </Link>
    </div>
  </div>;
};
const AddButtonAuth = DialerWCompAuth(AddButton);

class DistributionList extends Component {
  static propTypes = {
    distributionList: PropTypes.array,
    listLoaded: PropTypes.bool,
    
    getListOfDistributions: PropTypes.func.isRequired,
    deleteDistribution: PropTypes.func.isRequired,
  };
  
  constructor(props) {
    super(props);
    
    this.tableAPI = undefined;
    
    this.state = {
      deleteModalIsOpen: false,
      distributionId: undefined,
      distributionText: undefined
    };
    
    this.toggleModal = this.toggleModal.bind(this);
    this.deleteDistribution = this.deleteDistribution.bind(this);
    this.deleteDistributionButton = this.deleteDistributionButton.bind(this);
  }
  
  componentDidMount() {
    const { distributionList, listLoaded, getListOfDistributions } = this.props;
    window.reactHistory = history;
    window.deleteDistributionButton = this.deleteDistributionButton;
    
    if (listLoaded === false || _.isEmpty(distributionList)) {
      getListOfDistributions();
    }
    
    this.initTable();
  }
  
  componentWillUnmount() {
    this.tableAPI.destroy();
  }
  
  componentDidUpdate(prevProps, prevState) {
    // update Distribution list table only if we receive a new list
    if (!_.isEqual(prevProps.distributionList, this.props.distributionList)) {
      this.updateTable();
    }
  }
  
  toggleModal() {
    this.setState({
      deleteModalIsOpen: !this.state.deleteModalIsOpen
    });
  }
  
  deleteDistribution(distributionId) {
    const { deleteDistribution } = this.props;
    deleteDistribution(distributionId);
    this.toggleModal();
  }
  
  deleteDistributionButton(distributionId, distributionName) {
    this.setState({
      deleteModalIsOpen: true,
      distributionId: distributionId,
      distributionText: distributionName
    });
  }
  
  updateTable() {
    const { distributionList, listLoaded } = this.props;
    
    if (listLoaded === true) {
      this.tableAPI.clear();
      this.tableAPI.rows.add(distributionList);
      this.tableAPI.draw();
    }
  }
  
  initTable() {
    const { distributionList } = this.props;
    
    this.$el = $(this.el);
    this.tableAPI = this.$el.DataTable({
      data: distributionList,
      pageLength: 10,
      order: [],
      columns: [
        { title: "Distribution Name", data: "distributionName" },
        {
          data: "distributionItems",
          title: 'Distribution Ranges',
          wrap: true,
          render: function (item) {
            let ranges = "";
            for (let value of item) {
              ranges += `(${value.intervalStart}, ${value.intervalEnd}) `;
            }
            
            return ranges;
          },
          orderable: false
        },
        {
          data: null,
          title: 'Actions',
          wrap: true,
          width: "210px",
          render: function (item) {
            if (isDialerW()) {
              if (item.deletable) {
                return '<div class="btn-group"> ' +
                  '<a onclick="window.reactHistory.push(\'/admin/distribution/edit/' + item.id + '\')" class="distribution-actions">' +
                  '<button type="button" class="btn btn-outline-info"><i class="fas fa-edit"></i> Edit</button>' +
                  '</a>' +
                  '<a onclick="window.deleteDistributionButton(' + item.id + ', \'' + item.distributionName + '\')" class="distribution-actions">' +
                  '<button type="button" class="btn btn-outline-danger"><i class="fas fa-trash"></i> Delete</button>' +
                  '</a>';
              } else {
                return '<div class="btn-group"> ' +
                  '<a onclick="window.reactHistory.push(\'/admin/distribution/edit/' + item.id + '\')" class="distribution-actions">' +
                  '<button type="button" class="btn btn-outline-info"><i class="fas fa-edit"></i> Edit</button>' +
                  '</a>' +
                  '<a aria-label="Distribution can not be deleted because it\'s used somewhere else!" data-cooltipz-dir="left" class="distribution-actions">' +
                  '<button type="button" class="btn btn-outline-danger" disabled><i class="fas fa-trash"></i> Delete</button>' +
                  '</a>' +
                  '</div>';
              }
            }
            return '<div class="btn-group"> ' +
              '<a onclick="window.reactHistory.push(\'/admin/distribution/edit/' + item.id + '\')" class="distribution-actions">' +
              '<button type="button" class="btn btn-outline-info"><i class="fas fa-eye"></i> View</button>' +
              '</a>' +
              '</div>';
          },
          orderable: false
        }
      ]
    });
  }
  
  render() {
    const { listLoaded } = this.props;
    
    return (
      <div className="list-of-distributions section">
        
        <div className="section-title">
          <h2>List of Distributions</h2>
        </div>
        
        <Loading display={!listLoaded}/>
        
        <ConfirmationModal
          isOpen={this.state.deleteModalIsOpen}
          title={"Distribution: " + this.state.distributionText}
          content="<p>Are you sure you want to delete this Distribution?</p>"
          actionText="Delete"
          toggle={this.toggleModal}
          doAction={() => this.deleteDistribution(this.state.distributionId)}/>
        
        <div className="row">
          <div className="col-12">
            <table className="table table-hover" ref={el => this.el = el}>
            </table>
          </div>
        </div>
        
        <AddButtonAuth/>
      
      </div>
    );
  };
}

export default connect(
  (state) => {
    return {
      distributionList: state.distribution.list,
      listLoaded: state.distribution.listLoaded
    };
  },
  {
    getListOfDistributions: () => getListOfDistributions(),
    deleteDistribution: (distributionId) => deleteDistribution(distributionId),
  }
)(DistributionList);


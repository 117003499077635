import React, { Component }                                         from 'react';

import "./RecordingProfile.scss";
import connect                                                      from "react-redux/es/connect/connect";
import _                                                            from "lodash";
import { RecordingWCompAuth }                                       from "../../security/Authorization";
import PropTypes                                                    from "prop-types";
import Loading                                                      from "../loading/Loading";
import { clearStateEditRecording, editRecording, getRecordingById } from "../../redux/recordingSlice";

const UpdateButton = ({ editRecordingProcess }) => {
  return <div className="col-8 d-flex align-self-center justify-content-center">
    {
      (editRecordingProcess === false)
        ? <button type="submit" className="btn btn-primary"><i className="fas fa-redo"></i> Update
        </button>
        : <button className="btn btn-primary" type="button" disabled>
                          <span className="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span> Update
        </button>
    }
  </div>;
};
const UpdateButtonAuth = RecordingWCompAuth(UpdateButton);

class RecordingProfile extends Component {
  static propTypes = {
    recording: PropTypes.object,
    editedRecording: PropTypes.object,
    recordingProfileLoaded: PropTypes.bool,
    editRecordingProcess: PropTypes.bool,
    
    getRecordingById: PropTypes.func.isRequired,
    clearStateEditRecording: PropTypes.func.isRequired,
    editRecording: PropTypes.func.isRequired,
  };
  
  constructor(props) {
    super(props);
    
    this.state = {
      recordingId: undefined,
      
      numberOfDays: undefined,
    };
    
    this.handleChange = this.handleChange.bind(this);
  }
  
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps?.recording?.id !== prevState?.recordingId) {
      console.log("[[>>> getDerivedStateFromProps]]");
      
      return {
        ...prevState,
        recordingId: nextProps.recording.id,
        numberOfDays: nextProps.recording.numberOfDays,
      };
    } else {
      return null;
    }
  }
  
  componentDidMount() {
    const { recording, getRecordingById } = this.props;
    
    if (this.props?.match?.params?.id !== undefined) {
      const recordingId = _.parseInt(this.props.match.params.id);
      
      if (recording === undefined) {
        getRecordingById(recordingId);
      }
    }
  }
  
  componentWillUnmount() {
    const { clearStateEditRecording } = this.props;
    clearStateEditRecording();
  }
  
  handleChange = (event) => {
    const { target } = event;
    let { id } = target;
    let value = target.value;
    
    if (target.type === "checkbox") {
      value = target.checked;
    } else {
      if (target.type === "radio") {
        id = target.name;
        value = target.value;
      } else {
        value = target.value;
      }
    }
    
    this.setState({
      [id]: value,
    });
  };
  
  submitUpdateForm(event) {
    const { editRecording } = this.props;
    event.preventDefault();
    
    // extract Recording id
    let recordingId;
    if (this.props?.match?.params?.id !== undefined) {
      recordingId = _.parseInt(this.props.match.params.id);
    }
    
    if (recordingId === undefined) {
      console.error("Something is wrong! we don't have the Recording id at this point!");
      return;
    }
    
    editRecording(recordingId, this.state);
  }
  
  render() {
    const { recording, recordingProfileLoaded, editedRecording, editRecordingProcess } = this.props;
    const { numberOfDays } = this.state;
    
    return (
      <div className="recording-profile section">
        
        <div className="section-title">
          <h2>Edit Recording GW</h2>
        </div>
        
        <Loading display={!recordingProfileLoaded}/>
        
        {
          (recording !== undefined)
            ? <div>
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Recording GW Id: {recording.recordingId} -- Recording GW
                    Name: {recording.recordingName}</h5>
                  <p className="card-text">Created
                    By: {recording.createdBy} on {new Date(recording.createdDate).toLocaleDateString()} date</p>
                </div>
              </div>
              
              <hr className="bg-info m-4 border-info"/>
              
              
              <div className="row">
                <div className="col-12">
                  <form onSubmit={(event) => this.submitUpdateForm(event)}>
                    
                    <div className="row">
                      <div className="col-4">
                        <label htmlFor="numberOfDays" className="form-label">Number of Days</label>
                        <input type="number" min={1} className="form-control" name="numberOfDays" id="numberOfDays"
                               placeholder="number of days"
                               value={numberOfDays}
                               onChange={(event) => this.handleChange(event)} required/>
                      </div>
                      
                      
                        <UpdateButtonAuth editRecordingProcess={editRecordingProcess}/>
                    </div>
                  </form>
                  
                  {(!_.isEmpty(editedRecording.errors))
                    ? <div className="alert alert-danger login-error" role="alert">
                      {
                        editedRecording.errors.map(error => <div key={error.fieldName + error.message}>
                          {
                            (error.fieldName !== undefined)
                              ? error.fieldName + " - "
                              : null
                          }
                          {error.message}
                        </div>)
                      }
                    </div>
                    : null
                  }
                
                </div>
              </div>
            </div>
            
            : null
        }
      </div>
    );
  };
}

export default connect(
  (state, props) => {
    let recordingId;
    if (props?.match?.params?.id !== undefined) {
      recordingId = _.parseInt(props.match.params.id);
    }
    
    return {
      recording: _.find(state.recording.list, recording => recording.id === recordingId),
      
      editedRecording: state.recording.editedRecording,
      recordingProfileLoaded: state.recording.recordingProfileLoaded,
      
      editRecordingProcess: state.recording.editRecordingProcess,
    };
  },
  {
    getRecordingById: (recordingId) => getRecordingById(recordingId),
    clearStateEditRecording: () => clearStateEditRecording(),
    editRecording: (recordingId, recordingData) => editRecording(recordingId, recordingData),
  }
)(RecordingProfile);


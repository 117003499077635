import React, { Component } from 'react';

import { Area, AreaChart, CartesianGrid, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import Counts                                                                                        from "./Counts";

import "./Dashboards.scss";
import connect
                                                                                                     from "react-redux/es/connect/connect";
import PropTypes                                                                                     from "prop-types";
import {
  clearReportingState,
  getReportingByType
}                                                                                                    from "../../redux/dashboardSlice";
import _                                                                                             from "lodash";
import Loading
                                                                                                     from "../loading/Loading";

const dashColors = ['#2f7ed8', "#90ed7d", "#f28f43", "#e4d354",
  "#8085e9", "#91e8e1", "#1aadce", "#8bbc21"];

class Dashboards extends Component {
  static propTypes = {
    metadataListLoaded: PropTypes.bool,
    reportingType: PropTypes.object,
    reportingList: PropTypes.object,
    reportingLoaded: PropTypes.object,
    
    getReportingByType: PropTypes.func.isRequired,
    clearReportingState: PropTypes.func.isRequired,
  };
  
  componentDidUpdate(prevProps, prevState) {
    // just in case the metadata list is not loaded yet in order to fetch reporting data
    if (prevProps.metadataListLoaded === false && this.props.metadataListLoaded === true) {
      console.log(">>>>>!!!!!!!!!!!!!!!!!!!!!!!>>>>>>>>");
      this.fetchReportingData();
    }
  }
  
  componentDidMount() {
    const { metadataListLoaded } = this.props;
    
    // load the reporting data only if we already have the metadata list (this is needed for the APIs calls)
    if (metadataListLoaded === true) {
      this.fetchReportingData();
    }
  }
  
  /**
   * At this point we should have all "reportingType" metadata already fetched
   */
  fetchReportingData() {
    const { reportingType, getReportingByType } = this.props;
    
    _.forEach(_.keys(reportingType), function (type, index) {
        getReportingByType(type);
        // const timeout = 50 * index;   // check if this is the proper interval to delay calls
        // setTimeout(() => {
        //   getReportingByType(type);
        // }, timeout);
      }
    );
  }
  
  componentWillUnmount() {
    const { clearReportingState } = this.props;
    clearReportingState();
  }
  
  
  formatXAxis(tickItem) {
    const date = new Date(tickItem);
    
    return (date.getMonth() + 1) + "/" + date.getDate();
  }
  
  render() {
    const { reportingType, reportingList, reportingLoaded } = this.props;
    
    return (
      <div className="dashboards section">
        
        <div className="section-title">
          <h2>Dashboards</h2>
        </div>
        
        <div className="row">
          <div className="col-12 mb-3">
            <Counts/>
          </div>
        </div>
        
        {
          (!_.isEmpty(reportingType) && !_.isEmpty(Object.keys(reportingType)))
            ? <div className="row">
              {
                Object.keys(reportingType).map((type, index) =>
                  <div className="col-12 mb-4" key={type}>
                    <h3>{reportingType[type]}</h3>
                    
                    <Loading display={!reportingLoaded[type]}/>
                    
                    {
                      (!_.isEmpty(reportingList[type]))
                        ? <div className="col-12">
                          <div style={{ height: 300 }}>
                            <ResponsiveContainer>
                              <AreaChart
                                data={reportingList[type].data}
                                margin={{
                                  top: 10,
                                  right: 30,
                                  left: 0,
                                  bottom: 20,
                                }}
                              >
                                <CartesianGrid strokeDasharray="3 3"/>
                                
                                <XAxis dataKey="date" tickFormatter={this.formatXAxis}
                                       label={{ value: 'Time', position: 'bottom', offset: 0 }}/>
                                <YAxis domain={[reportingList[type].threshold.min, reportingList[type].threshold.max]}
                                       label={{ value: 'Value', angle: -90, position: 'insideLeft' }}/>
                                <Tooltip/>
                                
                                {
                                  reportingList[type].threshold.error !== 0
                                    ? <ReferenceLine y={reportingList[type].threshold.error} label="Error"
                                                     ifOverflow="extendDomain" stroke="red"
                                                     strokeWidth="2" strokeDasharray="3 3"/>
                                    : null
                                }
                                
                                {
                                  reportingList[type].threshold.warning !== 0
                                    ? <ReferenceLine y={reportingList[type].threshold.warning} label="Warning"
                                                     ifOverflow="extendDomain" stroke="orange"
                                                     strokeWidth="2" strokeDasharray="3 3"/>
                                    : null
                                }
                                
                                <Area type="monotone" dataKey="value" stroke={dashColors[index % dashColors.length]}
                                      fill={dashColors[index % dashColors.length]}
                                      fillOpacity={0.9}/>
                              </AreaChart>
                            </ResponsiveContainer>
                          </div>
                        </div>
                        : <div style={{ height: 300 }}></div>
                    }
                  </div>
                )
              }
            </div>
            : null
        }
      
      </div>
    );
  };
}

export default connect(
  (state, props) => {
    
    return {
      metadataListLoaded: state.metadata.listLoaded,
      reportingType: state.metadata.list["reportingType"],
      reportingList: state.dashboard.reportingList,
      reportingLoaded: state.dashboard.reportingLoaded,
    };
  },
  {
    getReportingByType: (type) => getReportingByType(type),
    clearReportingState: () => clearReportingState()
  }
)(Dashboards);

import {
  fetchAllRecordingContentFromDisk,
  fetchFileContent,
  fetchFileContentFromDisk,
  fetchRecordingContentFromDisk
} from "../api/Api";

/**
 * Trigger File download action.
 */
export const saveFile = async (file) => {
  try {
    const response = await fetchFileContent(file.id);
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(response.data);
    downloadLink.download = file.name;
    downloadLink.click();
  } catch (error) {
    console.log(error);
  }
};

/**
 * Trigger FAS File download action.
 */
export const saveFasFile = async (fas) => {
  try {
    const response = await fetchFileContentFromDisk(fas.id);
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(response.data);
    downloadLink.download = fas.fileName;
    downloadLink.click();
  } catch (error) {
    console.log(error);
  }
};

/**
 * Trigger Recording File download action.
 */
export const saveRecordingFile = async (recordingId, recordingName) => {
  try {
    const response = await fetchRecordingContentFromDisk(recordingId);
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(response.data);
    downloadLink.download = recordingName;
    downloadLink.click();
  } catch (error) {
    console.log(error);
  }
};

/**
 * Trigger All Recordings File download action.
 */
export const saveAllRecordingFile = async (params) => {
  try {
    const response = await fetchAllRecordingContentFromDisk(params);
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(response.data);
    downloadLink.download = "archive.zip";
    downloadLink.click();
  } catch (error) {
    console.log(error);
  }
};

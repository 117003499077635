import { createSlice }          from "@reduxjs/toolkit";
import _                        from "lodash";
import {
  addRecording,
  deleteRecordingById,
  deleteRecordingSearchAll,
  deleteRecordingSearchById,
  editRecordingById,
  fetchListOfRecordings,
  fetchRecordingById
}                               from "../api/Api";
import history                  from "../history";
import { toast }                from "react-toastify";
import { saveAllRecordingFile } from "../util/File";

const initialState = {
  list: [],             // list of Recording GWs
  listLoaded: false,
  
  createdRecording: {},       // new Recording GW entity/data
  createRecordingProcess: false,
  
  editedRecording: {}, // edit Recording GW entity/data
  recordingProfileLoaded: true,
  
  editRecordingProcess: false,
  
  deleteRecordingProcess: false,
  deleteAllRecordingProcess: false,
  downloadAllRecordingProcess: false,
};

export const recordingSlice = createSlice({
  name: 'recording',
  initialState,
  reducers: {
    /*****************************************************************************************
     * Recording Gateway Reducers
     *****************************************************************************************/
    clearCreatedRecording: (state, action) => {
      state.createdRecording = {};
    },
    
    createRecordingInvoked: (state, action) => {
      state.createRecordingProcess = true;
    },
    
    createRecordingSuccess: (state, action) => {
      state.createdRecording = action.payload;
      state.list = [action.payload, ...state.list];
      state.createRecordingProcess = false;
    },
    
    createRecordingFailure: (state, action) => {
      // check if we have validation error or any other type of errors
      let errors;
      if (action.payload.errors === undefined) {
        errors = [];
        errors.push(action.payload);
      } else {
        errors = action.payload.errors;
      }
      
      state.createdRecording.errors = errors;
      state.createRecordingProcess = false;
    },
    
    deleteRecordingSuccess: (state, action) => {
      const recordingId = action.payload;
      state.list = _.filter(state.list, recording => recording.id !== recordingId);
    },
    
    fetchRecordings: (state, action) => {
      state.list = action.payload;
      state.listLoaded = true;
    },
    
    fetchRecordingInvoked: (state, action) => {
      state.recordingProfileLoaded = false;
    },
    
    fetchRecordingFailure: (state, action) => {
      state.recordingProfileLoaded = false;
    },
    
    fetchRecordingSuccess: (state, action) => {
      if (_.isEmpty(state.list)) {
        state.list = [];
        state.list.push(action.payload);
      } else {
        // add recording if it's not already in store
        const recording = _.find(state.list, recording => recording.id === action.payload.id);
        
        if (recording === undefined) {
          state.list.push(action.payload);
        }
      }
      
      state.recordingProfileLoaded = true;
    },
    
    clearEditRecording: (state, action) => {
      state.editedRecording = {};
    },
    
    editRecordingInvoked: (state, action) => {
      state.editRecordingProcess = true;
    },
    
    editRecordingSuccess: (state, action) => {
      state.editedRecording = action.payload;
      
      // update state
      const recordingId = _.findIndex(state.list, recording => recording.id === action.payload.id);
      if (recordingId !== -1) {
        // maybe it's better to just update the entire object than each field (like we did in edit Dialer Destination)
        state.list[recordingId] = action.payload;
      }
      
      state.editRecordingProcess = false;
    },
    
    editRecordingFailure: (state, action) => {
      // check if we have validation error or any other type of errors
      let errors;
      if (action.payload.errors === undefined) {
        errors = [];
        errors.push(action.payload);
      } else {
        errors = action.payload.errors;
      }
      
      state.editedRecording.errors = errors;
      state.editRecordingProcess = false;
    },
    
    /*****************************************************************************************
     * Recording Reducers
     *****************************************************************************************/
    deleteRecordingSearchInvoked: (state, action) => {
      state.deleteRecordingProcess = true;
    },
    
    deleteRecordingSearchSuccess: (state, action) => {
      state.deleteRecordingProcess = false;
    },
    
    deleteRecordingAllSearchInvoked: (state, action) => {
      state.deleteAllRecordingProcess = true;
    },
    
    deleteRecordingAllSearchSuccess: (state, action) => {
      state.deleteAllRecordingProcess = false;
    },
    
    downloadRecordingAllSearchInvoked: (state, action) => {
      state.downloadAllRecordingProcess = true;
    },
    
    downloadRecordingAllSearchSuccess: (state, action) => {
      state.downloadAllRecordingProcess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase("user/logoutSuccess", (state, action) => {
        return initialState;
      });
  }
});

export const {
  clearCreatedRecording,
  createRecordingInvoked,
  createRecordingSuccess,
  createRecordingFailure,
  
  editRecordingInvoked,
  editRecordingSuccess,
  editRecordingFailure,
  
  deleteRecordingSuccess,
  
  fetchRecordings,
  fetchRecordingInvoked,
  fetchRecordingSuccess,
  fetchRecordingFailure,
  
  clearEditRecording,
  
  deleteRecordingSearchInvoked,
  deleteRecordingSearchSuccess,
  
  deleteRecordingAllSearchInvoked,
  deleteRecordingAllSearchSuccess,
  downloadRecordingAllSearchInvoked,
  downloadRecordingAllSearchSuccess
} = recordingSlice.actions;

/*****************************************************************************************
 * Recording Gateway Actions
 *****************************************************************************************/
export const getListOfRecordings = () => async (dispatch, getState) => {
  try {
    const { data } = await fetchListOfRecordings();
    dispatch(fetchRecordings(data));
  } catch (error) {
    console.log(error);
  }
};

export const getRecordingById = (recordingId) => async (dispatch, getState) => {
  dispatch(fetchRecordingInvoked());
  try {
    const { data } = await fetchRecordingById(recordingId);
    dispatch(fetchRecordingSuccess(data));
  } catch (error) {
    console.log(error);
    dispatch(fetchRecordingFailure());
  }
};

export const clearStateCreatedRecording = () => async (dispatch, getState) => {
  dispatch(clearCreatedRecording()); // make sure to reset any previous data fetched / errors
};

export const createRecording = (recordingData) => async (dispatch, getState) => {
  dispatch(createRecordingInvoked());
  
  try {
    const { data } = await addRecording(recordingData);
    dispatch(createRecordingSuccess(data));
    
    // redirect user back to Recording Listing
    history.push('/admin/recgw');
  } catch (error) {
    const { data } = error.response;
    
    dispatch(createRecordingFailure(data));
  }
};

export const deleteRecording = (recordingId) => async (dispatch, getState) => {
  try {
    await deleteRecordingById(recordingId);
    dispatch(deleteRecordingSuccess(recordingId));
  } catch (error) {
    console.log(error);
  }
};

export const clearStateEditRecording = () => async (dispatch, getState) => {
  dispatch(clearEditRecording()); // make sure to reset any previous data fetched / errors
};

export const editRecording = (recordingId, recordingData) => async (dispatch, getState) => {
  dispatch(editRecordingInvoked());
  
  try {
    const { data } = await editRecordingById(recordingId, recordingData);
    dispatch(editRecordingSuccess(data));
    
    toast.success(`Successfully Updated Recording: ${data.recordingName}`);
    
    // redirect user back to Recording Listing
    history.push('/admin/recgw');
  } catch (error) {
    const { data } = error.response;
    
    dispatch(editRecordingFailure(data));
  }
};

/*****************************************************************************************
 * Recording Actions
 *****************************************************************************************/

export const deleteRecordingSearch = (recordingId) => async (dispatch, getState) => {
  dispatch(deleteRecordingSearchInvoked());
  try {
    await deleteRecordingSearchById(recordingId);
    dispatch(deleteRecordingSearchSuccess());
    
    toast.success(`Successfully Deleted Recording!`);
  } catch (error) {
    console.log(error);
  }
};

export const deleteAllRecordingSearch = (searchParams) => async (dispatch, getState) => {
  dispatch(deleteRecordingAllSearchInvoked());
  try {
    await deleteRecordingSearchAll(searchParams);
    dispatch(deleteRecordingAllSearchSuccess());
    
    toast.success(`Successfully Deleted All Recordings from the search result!`);
  } catch (error) {
    console.log(error);
  }
};

export const downloadAllRecordingSearch = (searchParams) => async (dispatch, getState) => {
  dispatch(downloadRecordingAllSearchInvoked());
  try {
    await saveAllRecordingFile(searchParams);
    dispatch(downloadRecordingAllSearchSuccess());
  } catch (error) {
    console.log(error);
  }
};

export default recordingSlice.reducer;

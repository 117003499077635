import React, { Component }            from 'react';

import "./CliProfile.scss";
import connect                         from "react-redux/es/connect/connect";
import _                               from "lodash";
import { deleteCliChange, getCliById } from "../../redux/cliSlice";
import PropTypes                       from "prop-types";
import Loading                         from "../loading/Loading";
import ConfirmationModal               from "../modal/ConfirmationModal";
import { Link }                        from "react-router-dom";
import history                         from "../../history";
import { CliWCompAuth, isCliW }        from "../../security/Authorization";

const $ = require('jquery');
$.DataTable = require('datatables.net-bs5');

const AddButton = ({ cli }) => {
  return <div className="row mt-5">
    <div className="col-12">
      <Link to={`/admin/cli/${cli.id}/cli-change/add/cli-change`}>
        <button type="button" className="btn btn-lg btn-outline-success">
          <i className="fas fa-plus-circle"></i> Add New CLI Change
        </button>
      </Link>
    </div>
  </div>;
};
const AddButtonAuth = CliWCompAuth(AddButton);

class CliProfile extends Component {
  static propTypes = {
    cli: PropTypes.object,
    cliChangeLoaded: PropTypes.bool,
    
    getCliById: PropTypes.func.isRequired,
    deleteCliChange: PropTypes.func.isRequired,
  };
  
  constructor(props) {
    super(props);
    
    this.tableAPI = undefined;
    
    this.state = {
      deleteModalIsOpen: false,
      cliChangeId: undefined,
      cliChangeText: undefined
    };
    
    this.toggleModal = this.toggleModal.bind(this);
    this.deleteCliChange = this.deleteCliChange.bind(this);
    this.deleteCliChangeButton = this.deleteCliChangeButton.bind(this);
  }
  
  componentDidMount() {
    const { cli, cliChangeLoaded, getCliById } = this.props;
    
    window.reactHistory = history;
    window.deleteCliButton = this.deleteCliChangeButton;
    
    if (this.props?.match?.params?.id !== undefined) {
      const cliId = _.parseInt(this.props.match.params.id);
      if (cliChangeLoaded === false || cli === undefined) {
        getCliById(cliId);
      }
    }
    
    this.initTable();
  }
  
  componentWillUnmount() {
    this.tableAPI.destroy();
  }
  
  componentDidUpdate(prevProps, prevState) {
    // update CLI Change list table only if we receive a new list
    if (!_.isEqual(prevProps.cli, this.props.cli)) {
      console.log(">>>>>!!!!!!!!!!!!!!!!!!!!!!!>>>>>>>>");
      this.updateTable();
    }
  }
  
  toggleModal() {
    this.setState({
      deleteModalIsOpen: !this.state.deleteModalIsOpen
    });
  }
  
  deleteCliChange(cliChangeId) {
    const { deleteCliChange } = this.props;
    
    // extract CLI id
    let cliId;
    if (this.props?.match?.params?.id !== undefined) {
      cliId = _.parseInt(this.props.match.params.id);
    }
    if (cliId === undefined) {
      console.error("Something is wrong! we don't have the CLI id at this point!");
      return;
    }
    
    deleteCliChange(cliId, cliChangeId);
    
    this.toggleModal();
  }
  
  deleteCliChangeButton(cliChangeId, destinationName) {
    this.setState({
      deleteModalIsOpen: true,
      cliChangeId: cliChangeId,
      cliChangeText: destinationName
    });
  }
  
  updateTable() {
    const { cli, cliChangeLoaded } = this.props;
    
    if (cliChangeLoaded === true) {
      this.tableAPI.clear();
      this.tableAPI.rows.add(cli.cliChangeCampaigns);
      this.tableAPI.draw();
    }
  }
  
  initTable() {
    const { cli } = this.props;
    
    this.$el = $(this.el);
    this.tableAPI = this.$el.DataTable({
      data: cli !== undefined ? cli.cliChangeCampaigns : [],
      pageLength: 10,
      order: [],
      
      columns: [
        { title: "Destination Code", data: "destinationCode" },
        { title: "Destination Name", data: "destinationName" },
        { title: "Change Policy", data: "cliChangePolicy" },
        { title: "Change Type", data: "cliChangeType" },
        { title: "Enabled", data: "enabled" },
        {
          data: "createdDate",
          title: 'Created Date',
          wrap: true,
          render: function (item) {
            const date = new Date(item);
            return date.toLocaleDateString();
          },
        },
        { title: "Created By", data: "createdBy" },
        {
          data: null,
          title: 'Actions',
          wrap: true,
          width: "210px",
          render: function (item) {
            const destinationName = item.destinationName.replace(/'/g, "\\'");
            
            if (isCliW()) {
              return '<div class="btn-group"> ' +
                '<a onclick="window.reactHistory.push(\'/admin/cli/cli-change/edit/' + item.id + '\')" class="cli-change-actions">' +
                '<button type="button" class="btn btn-outline-info"><i class="fas fa-edit"></i> Edit</button>' +
                '</a>' +
                '<a onclick="window.deleteCliButton(' + item.id + ', \'' + destinationName + '\')" class="cli-change-actions">' +
                '<button type="button" class="btn btn-outline-danger"><i class="fas fa-trash"></i> Delete</button>' +
                '</a>' +
                '</div>';
            }
            return '<div class="btn-group"> ' +
              '<a onclick="window.reactHistory.push(\'/admin/cli/cli-change/edit/' + item.id + '\')" class="cli-change-actions">' +
              '<button type="button" class="btn btn-outline-info"><i class="fas fa-eye"></i> View</button>' +
              '</a>' +
              '</div>';
          },
          orderable: false
        }
      ]
    });
  }
  
  // goBack = () => {
  //   history.goBack();
  // };
  
  render() {
    const { cli, cliChangeLoaded } = this.props;
    
    return (
      <div className="cli-profile section">
        
        <div className="section-title">
          <h2>Edit CLI Campaign</h2>
        </div>
        
        <Loading display={!cliChangeLoaded}/>
        
        <ConfirmationModal
          isOpen={this.state.deleteModalIsOpen}
          title={"CLI Change Campaign: " + this.state.cliChangeText}
          content="<p>Are you sure you want to delete this CLI Change Campaign? <br/> <b>All associated data will be lost!</b></p>"
          actionText="Delete"
          toggle={this.toggleModal}
          doAction={() => this.deleteCliChange(this.state.cliChangeId)}/>
        
        {
          (cli !== undefined)
            ? <div className="card">
              <div className="card-body">
                <h5 className="card-title">CLI Unique Id: {cli.campaignId} -- CLI Name: {cli.campaignName}</h5>
                <p className="card-text">Created
                  By: {cli.createdBy} on {new Date(cli.createdDate).toLocaleDateString()} date</p>
                
                {
                  (!_.isNil(cli.clonedFromId))
                    ? <h6><i className="fas fa-triangle-exclamation"></i> This Cli Campaign is cloned from <Link
                      className="cloned-link" to={"/admin/cli/edit/" + cli.clonedFromId}
                      onClick={() => setTimeout(() => {
                        window.location.reload(); // just reload the page to force re-fetching the new data
                      }, 1)}>
                      this one with id {cli.clonedFromId}</Link></h6>
                    : null
                }
                
                {/*<button type="button" className="btn btn-primary" onClick={() => this.goBack()}>*/}
                {/*  <i className="fas fa-backward"></i> Go Back*/}
                {/*</button>*/}
              </div>
            </div>
            : null
        }
        
        <div className="row mt-4">
          <div className="col-12">
            <table className="table table-hover" ref={el => this.el = el}>
            </table>
          </div>
        </div>
        
        {
          (cli !== undefined)
            ? <AddButtonAuth cli={cli}/>
            : null
        }
      
      </div>
    );
  };
}

export default connect(
  (state, props) => {
    let cliId;
    if (props?.match?.params?.id !== undefined) {
      cliId = _.parseInt(props.match.params.id);
    }
    
    return {
      cli: state.cli.cliChangeList[cliId],
      cliChangeLoaded: state.cli.cliChangeLoaded[cliId] !== undefined ? state.cli.cliChangeLoaded[cliId] : false
    };
  },
  {
    getCliById: (cliId) => getCliById(cliId),
    deleteCliChange: (cliId, cliChangeId) => deleteCliChange(cliId, cliChangeId),
  }
)(CliProfile);


import React        from 'react';
import ReactDOM     from 'react-dom';
import AppRoutes    from "./router/AppRoutes";
import { store }    from './redux/store';
import { Provider } from 'react-redux';

import '@fortawesome/fontawesome-free/css/all.min.css';
import './bootstrap-custom.scss';
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import './index.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'cooltipz-css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

String.prototype.interpolate = function (params) {
  const names = Object.keys(params);
  const vals = Object.values(params);
  return new Function(...names, `return \`${this}\`;`)(...vals);
};

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

ReactDOM.render(<React.StrictMode>
  <Provider store={store}>
    <AppRoutes/>
  </Provider>
</React.StrictMode>, document.getElementById('root'));

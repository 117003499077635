import { Component }     from 'react';
import connect           from "react-redux/es/connect/connect";
import { performLogout } from "../../redux/userSlice";
import PropTypes         from "prop-types";

class Logout extends Component {
  static propTypes = {
    performLogout: PropTypes.func.isRequired,
  };
  
  componentDidMount() {
    this.props.performLogout();
  }
  
  render() {
    return null;
  }
}

export default connect(
  (state) => {
    return {};
  },
  {
    performLogout: () => performLogout()
  }
)(Logout);

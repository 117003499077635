import React, { Component }                                                  from 'react';

import "./DistributionProfile.scss";
import connect                                                               from "react-redux/es/connect/connect";
import { DialerWCompAuth }                                                   from "../../security/Authorization";
import PropTypes                                                             from "prop-types";
import _                                                                     from "lodash";
import { clearStateEditDistribution, editDistribution, getDistributionById } from "../../redux/distributionSlice";
import Loading                                                               from "../loading/Loading";
import { getTrackBackground, Range }                                         from "react-range";
import { COLOR, MAX, MIN, MIN_RANGE_SIZE, STEP }                             from "./AddDistribution";

const UpdateButton = ({ editDistributionProcess }) => {
  return <div className="row mt-5">
    <div className="col-12">
      {
        (editDistributionProcess === false)
          ? <button type="submit" className="btn btn-primary"><i className="fas fa-redo"></i> Update
          </button>
          : <button className="btn btn-primary" type="button" disabled>
                          <span className="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span> Update
          </button>
      }
    </div>
  </div>;
};
const UpdateButtonAuth = DialerWCompAuth(UpdateButton);

class DistributionProfile extends Component {
  static propTypes = {
    distribution: PropTypes.object,
    editedDistribution: PropTypes.object,
    distributionProfileLoaded: PropTypes.bool,
    editDistributionProcess: PropTypes.bool,
    
    getDistributionById: PropTypes.func.isRequired,
    clearStateEditDistribution: PropTypes.func.isRequired,
    editDistribution: PropTypes.func.isRequired,
  };
  
  constructor(props) {
    super(props);
    
    this.state = {
      distributionId: undefined,
      distributionItems: [],
      
      values: [],
      colors: [],   // this needs to be true: values.length + 1 === colors.length;
    };
    
    this.handleItemChange = this.handleItemChange.bind(this);
    this.initIntervals = this.initIntervals.bind(this);
    this.updateIntervals = this.updateIntervals.bind(this);
    this.addInterval = this.addInterval.bind(this);
    this.computeIntervals = this.computeIntervals.bind(this);
  }
  
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps?.distribution?.id !== prevState?.distributionId) {
      console.log("[[>>> getDerivedStateFromProps]]");
      
      return {
        ...prevState,
        distributionId: nextProps.distribution.id,
        distributionItems: nextProps.distribution.distributionItems,
      };
    } else {
      return null;
    }
  }
  
  componentDidUpdate(prevProps, prevState) {
    // update intervals when we fetch a new distribution
    if (!_.isEqual(prevProps.distribution, this.props.distribution)) {
      console.log(">>>>>!!!!!!!!!!!!!!!!!!!!!!!>>>>>>>>");
      this.initIntervals();
    }
  }
  
  componentDidMount() {
    const { distribution, getDistributionById } = this.props;
    
    if (this.props?.match?.params?.id !== undefined) {
      const distributionId = _.parseInt(this.props.match.params.id);
      if (distribution === undefined) {
        getDistributionById(distributionId);
      } else {
        this.initIntervals();
      }
    }
  }
  
  componentWillUnmount() {
    const { clearStateEditDistribution } = this.props;
    clearStateEditDistribution();
  }
  
  /**
   * Update item values.
   */
  handleItemChange = (event) => {
    const { target } = event;
    const { id, value } = target;
    
    // we have a path hierarchy
    const path = id.split(".");
    if (path.length === 2) {
      const index = path[0];
      const field = path[1];
      
      const distributionItems = [...this.state.distributionItems];
      const item = { ...distributionItems[index] };
      item[field] = value;
      distributionItems[index] = item;
      this.setState({ distributionItems });
    } else {
      console.error("something is wrong with the interval item id: " + id);
    }
  };
  
  submitUpdateForm(event) {
    const { editDistribution } = this.props;
    event.preventDefault();
    
    // extract Distribution id
    let distributionId;
    if (this.props?.match?.params?.id !== undefined) {
      distributionId = _.parseInt(this.props.match.params.id);
    }
    if (distributionId === undefined) {
      console.error("Something is wrong! we don't have the Distribution id at this point!");
      return;
    }
    
    editDistribution(distributionId, this.state);
  }
  
  /**
   * Init the intervals.
   */
  initIntervals() {
    const { distributionItems } = this.props.distribution;
    let values = [];
    const colors = [];
    
    if (distributionItems.length < MIN_RANGE_SIZE) {
      console.error("Something is wrong! we have a distributionItems with a length smaller than 2 - actual length: "
        + distributionItems.length);
    }
    
    for (let i = 0; i < distributionItems.length; i++) {
      values.push(distributionItems[i].intervalStart);
      values.push(distributionItems[i].intervalEnd);
      
      colors.push(COLOR);
    }
    
    // add only unique values beside MIN/MAX (for some reason this library treats this values differently...)
    values = _.uniq(_.sortBy(_.filter(values, v => v !== MIN && v !== MAX)));
    
    this.setState({ values, colors });
  }
  
  /**
   * Update intervals values.
   */
  updateIntervals(values) {
    // only allow unique values
    if (_.uniq([MIN, ...values, MAX]).length === [MIN, ...values, MAX].length) {
      this.setState({ values });
      
      // just to be sure that the this.state.values were updated
      setTimeout(() => {
        this.computeIntervals();
      }, 50);
    }
  }
  
  /**
   * Add a new interval for the distribution.
   */
  addInterval() {
    // find new "free" interval value
    const values = [MIN, ...this.state.values, MAX];
    
    let interval;
    for (let i = MAX; i >= MIN; i--) {
      if (!values.includes(i)) {
        interval = i;
        break;
      }
    }
    
    const newValues = _.sortBy([...this.state.values, interval]);
    
    this.setState({
      values: newValues,
      colors: [...this.state.colors, COLOR],  // just add another color
    });
    
    // just to be sure that the this.state.values were updated
    setTimeout(() => {
      this.computeIntervals();
    }, 50);
  }
  
  /**
   * Compute the intervals.
   */
  computeIntervals() {
    const values = [MIN, ...this.state.values, MAX];
    const distributionItems = [];
    
    for (let i = 0; i < values.length - 1; i++) {
      let distributionItem;
      
      // check if we have a new distribution item, in that case we just create a new item with undefined values
      if (!_.isNil(this.state.distributionItems[i])) {
        distributionItem = _.cloneDeep(this.state.distributionItems[i]);
        distributionItem.intervalStart = values[i];
        distributionItem.intervalEnd = values[i + 1];
      } else {
        distributionItem = {
          intervalStart: values[i],
          intervalEnd: values[i + 1],
          minutesPercentage: undefined,
          acdVariation: undefined,
          asrVariation: undefined
        };
      }
      
      distributionItems.push(distributionItem);
    }
    
    this.setState({ distributionItems });
  }
  
  render() {
    const { distribution, distributionProfileLoaded, editedDistribution, editDistributionProcess } = this.props;
    const { distributionItems } = this.state;
    
    return (
      <div className="distribution-profile section">
        
        <div className="section-title">
          <h2>Edit Distribution</h2>
        </div>
        
        <div className="alert alert-warning" role="alert">
          <i className="fas fa-exclamation-circle"></i> Updating this Distribution will reload all the Dialer
          Destinations where it's used
        </div>
        
        <Loading display={!distributionProfileLoaded}/>
        
        {
          (distribution !== undefined)
            ? <div>
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Distribution Name: {distribution.distributionName} </h5>
                  <p className="card-text">Created
                    By: {distribution.createdBy} on {new Date(distribution.createdDate).toLocaleDateString()} date</p>
                </div>
              </div>
              
              <hr className="bg-info m-4 border-info"/>
              
              <div className="row">
                <div className="col-12">
                  <form onSubmit={(event) => this.submitUpdateForm(event)}>
                    
                    <div className="row">
                      <div className="col-6">
                        <button type="button" className="btn btn-primary" onClick={() => this.addInterval()}>Add New
                          Interval
                        </button>
                      </div>
                      
                      <div className="row mt-4">
                        <div className="col-12 mb-4">
                          <label className="form-label">Distribution Ranges</label>
                        </div>
                        <div className="col-12">
                          <Range
                            key={this.state.values.length}
                            values={this.state.values}
                            step={STEP}
                            min={MIN}
                            max={MAX}
                            onChange={(values) => this.updateIntervals(values)}
                            renderTrack={({ props, children }) => (
                              <div
                                onMouseDown={props.onMouseDown}
                                onTouchStart={props.onTouchStart}
                                style={{
                                  ...props.style,
                                  height: "36px",
                                  display: "flex",
                                  width: "100%"
                                }}
                              >
                                <div
                                  ref={props.ref}
                                  style={{
                                    height: "8px",
                                    width: "100%",
                                    borderRadius: "4px",
                                    background: getTrackBackground({
                                      values: this.state.values,
                                      colors: this.state.colors,
                                      min: MIN,
                                      max: MAX,
                                    }),
                                    alignSelf: "center"
                                  }}
                                >
                                  {children}
                                </div>
                              </div>
                            )}
                            renderThumb={({ index, props, isDragged }) => (
                              <div
                                {...props}
                                style={{
                                  ...props.style,
                                  height: "36px",
                                  width: "36px",
                                  borderRadius: "4px",
                                  backgroundColor: "#FFF",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  boxShadow: "0px 2px 6px #AAA"
                                }}
                              >
                                <div
                                  style={{
                                    position: 'absolute',
                                    top: '-32px',
                                    color: '#fff',
                                    fontWeight: 'bold',
                                    fontSize: '14px',
                                    padding: '4px',
                                    borderRadius: '4px',
                                    backgroundColor: '#548BF4'
                                  }}
                                >
                                  {this.state.values[index].toFixed(0)}
                                </div>
                                <div
                                  style={{
                                    height: "16px",
                                    width: "5px",
                                    backgroundColor: isDragged ? "#548BF4" : "#CCC"
                                  }}
                                />
                              </div>
                            )}
                          />
                        </div>
                      </div>
                      
                      <div className="row mt-3">
                        {
                          distributionItems.map((item, index) => <div className="col-4 mb-3"
                                                                      key={index}>
                            <div className="card">
                              <div className="card-body">
                                <h5
                                  className="card-title d-flex justify-content-center">{item.intervalStart} to {item.intervalEnd}</h5>
                                
                                <div className="mb-3 mt-3">
                                  <label htmlFor="asrVariation" className="form-label">ASR Variation</label>
                                  <input type="number" className="form-control" name="asrVariation"
                                         id={index + ".asrVariation"}
                                         placeholder=""
                                         value={item.asrVariation}
                                         onChange={(event) => this.handleItemChange(event)} required/>
                                </div>
                                
                                <div className="mb-3 mt-3">
                                  <label htmlFor="acdVariation" className="form-label">ACD Variation</label>
                                  <input type="number" className="form-control" name="acdVariation"
                                         id={index + ".acdVariation"}
                                         placeholder=""
                                         value={item.acdVariation}
                                         onChange={(event) => this.handleItemChange(event)} required/>
                                </div>
                                
                                <div className="mb-3 mt-3">
                                  <label htmlFor="minutesPercentage" className="form-label">Minutes Percentage</label>
                                  <input type="number" className="form-control" name="minutesPercentage"
                                         id={index + ".minutesPercentage"}
                                         placeholder=""
                                         value={item.minutesPercentage}
                                         onChange={(event) => this.handleItemChange(event)} required/>
                                </div>
                              </div>
                            </div>
                          </div>)
                        }
                      </div>
                    
                    </div>
                    
                    <UpdateButtonAuth editDistributionProcess={editDistributionProcess}/>
                    
                    <div className="row mt-1">
                      <div className="col-12">
                        <div className="alert alert-warning" role="alert">
                          <i className="fas fa-exclamation-circle"></i> Updating this Distribution will reload all the
                          Dialer
                          Destinations where it's used
                        </div>
                      </div>
                    </div>
                  
                  </form>
                  
                  {(!_.isEmpty(editedDistribution.errors))
                    ? <div className="alert alert-danger login-error" role="alert">
                      {
                        editedDistribution.errors.map(error => <div key={error.fieldName + error.message}>
                          {
                            (error.fieldName !== undefined)
                              ? error.fieldName + " - "
                              : null
                          }
                          {error.message}
                        </div>)
                      }
                    </div>
                    : null
                  }
                
                </div>
              </div>
            
            </div>
            
            : null
        }
      
      </div>
    );
  };
}

export default connect(
  (state, props) => {
    let distributionId;
    if (props?.match?.params?.id !== undefined) {
      distributionId = _.parseInt(props.match.params.id);
    }
    
    return {
      distribution: _.find(state.distribution.list, distribution => distribution.id === distributionId),
      
      editedDistribution: state.distribution.editedDistribution,
      distributionProfileLoaded: state.distribution.distributionProfileLoaded,
      
      editDistributionProcess: state.distribution.editDistributionProcess,
    };
  },
  {
    getDistributionById: (distributionId) => getDistributionById(distributionId),
    clearStateEditDistribution: () => clearStateEditDistribution(),
    editDistribution: (distributionId, distributionData) => editDistribution(distributionId, distributionData),
  }
)(DistributionProfile);

import React, { Component }                                   from 'react';
import PropTypes                                              from "prop-types";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import "./ConfirmationModal.scss";

/**
 * Modal Component.
 */
export default class ConfirmationModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    actionText: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.string,
    
    toggle: PropTypes.func.isRequired,
    doAction: PropTypes.func.isRequired
  };
  
  render() {
    const { isOpen, title, content, actionText, color, size, toggle, doAction } = this.props;
    
    return (
      <Modal isOpen={isOpen} toggle={toggle} size={size}>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody dangerouslySetInnerHTML={{ __html: content }}>
        </ModalBody>
        <ModalFooter>
          <Button color={color === undefined ? "danger" : color} onClick={doAction}>
            {
              actionText === undefined
                ? "Update"
                : <span>{actionText}</span>
            }
          </Button>
          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    );
  };
};

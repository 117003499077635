import React, { Component }   from 'react';

import "./Counts.scss";
import connect                from "react-redux/es/connect/connect";
import PropTypes              from "prop-types";
import { getReportingCounts } from "../../redux/dashboardSlice";
import Loading                from "../loading/Loading";

class Counts extends Component {
  static propTypes = {
    reportingCounts: PropTypes.object,
    reportingCountsLoaded: PropTypes.bool,
    
    getReportingCounts: PropTypes.func.isRequired,
  };
  
  componentDidMount() {
    const { reportingCountsLoaded, getReportingCounts } = this.props;
    
    if (reportingCountsLoaded === false) {
      getReportingCounts();
    }
  }
  
  render() {
    const { reportingCounts, reportingCountsLoaded } = this.props;
    
    return (
      <div className="counts section">
        <div className="container">
          
          <Loading display={!reportingCountsLoaded}/>
          
          {
            (reportingCountsLoaded === true)
              ? <div className="row">
                <div className="col-4">
                  <div className="count-box">
                    <i className="fas fa-user-friends"></i>
                    <span className="purecounter">{reportingCounts["users"]}</span>
                    <p>Users</p>
                  </div>
                </div>
                
                <div className="col-4">
                  <div className="count-box">
                    <i className="fas fa-list-ol"></i>
                    <span className="purecounter">{reportingCounts["clis"]}</span>
                    <p>CLI Change Campaigns</p>
                  </div>
                </div>
                
                <div className="col-4">
                  <div className="count-box">
                    <i className="fas fa-blender-phone"></i>
                    <span className="purecounter">{reportingCounts["dialers"]}</span>
                    <p>Dialer Destination Campaigns</p>
                  </div>
                </div>
              </div>
              : null
          }
        
        </div>
      </div>
    );
  };
}

export default connect(
  (state, props) => {
    
    return {
      reportingCounts: state.dashboard.reportingCounts,
      reportingCountsLoaded: state.dashboard.reportingCountsLoaded,
    };
  },
  {
    getReportingCounts: () => getReportingCounts()
  }
)(Counts);

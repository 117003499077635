import React, { Component }                       from 'react';

import "./FasProfile.scss";
import connect                                    from "react-redux/es/connect/connect";
import _                                          from "lodash";
import { FasWCompAuth }                           from "../../security/Authorization";
import PropTypes                                  from "prop-types";
import { saveFasFile }                            from "../../util/File";
import { clearStateEditFas, editFas, getFasById } from "../../redux/fasSlice";
import Loading                                    from "../loading/Loading";

const UpdateButton = ({ editFasProcess }) => {
  return <div className="col-6 d-flex align-self-center justify-content-center">
    {
      (editFasProcess === false)
        ? <button type="submit" className="btn btn-primary"><i className="fas fa-redo"></i> Update
        </button>
        : <button className="btn btn-primary" type="button" disabled>
                          <span className="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span> Update
        </button>
    }
  </div>;
};
const UpdateButtonAuth = FasWCompAuth(UpdateButton);

class FasProfile extends Component {
  static propTypes = {
    fas: PropTypes.object,
    editedFas: PropTypes.object,
    fasProfileLoaded: PropTypes.bool,
    editFasProcess: PropTypes.bool,
    
    getFasById: PropTypes.func.isRequired,
    clearStateEditFas: PropTypes.func.isRequired,
    editFas: PropTypes.func.isRequired,
  };
  
  constructor(props) {
    super(props);
    
    this.state = {
      fasId: undefined,
      
      fasPercentage: undefined,
      busyPercentage: undefined,
      ringPercentage: undefined,
      file: undefined
    };
    
    this.downloadFile = this.downloadFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps?.fas?.id !== prevState?.fasId) {
      console.log("[[>>> getDerivedStateFromProps]]");
      
      return {
        ...prevState,
        fasId: nextProps.fas.id,
        fasPercentage: nextProps.fas.fasPercentage,
        busyPercentage: nextProps.fas.busyPercentage,
        ringPercentage: nextProps.fas.ringPercentage,
      };
    } else {
      return null;
    }
  }
  
  componentDidMount() {
    const { fas, getFasById } = this.props;
    
    if (this.props?.match?.params?.id !== undefined) {
      const fasId = _.parseInt(this.props.match.params.id);
      
      if (fas === undefined) {
        getFasById(fasId);
      }
    }
  }
  
  componentWillUnmount() {
    const { clearStateEditFas } = this.props;
    clearStateEditFas();
  }
  
  handleChange = (event) => {
    const { target } = event;
    let { id } = target;
    let value = target.value;
    
    if (target.type === "file") {
      value = target.files[0];
    } else {
      if (target.type === "checkbox") {
        value = target.checked;
      } else {
        if (target.type === "radio") {
          id = target.name;
          value = target.value;
        } else {
          value = target.value;
        }
      }
    }
    
    this.setState({
      [id]: value,
    });
  };
  
  downloadFile(fas) {
    saveFasFile(fas);
  }
  
  submitUpdateForm(event) {
    const { editFas } = this.props;
    event.preventDefault();
    
    // extract FAS id
    let fasId;
    if (this.props?.match?.params?.id !== undefined) {
      fasId = _.parseInt(this.props.match.params.id);
    }
    
    if (fasId === undefined) {
      console.error("Something is wrong! we don't have the FAS id at this point!");
      return;
    }
    
    // create an object of formData
    const formData = new FormData();
    
    const fasGatewayUpdate = new Blob([JSON.stringify({
      fasId: this.state.fasId,
      fasName: this.state.fasName,
      fasPercentage: this.state.fasPercentage,
      busyPercentage: this.state.busyPercentage,
      ringPercentage: this.state.ringPercentage
    })], {
      type: 'application/json'
    });
    
    // update the formData object
    formData.append("fasGatewayUpdate", fasGatewayUpdate);
    formData.append("file", this.state.file);
    
    editFas(fasId, formData);
  }
  
  render() {
    const { fas, fasProfileLoaded, editedFas, editFasProcess } = this.props;
    const { fasPercentage, busyPercentage, ringPercentage } = this.state;
    
    return (
      <div className="fas-profile section">
        
        <div className="section-title">
          <h2>Edit FAS</h2>
        </div>
        
        <Loading display={!fasProfileLoaded}/>
        
        {
          (fas !== undefined)
            ? <div>
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">FAS Id: {fas.fasId} -- Fas Name: {fas.fasName}</h5>
                  <p className="card-text">Created
                    By: {fas.createdBy} on {new Date(fas.createdDate).toLocaleDateString()} date</p>
                </div>
              </div>
              
              <hr className="bg-info m-4 border-info"/>
              
              
              <div className="row">
                <div className="col-12">
                  <form onSubmit={(event) => this.submitUpdateForm(event)}>
                    
                    <div className="row">
                      <div className="col-4">
                        <label htmlFor="fasPercentage" className="form-label">FAS Percentage</label>
                        <input type="number" className="form-control" name="fasPercentage" id="fasPercentage"
                               placeholder="percentage"
                               value={fasPercentage}
                               onChange={(event) => this.handleChange(event)} required/>
                      </div>
                      
                      <div className="col-4">
                        <label htmlFor="busyPercentage" className="form-label">Busy Percentage</label>
                        <input type="number" className="form-control" name="busyPercentage" id="busyPercentage"
                               placeholder="percentage"
                               value={busyPercentage}
                               onChange={(event) => this.handleChange(event)} required/>
                      </div>
                      
                      <div className="col-4">
                        <label htmlFor="ringPercentage" className="form-label">Ring Percentage</label>
                        <input type="number" className="form-control" name="ringPercentage" id="ringPercentage"
                               placeholder="percentage"
                               value={ringPercentage}
                               onChange={(event) => this.handleChange(event)} required/>
                      </div>
                    </div>
                    
                    <div className="row mt-3">
                      <div className="col-6">
                        <label htmlFor="file" className="form-label">Upload FAS MP3 file</label>
                        <input className="form-control" type="file" name="file" id="file"
                               onChange={(event) => this.handleChange(event)}/>
                        <div className="form-text">Upload a .mp3 file - this can be edited later</div>
                      </div>
                      
                      <UpdateButtonAuth editFasProcess={editFasProcess}/>
                    </div>
                  
                  </form>
                  
                  {(!_.isEmpty(editedFas.errors))
                    ? <div className="alert alert-danger login-error" role="alert">
                      {
                        editedFas.errors.map(error => <div key={error.fieldName + error.message}>
                          {
                            (error.fieldName !== undefined)
                              ? error.fieldName + " - "
                              : null
                          }
                          {error.message}
                        </div>)
                      }
                    </div>
                    : null
                  }
                
                </div>
              </div>
              
              <hr className="bg-info m-4 border-info"/>
            </div>
            
            : null
        }
        
        {
          (fas !== undefined)
            ? <div className="row">
              <div className="col-12">
                <h5 className="download"><i className="fas fa-download"></i> Download uploaded MP3 file</h5>
              </div>
              
              <div className="col-12">
                <div className="table-responsive">
                  <table className="table align-middle">
                    <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Uploaded By</th>
                      <th scope="col">Uploaded Date</th>
                      <th scope="col">Download</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td><a aria-label="Download file" data-cooltipz-dir="right" href="" onClick={(event) => {
                        event.preventDefault();
                        this.downloadFile(fas);
                      }}>{fas.fileName}</a></td>
                      <td>{fas.updatedBy}</td>
                      <td>{new Date(fas.updatedDate).toLocaleDateString()}</td>
                      <td>
                        <button type="button" className="btn btn-primary" onClick={() => this.downloadFile(fas)}>
                          <i className="fas fa-music"></i> Download
                        </button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              
              </div>
            
            </div>
            : null
        }
      
      </div>
    );
  };
}

export default connect(
  (state, props) => {
    let fasId;
    if (props?.match?.params?.id !== undefined) {
      fasId = _.parseInt(props.match.params.id);
    }
    
    return {
      fas: _.find(state.fas.list, fas => fas.id === fasId),
      
      editedFas: state.fas.editedFas,
      fasProfileLoaded: state.fas.fasProfileLoaded,
      
      editFasProcess: state.fas.editFasProcess,
    };
  },
  {
    getFasById: (fasId) => getFasById(fasId),
    clearStateEditFas: () => clearStateEditFas(),
    editFas: (fasId, fasData) => editFas(fasId, fasData),
  }
)(FasProfile);

import { createSlice }                                from "@reduxjs/toolkit";
import { fetchReportingByType, fetchReportingCounts } from "../api/Api";

const initialState = {
  reportingList: {},    // Map of <Type, Reporting>
  reportingLoaded: {},
  reportingCounts: {},    // Map of <String, Long>
  reportingCountsLoaded: false,
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    clearReporting: (state, action) => {
      state.reportingList = {};
      state.reportingLoaded = {};
      state.reportingCountsLoaded = false;
    },
    
    fetchReportingInvoked: (state, action) => {
      const type = action.payload;
      state.reportingLoaded[type] = false;
    },
    
    fetchReportingSuccess: (state, action) => {
      const { type, data } = action.payload;
      state.reportingList[type] = data;
      state.reportingLoaded[type] = true;
    },
    
    fetchReportingFailure: (state, action) => {
      const type = action.payload;
      state.reportingLoaded[type] = false;
    },
    
    fetchReportingCountsInvoked: (state, action) => {
      state.reportingCountsLoaded = false;
    },
    
    fetchReportingCountsSuccess: (state, action) => {
      state.reportingCounts = action.payload;
      state.reportingCountsLoaded = true;
    },
    
    fetchReportingCountsFailure: (state, action) => {
      state.reportingCountsLoaded = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase("user/logoutSuccess", (state, action) => {
        return initialState;
      });
  }
});

export const {
  clearReporting,
  fetchReportingInvoked,
  fetchReportingSuccess,
  fetchReportingFailure,
  fetchReportingCountsInvoked,
  fetchReportingCountsSuccess,
  fetchReportingCountsFailure
} = dashboardSlice.actions;

export const clearReportingState = () => async (dispatch, getState) => {
  dispatch(clearReporting()); // make sure to reset any previous data fetched / errors
};

export const getReportingByType = (type) => async (dispatch, getState) => {
  dispatch(fetchReportingInvoked(type));
  try {
    const { data } = await fetchReportingByType(type);
    dispatch(fetchReportingSuccess({ type, data }));
  } catch (error) {
    console.log(error);
    dispatch(fetchReportingFailure(type));
    console.log(error);
  }
};

export const getReportingCounts = () => async (dispatch, getState) => {
  dispatch(fetchReportingCountsInvoked());
  try {
    const { data } = await fetchReportingCounts();
    dispatch(fetchReportingCountsSuccess(data));
  } catch (error) {
    console.log(error);
    dispatch(fetchReportingCountsFailure());
    console.log(error);
  }
};

export default dashboardSlice.reducer;
